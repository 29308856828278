import ProTable, {
  ActionType,
  enUSIntl,
  IntlProvider,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import qs from "query-string";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import api, { url } from "../../../api";
import SNButtonGroup from "../../../components/SN/SNButtonGroup";
import { SNMenuItemProps } from "../../../components/SN/SNMenuItem";
import SNPanel from "../../../components/SN/SNPanel";
import { useAsyncState } from "../../../hooks/useAsyncState";
import { formatName } from "../../../lib";
import { paramsToObject } from "../../../lib/locationSearch";
import AuthLogDetailModal from "./components/AuthLogDetailModal";
import AuthLogFilter from "./components/AuthLogFilter";
import { getLoginTypeTag } from "./const";
import { AuthLogApi } from "./data.t";

const AuthLog = () => {
  const location = useLocation();
  const listRef = useRef<ActionType>();
  const [isVisibleInfoModal, setIsVisibleInfoModal] = useState<boolean>(false);
  const [selectedIdForInfo, setSelectedIdForInfo] = useAsyncState<any>(null);

  const _renderList = () => {
    const columns: ProColumns<AuthLogApi>[] = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: true,
      },
      {
        title: "Name",
        key: "name",
        render: (_, data: AuthLogApi) =>
          formatName(data.user.first_name, data.user.last_name),
      },
      {
        title: "Username",
        key: "username",
        render: (_, data: AuthLogApi) => data.user.username,
      },
      {
        title: "Login time",
        dataIndex: "login_time",
        key: "login_time",
        valueType: "dateTime",
        sorter: true,
      },
      {
        title: "Logout time",
        dataIndex: "logout_time",
        key: "logout_time",
        valueType: "dateTime",
      },
      {
        title: "Login from",
        dataIndex: "login_from",
        key: "login_from",
        render: (value: any) => getLoginTypeTag(value),
      },
      {
        title: "",
        key: "options",
        render: (value: any) => {
          const actions: SNMenuItemProps[] = [
            {
              key: "info",
              icon: ["fal", "info"],
              title: "Details",
              onClick: () =>
                setSelectedIdForInfo(value, () => {
                  setIsVisibleInfoModal(true);
                }),
            },
          ];
          return <SNButtonGroup dropdown={actions} />;
        },
      },
    ];

    return (
      <IntlProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
        <ProTable<AuthLogApi, {}>
          actionRef={listRef}
          request={async (
            { pageSize, current },
            sort
          ): Promise<RequestData<AuthLogApi>> => {
            const sortKey = Object.keys(sort).length
              ? Object.keys(sort)[0]
              : "";
            const sortParams = sortKey.length
              ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
              : "";
            const locationParams = qs.stringify(
              paramsToObject(location.search)
            );

            const urlRequest = `${url.AUTH_LOG}?limit=${pageSize}&offset=${
              (current - 1) * pageSize
            }${sortParams}${locationParams ? `&${locationParams}` : ""}`;

            const res = await api.get(urlRequest);

            return {
              data: res.data.results,
              success: true,
              total: res.data.count,
            };
          }}
          headerTitle="Auth log"
          search={false}
          params={location.search}
          toolBarRender={() => [<AuthLogFilter key="filter" />]}
          options={{
            setting: false,
            fullScreen: false,
            density: false,
          }}
          size={"small"}
          pagination={{ pageSize: 100, showQuickJumper: true }}
          rowKey={(key) => key.id}
          columns={columns}
          dateFormatter="string"
        />
        <AuthLogDetailModal
          info={selectedIdForInfo}
          isVisible={isVisibleInfoModal}
          onClose={() => {
            setSelectedIdForInfo(null);
            setIsVisibleInfoModal(false);
          }}
        />
      </IntlProvider>
    );
  };

  return <SNPanel view="list">{_renderList()}</SNPanel>;
};

export default AuthLog;
