import { AddressApi } from "../../constants/system";
import { CompanyDataApi } from "../Companies/data.t";

export type NodeAllocationStatusType = 1 | 2 | 3 | 4;
export type NodeOrderStatusType = 1 | 2 | 3 | 4 | 5;

export const nodeOrderStatus: { [key in NodeOrderStatusType]: string } = {
  1: "Initiated",
  2: "Installed",
  3: "Broken",
  4: "Returned",
  5: "Manufactured",
};

export const nodeAllocateStatus: {
  [key in NodeAllocationStatusType]: string;
} = {
  1: "Ordered",
  2: "Manufacturing",
  3: "Shipping",
  4: "Shipped",
};

export interface NodeOrderApi {
  id: number;
  allocate: Pick<NodeAllocationApi, "id" | "created_at" | "company">;
  created_at: string;
  node_id: string;
  status: NodeOrderStatusType;
  is_active: boolean;
}

export interface NodeAllocationApi {
  id: number;
  created_at: string;
  node_allocated: number;
  company: Pick<
    CompanyDataApi,
    "company_name" | "company_id" | "company_uuid" | "logo"
  >;
  initial: string;
  number: string;
  series: string;
  total: number;
  description: string;
  ordered_at: string;
  status: NodeAllocationStatusType;
  ordered_by: any;
  shipping_address: AddressApi;
  shipped_at: string;
  created_by: any;
}

export interface NodeProductionApi {
  id: number;
  node_produced: number;
  initial: string;
  number: string;
  series: string;
  type: number;
  total: number;
  description: string;
  production_date: string;
  status: NodeAllocationStatusType;
  created_by: any;
}
