import { Col, Skeleton } from "antd";

import SNButton from "../../../../components/SN/SNButton";
import SNDescription from "../../../../components/SN/SNDescription";
import { SNDescriptionItemProps } from "../../../../components/SN/SNDescription/data.t";
import SNEmpty from "../../../../components/SN/SNEmpty";
import SNModal from "../../../../components/SN/SNModal";
import { generateCompRole } from "../../../../constants";
import accountService from "../../service";

const AccountDetailModal = (props) => {
  const { id, isVisible, onClose } = props;
  const [info, isLoading] = accountService.useAccountDetails(id);

  const _renderDetails = () => {
    if (isLoading) return <Skeleton />;
    if (!info || !info.id)
      return <SNEmpty title="No data found" icon="comment-alt-exclamation" />;

    const companyColumns: SNDescriptionItemProps[] = info.companies
      ? info.companies.map((item) => {
          return {
            label: item.company_name,
            value: (
              <span>
                <strong>Email: </strong>
                {`${item.company_email ?? ""}`}
                <br />
                <strong>Designation: </strong>
                {`${item.company_user_designation ?? ""}`}
                <br />
                <strong>Position: </strong>
                {`${generateCompRole()[item.company_user_group] ?? ""}`}
              </span>
            ),
          };
        })
      : [];
    return (
      <Col xl={24} lg={24} md={24} xs={24}>
        {info.companies.length ? (
          <SNDescription
            icon={"building"}
            title={"Company Joined"}
            bordered
            column={1}
            size={"small"}
            rowSize={["40%", "60%"]}
            itemData={companyColumns}
          />
        ) : null}
      </Col>
    );
  };

  const footer = [
    <SNButton
      key="cancel"
      title={"Close"}
      onClick={onClose}
      className="sn-btn-big"
    />,
  ];

  return (
    <SNModal
      title="User Details"
      isVisible={isVisible}
      footer={footer}
      onCancel={onClose}
      width={800}
      bodyStyle={{
        maxHeight: 800,
        overflowY: "auto",
        overflowX: "hidden",
        padding: 16,
        // margin: 20,
      }}
      destroyOnClose
    >
      {_renderDetails()}
    </SNModal>
  );
};

export default AccountDetailModal;
