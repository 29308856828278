import {
  IconName,
  IconProp,
  SizeProp,
} from "@fortawesome/fontawesome-svg-core";
import Button, { ButtonProps } from "antd/lib/button";
import { FC } from "react";
import { Link } from "react-router-dom";
import Icon from "../SNIcon";
import AntTooltip from "../SNTooltip";
import "./index.scss";

export interface SNButtonProps extends Omit<ButtonProps, "snBtn"> {
  iconcolor?: string;
  iconSize?: SizeProp;
  href?: string;
  loading?: boolean | object;
  icon?: IconProp | IconName;
  title?: any;
  link?: any;
  tooltip?: string;
}

const SNButton: FC<SNButtonProps> = (props) => {
  const {
    link,
    title,
    icon,
    iconcolor,
    href,
    tooltip,
    size,
    className,
    iconSize,
    ...newprops
  } = props;

  const getIcon = (icon: any, iconColor: string | undefined, title: any) => {
    return (
      <Icon
        icon={icon}
        size={iconSize}
        style={Object.assign(
          { color: iconColor },
          title && { marginRight: 10 }
        )}
      />
    );
  };

  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <AntTooltip text={tooltip || ""}>
          <Button icon={icon && getIcon(icon, iconcolor, title)} />
        </AntTooltip>
      </a>
    );
  }

  if (link) {
    return (
      <Link to={link}>
        <Button
          {...newprops}
          icon={icon && getIcon(icon, iconcolor, title)}
          className={`${className || ""} sn-button`}
        >
          {title}
        </Button>
      </Link>
    );
  }

  return (
    <Button
      icon={icon && getIcon(icon, iconcolor, title)}
      className={`${className || ""} sn-button`}
      {...newprops}
    >
      {title}
    </Button>
  );
};

export default SNButton;
