import { Modal } from "antd";
import Avatar, { AvatarProps } from "antd/lib/avatar";
import { useState } from "react";
import { formatNameAvatar } from "../../../lib";
import SNText from "../SNText";
import "./index.scss";

interface createByData {
  first_name?: string;
  last_name?: string;
}
interface imageData {
  low?: string;
  med?: string;
  full?: string;
  created_at?: string;
  created_by?: createByData;
  title?: string;
}

interface SNAvatarProps extends Omit<AvatarProps, "snAvatar"> {
  name?: any;
  image?: imageData;
}

function stringToHslColor(str, s, l) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
}

function SNAvatar(props: SNAvatarProps) {
  const { name, src, style, image, ...rest } = props;
  const char = formatNameAvatar(name);

  const [previewVisible, setPreviewVisible] = useState(false);

  return (
    <>
      {!image && (
        <Avatar
          src={src}
          {...rest}
          style={{
            backgroundColor: !src && char && stringToHslColor(char, 70, 60),
            cursor: image && "pointer",
            ...style,
          }}
        >
          {!src && char}
        </Avatar>
      )}
      {image && (
        <>
          <div onClick={() => setPreviewVisible(true)}>
            <Avatar
              src={image.low}
              {...rest}
              style={{
                ...style,
                cursor: "pointer",
                border: "1px solid #d9d9d9",
              }}
              alt=""
            >
              {!src && char}
            </Avatar>
          </div>

          <Modal
            visible={previewVisible}
            title={image.title || <SNText text="Image" />}
            footer={false}
            onCancel={() => setPreviewVisible(false)}
            className="sn-image-preview-modal"
            bodyStyle={{ padding: 0 }}
          >
            <img
              alt="example"
              style={{ width: "100%" }}
              src={image?.full || image?.med || image?.low}
            />
          </Modal>
        </>
      )}
    </>
  );
}

export default SNAvatar;
