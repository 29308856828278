import api, { url } from "../../api";

function addProduction(params: any = {}, postActions?: (data: any) => void) {
  api.post(url.NODE_PRODUCTION, params).then((response) => {
    if (response.status === 201) {
      if (postActions) postActions(response.data);
    }
  });
}

function addAllocation(params: any = {}, postActions?: (data: any) => void) {
  api.post(url.NODE_ALLOCATION, params).then((response) => {
    if (response.status === 201) {
      if (postActions) postActions(response.data);
    }
  });
}

const nodeService = { addProduction, addAllocation };

export default nodeService;
