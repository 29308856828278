import axios from "axios";
import { useEffect, useState } from "react";
import api from "../../../../api";

function useDataProList(apiUrl, filters: string = "") {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [willReload, setWillReload] = useState<boolean>(false);

  let source = axios.CancelToken.source();
  const reload = () => {
    setWillReload(!willReload);
  };
  useEffect(() => {
    if (apiUrl) {
      setLoading(true);
      api
        .get(`${apiUrl}${filters}`, true, source.token)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setData(null);
          setLoading(false);
        });
    } else {
      setData(null);
    }
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [apiUrl, filters, willReload]);

  return [data, loading, reload];
}

export default useDataProList;
