import React from 'react';
import { Tag } from 'antd';

export default function CompanyStat({ status }) {
    switch (status) {
        case true:
            return <Tag color="green">Active</Tag>;
        case false:
            return <Tag color="orange">Pending approval</Tag>;
        default:
            return <Tag>{status}</Tag>
    }
}