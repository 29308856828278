import { Col, Row, Skeleton } from "antd";
import PropTypes from "prop-types";

import SNPanel from "../SNPanel";

SNFormLoading.propTypes = {
  noPanel: PropTypes.bool,
};
const formTitle = () => {
  return (
    <h4 className="form-section-title">
      <span className="animated-background" style={titleStyle}>
        &nbsp;
      </span>
    </h4>
  );
};

const titleStyle = {
  display: "block",
  height: 18,
  marginBottom: 5,
  width: "75%",
};
function SNFormLoading(props) {
  const { noPanel } = props;
  if (noPanel) {
    return (
      <div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {formTitle()}
            <Skeleton active paragraph={{ rows: 4 }} />
          </Col>
          <Col span={12}>
            {formTitle()}
            <Skeleton active paragraph={{ rows: 4 }} />
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <SNPanel panelTitle="">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          {formTitle()}
          <Skeleton active paragraph={{ rows: 4 }} />
        </Col>
        <Col span={12}>
          {formTitle()}
          <Skeleton active paragraph={{ rows: 4 }} />
        </Col>
      </Row>
    </SNPanel>
  );
}

export default SNFormLoading;
