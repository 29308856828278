import { createSlice } from "@reduxjs/toolkit";

interface ApiState {
  isProcessing: boolean;
}

let initialState: ApiState = {
  isProcessing: false,
};

const apiReducer = createSlice({
  name: "Api",
  initialState,
  reducers: {
    startProcessing: (state) => {
      state.isProcessing = true;
    },
    endProcessing: (state) => {
      state.isProcessing = false;
    },
  },
});

export default apiReducer.reducer;

export const { startProcessing, endProcessing } = apiReducer.actions;
