import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import ReactDOM from "react-dom";
import { ENV_NAME } from "settings/env";
import WebFont from "webfontloader";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./settings/serviceWorker";

Sentry.init({
  dsn: "https://db756d32e89249eca0a4114aae0f9ea0@o56339.ingest.sentry.io/5870979",
  integrations: [new Integrations.BrowserTracing()],
  environment: ENV_NAME,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

WebFont.load({
  google: {
    families: ["Roboto:300,400,500,700", "sans-serif"],
  },
});

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
