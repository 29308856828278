import { Col, Row } from "antd";
import { FC } from "react";
import ChartCard from "./components/ChartCard";
import Field from "./components/Field";

const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 6,
  style: { marginBottom: 24 },
};

const Analytics: FC = () => {
  return (
    <Row gutter={24}>
      <Col {...topColResponsiveProps}>
        <ChartCard
          bordered={false}
          title={"Total companies"}
          loading={false}
          total={1340}
          footer={<Field label={"Daily register"} value={2} />}
          contentHeight={46}
        ></ChartCard>
      </Col>

      <Col {...topColResponsiveProps}>
        <ChartCard
          bordered={false}
          loading={false}
          title={"Total Nodes"}
          total={8846}
          footer={<Field label={"Node generate this week"} value={100} />}
          contentHeight={46}
        ></ChartCard>
      </Col>
      <Col {...topColResponsiveProps}>
        <ChartCard
          bordered={false}
          loading={false}
          title={"Assets"}
          total={75999}
          footer={<Field label={"Attached"} value="65278" />}
          contentHeight={46}
        ></ChartCard>
      </Col>
      <Col {...topColResponsiveProps}>
        <ChartCard
          bordered={false}
          loading={false}
          title={"Subcriptions"}
          total={"$100000"}
          footer={<Field label={"This month increase"} value="60%" />}
          contentHeight={46}
        ></ChartCard>
      </Col>
    </Row>
  );
};

export default Analytics;
