import { isValidElement } from "react";
import SNIcon from "../SNIcon";
import SNText from "../SNText";
import SNWikiIcon from "../SNWikiIcon";
import { SNTitleProps } from "./data.t";
import "./index.scss";

function SNTitle(props: SNTitleProps) {
  const { icon, title, className, children, wiki, extra } = props;

  const _getIcon = (icon: any) => {
    if (isValidElement(icon)) return icon;

    return <SNIcon icon={icon} style={{ marginRight: 8 }} />;
  };

  return (
    <h4 className={`sn-section-title` + ` ${className || ""}`}>
      <div className={"sn-section-title-top"}>
        <div className={"sn-section-title-top-left"}>
          {icon && _getIcon(icon)}
          {
            <SNText
              text={title}
              style={{
                color: "#42a5f5",
                fontSize: 16,
              }}
            />
          }
        </div>

        <div className={"sn-section-title-top-right"}>
          {extra} {wiki && <SNWikiIcon tips={wiki} />}
        </div>
      </div>

      {children}
    </h4>
  );
}

export default SNTitle;
