import _ from "lodash";
import moment, { Moment } from "moment";

export const cleanObject = (obj: Object): any => {
  const newObj = _.pickBy(obj);
  return newObj;
};

export const paramsToObject = (searchParams: string): any => {
  const urlParams = new URLSearchParams(searchParams);
  const entries: any = urlParams.entries();
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export const formatBooleanParam = (param: string): boolean | undefined => {
  if (!param) return undefined;
  if (param === "true") return true;
  if (param === "false") return false;

  return undefined;
};

export const formatNumberParam = (param: string): number | undefined => {
  if (!param) return undefined;

  return ~~param;
};

export const formatDateParam = (param: string): Moment | undefined => {
  if (!param) return undefined;

  return moment(param);
};

export const formatFormCustomSelectParam = (
  param: string,
  type: "string" | "number" = "number"
): { value: any } | undefined => {
  if (!param) return undefined;

  switch (type) {
    case "string":
      return { value: param };
    case "number":
      return { value: ~~param };
    default:
      return { value: null };
  }
};
