import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  authInfo: any;
  user: any;
}

let initialState: AuthState = {
  authInfo: null,
  user: null,
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.authInfo = action.payload;
    },
    removeAuth: (state) => {
      state.authInfo = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
  },
});

export default authReducer.reducer;

export const { setAuth, removeAuth, setUser, removeUser } = authReducer.actions;
