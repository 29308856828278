import { combineReducers } from "redux";
import apiReducer from "./apiReducer";
import authReducer from "./authReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  api: apiReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
