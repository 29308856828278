import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { Card, Skeleton } from "antd";
import { CardProps } from "antd/lib/card";
import { FC } from "react";
import Icon from "../../SN/SNIcon";
import SNText from "../SNText";
import "./index.scss";

interface SNPanelProps extends Omit<CardProps, "snPanel"> {
  panelTitle?: any;
  view?: "details" | "list" | "default" | "heading" | "form";
  height?: number;
  isLoading?: boolean;
  loadingRows?: number;
  loadingAvatar?: boolean;
  panelIcon?: IconProp | IconName;
}
interface viewListData {
  details: string;
  list: string;
  heading: string;
}
const SNPanel: FC<SNPanelProps> = (props) => {
  const {
    panelTitle,
    panelIcon,
    view,
    className,
    isLoading,
    loadingRows = 2,
    loadingAvatar,
    height,
    style,
    ...newprops
  } = props;
  const viewList: viewListData | any = {
    details: "sn-details-card",
    list: "sn-list-card",
    heading: "sn-heading-card",
    form: "sn-form-card",
  };
  let viewName = view ? viewList[view] : "";
  const title = () => {
    return (
      <span>
        {typeof panelIcon === "string" || typeof panelIcon === "object" ? (
          <Icon icon={panelIcon} style={{ marginRight: "7px" }} />
        ) : (
          panelIcon
        )}
        {typeof panelTitle === "string" ? (
          <SNText
            text={panelTitle}
            style={{ fontWeight: 500, fontSize: 17, color: "#42a5f5" }}
          />
        ) : (
          panelTitle
        )}
      </span>
    );
  };
  return (
    <Card
      {...newprops}
      className={`sn-panel ${viewName} ${className || ""}`}
      style={{ ...style, height: height }}
      title={panelTitle ? title() : null}
    >
      <Skeleton
        loading={isLoading ? isLoading : false}
        active
        paragraph={{ rows: loadingRows }}
        avatar={loadingAvatar}
      >
        {props.children}
      </Skeleton>
    </Card>
  );
};

export default SNPanel;
