import { List, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { useRef } from "react";
import { url } from "../../../../api";
import SNApiSelect, {
  SNApiSelectRef,
} from "../../../../components/SN/SNForm/SNApiSelect";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import { generateEndNodeId } from "../../../../lib";
import { NodeAllocationApi } from "../../../Nodes/data.t";

const { Option } = Select;
interface SNAllocatedNodesSelectProps extends SelectProps<any> {
  placeholdertext?: string;
  required?: boolean;
  name: string;
  label?: any;
  formClassName?: string;
  fields?: string;
}
function SNAllocatedNodesSelect(props: SNAllocatedNodesSelectProps) {
  const {
    placeholdertext = "Select node ID from the list",
    fields,
    required,
    name,
    label,
    formClassName,
    onSelect,
    ...newProps
  } = props;
  const apiSelectRef = useRef<SNApiSelectRef>();

  const optionFormat = (item: NodeAllocationApi) => {
    const { id, company, initial, number, series, total } = item;
    const title = (
      <span>
        #{`${id}`} - {`${initial}${number}${series}`} ⮕{" "}
        {generateEndNodeId(initial, number, total, series)}
      </span>
    );
    const description = `${company.company_name}`;

    return (
      <Option value={id} key={id} label={title} data={item}>
        <List.Item.Meta
          className="option-item-has-image"
          title={title}
          description={description}
        />
      </Option>
    );
  };

  return (
    <>
      <SNFormItem
        name={name}
        label={label}
        required={required}
        className={formClassName}
      >
        <SNApiSelect
          {...newProps}
          placeholdertext={placeholdertext}
          urlObject={`${url.NODE_ALLOCATION}?ordering=id`}
          optionFormat={optionFormat}
          limit={100}
          handleChange={onSelect}
          ref={apiSelectRef}
        />
      </SNFormItem>
    </>
  );
}

export default SNAllocatedNodesSelect;
