import Moment from "moment";

//Name initial generator for Avatars.
export function getUserInitials(name, noOfInitials = 2) {
  const name_parts = name.split(" ");
  let name_initial = "";

  for (let i = 0; i < noOfInitials; i++) {
    name_initial += name_parts[i].charAt(0).toUpperCase();
  }

  return name_initial;
}

//Node series generator
export function nodeSeries() {
  const alphabet = [..."abcdefghijklmnopqrstuvwxyz".toUpperCase()];

  const series = alphabet.map((letter) => {
    let letterArray = [];
    alphabet.forEach((element) => {
      letterArray.push(letter + element);
    });

    return letterArray.flat();
  });

  return series.flat().map((i) => {
    return { value: i, label: i };
  });
}

//End node id generator
export function generateEndNodeId(initial, number, total, series) {
  const total_node = parseInt(number, 10) + (total - 1);
  const total_node_length = total_node.toString().length;
  const zeros =
    total_node_length > 4 ? "" : `${"0".repeat(4 - total_node_length)}`;

  return `${initial}${zeros}${total_node}${series}`;
}

export const formatDate = (date, format) => {
  return Moment.parseZone(date).format(format);
};

export const formatNameAvatar = (name, num = 2) => {
  if (!name) return "";
  var matches = name.match(/\b(\w)/g); // ['J','S','O','N']
  var acronym = matches?.join(""); // JSON
  return acronym?.slice(0, num);
};

export const formatName = (firstName = "", lastName = "") => {
  return `${firstName} ${lastName}`.trim();
};

export const addressManager = (address, isToString = false) => {
  //Return null if Address is empty or null!
  if (!address) return "";

  let modifiedAddress = "";

  if (address.address) {
    modifiedAddress = modifiedAddress + address.address + ", ";
  }

  if (address.post) {
    modifiedAddress = modifiedAddress + address.post + ", ";
  }

  if (address.city) {
    modifiedAddress = modifiedAddress + address.city + ", ";
  }

  if (address.country) {
    modifiedAddress = modifiedAddress + address.country;
  }
  if (isToString)
    return `${
      address.address2 ? `${address.address2},` : ""
    } ${modifiedAddress}`;
  return (
    <div>
      {address.address2 && <p>{address.address2}</p>}
      {modifiedAddress}
    </div>
  );
};

export const formatSizeUnit = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes == 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};

// export const generatePassword = (minLength = 12, maxLength = 16) => {
//   const pass = PassHelper.generate({
//     length: Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength,
//     numbers: true,
//   });

//   return pass;
// };

export const pickKeysFromArrayObj = (entity, keys) => {
  const modifyArray = entity.map((x) => {
    let modifyObj = {};

    keys.forEach((key) => {
      modifyObj[key] = x[key];
    });
    return modifyObj;
  });
  return modifyArray;
};
