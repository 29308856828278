import backgroundPattern from "../assets/img/bg_pattern.png";
import serviceNodeLogo from "../assets/img/logo.svg";
import serviceNodeWhiteLogo from "../assets/img/logo-white.svg";
import { ORDER_STATUS, SUPSCRIPTION_STATUS } from "./Statuses";
import { nodeSeries } from "../lib";

export const THEME_COLOR = "#42a5f5";
export const COLOR_SUCCESS = "#5cc200";
export const COLOR_WARNING = "#ffb500";
export const COLOR_DANGER = "#ff7639";
export const THEME_SHADOW = "0 0 7px #bfbfbfb3";

export const BACKGROUND_PATTERN = backgroundPattern;
export const LOGO = serviceNodeLogo;
export const WHITE_LOGO = serviceNodeWhiteLogo;
export const STATUS = { ORDER_STATUS, SUPSCRIPTION_STATUS };
export const NODE_TYPE = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
];
export const NODE_INITIALS = [
  { value: "SE", label: "SE" },
  { value: "XX", label: "XX" },
];
export const NODE_SERIES = nodeSeries();
export const PLANS = [
  { value: 0, label: "NO_BILLING" },
  { value: 1, label: "MONTHLY" },
  { value: 2, label: "QUARTERLY" },
  { value: 3, label: "HALF_YEARLY" },
  { value: 4, label: "YEARLY" },
];
export const INVOICE_TERMS = [
  { value: 1, label: "ON_RECEIPT" },
  { value: 2, label: "NET15" },
  { value: 3, label: "NET30" },
  { value: 4, label: "NET60" },
];
export const ON_END = [
  { value: 1, label: "AUTO_RENEW" },
  { value: 2, label: "ON_HOLD" },
];
export const GUTTER_WIDTH = 20;
export const UNITS = [
  { value: 1, label: "times" },
  { value: 2, label: "hours" },
];
export const COMPANY_ROLE = [
  { value: 1, label: "System Owner" },
  { value: 2, label: "Manager" },
  { value: 3, label: "Service person" },
];
export const generateCompRole = () => {
  var result = {};
  for (var i = 0; i < COMPANY_ROLE.length; i++) {
    result[COMPANY_ROLE[i].value] = COMPANY_ROLE[i].label;
  }

  return result;
};
