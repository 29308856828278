import { Spin } from "antd";
import { FC } from "react";
import "./index.scss";
interface SNLoaderProps {
  loaderStyle?: string;
  loading?: boolean;
  tip?: any;
  className?: string;
  isBlock?: any;
  height?: number;
}

const SNLoader: FC<SNLoaderProps> = (props) => {
  const { loaderStyle, tip, className, isBlock, height, ...newProps } = props;

  if (loaderStyle === "dot") {
    return (
      <div
        className={`${className || ""} sn-spin-container ${
          isBlock ? "sn-loeader-center-block" : ""
        }`}
        style={{ height }}
      >
        <div className="sn-spin-chase">
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
          <div className="sn-spin-chase-dot"></div>
        </div>
        <div className="sn-spin-tip">{tip}</div>
      </div>
    );
  }

  return <Spin {...newProps} />;
};

export default SNLoader;
