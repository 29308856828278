import { AddressApi } from "../../constants/system";

export type CompanyIndustryType = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const compIndustry: { [key in CompanyIndustryType]: string } = {
  1: "Facility Management",
  2: "Property Owner",
  3: "Service & maintenance",
  4: "Manufacturer",
  5: "Equipment rental",
  6: "Installation",
  7: "Others",
};

export interface CompanyDataApi {
  id: number;
  company_country: string | null;
  company_uuid: string;
  company_email: string | null;
  company_id: string;
  company_name: string;
  company_phone: string | null;
  allow_partnership_request: boolean;
  contacts: any[];
  created_at: string;
  address: AddressApi;
  created_by: {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
  } | null;
  description: string;
  industry_type: any | null;
  invoice_address: AddressApi;
  invoice_email: string | null;
  invoice_ref: string | null;
  is_active: boolean;
  logo: string | null;
  owner: {
    first_name: string;
    id: number;
    image: string | null;
    last_name: string;
    username: string;
    is_active: boolean;
  };
  updated_at: string;
  updated_by: any;
  vat_reg_number: any | null;
  website: string | null;
}

export interface CompanyStatsApi {
  user: number;
  node: number;
  asset: number;
  place: number;
  work_order: number;
  parts: number;
  invoice: number;
  form: number;
  error: number;
  service: number;
  serviceplan: number;
  task: number;
  document_storage: string;
  image_storage: string;
}

export interface CompanyUserListApi {
  id: number;
  user: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    image: string | null;
  };
  errors: number;
  services: number;
  company: any;
  first_name: string;
  last_name: string;
  email: string | null;
  status: 1 | 2;
}

export interface CompanyNodesListItem {
  id: number;
  company: {
    company_name: string;
  };
  start_node: string;
  end_node: string;
  created_at: string;
  status: number;
  initial: string;
  number: string;
  series: string;
  total: number;
}
