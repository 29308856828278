import { CompanyDataApi } from "./../../Companies/data.t";
import { SubscriptionApi } from "./../data.t";
export type InvoiceStatusType = 1 | 2 | 3 | 4;

export type SubInvoiceItemType = {
  id: number;
  description: string;
  date_from: string;
  date_to: string;
  unit: number;
  unit_price: string; // float number
  vat_percent: string; // float number
  quantity: number;
  sub_total: number;
};

export interface InvoiceApi {
  id: number;
  company: CompanyDataApi;
  amount_paid: number;
  subscription: SubscriptionApi;
  number: string;
  po_number: string;
  date: string;
  invoice_terms: number;
  due_date: string;
  discount: string; //float number
  invoice_ref: string;
  note: string;
  first_invoice: boolean;
  status: InvoiceStatusType;
  subscription_invoice_items: any[];
  sub_total: string; //float number
  vat_amount: string; //float number
  total: string; //float number
  invoice_to: any;
}

export const invoiceStatus: { [key in InvoiceStatusType]: string } = {
  1: "Draft",
  2: "Sent",
  3: "Paid",
  4: "Cancelled",
};
