import { Divider, Select, Spin } from "antd";
import { SelectProps } from "antd/lib/select";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import api from "../../../../api";
import SNButton from "../../SNButton";
import "./index.scss";
import useDataProList from "./service";
interface SNProSelectProps extends Omit<SelectProps<any>, "snProSelect"> {
  company?: any;
  limit?: number;
  placeholdertext?: string;
  urlObject: string;
  optionFormat: any;
  handleChange?: any;
  titleText?: any;
  getData?: (data: any) => void;
  onAddNew?: () => void;
}
export interface SNApiSelectRef {
  addNewOption: (newItem: any) => any;
  reload: any;
}

const SNApiSelect = forwardRef((props: SNProSelectProps, ref: any) => {
  const {
    company,
    urlObject,
    limit,
    handleChange,
    placeholdertext,
    titleText,
    optionFormat,
    optionLabelProp,
    getData,
    onAddNew,
    ...newProps
  } = props;

  const [offset, setOffset] = useState(0);

  const [isSearching, setIsSearching] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState<string>(
    `${urlObject.includes("?") ? "&" : "?"}offset=0&limit=${limit}`
  );

  const [data, isLoading, reload] = useDataProList(urlObject, filters);
  const [open, setOpen] = useState<boolean>(false);

  const typingTimeoutRef = useRef(null);
  const [options, setOptions] = useState<any>([]);

  const addNewOption = (opt: any) => {
    setOptions([opt, ...options]);
  };

  useImperativeHandle(
    ref,
    (): SNApiSelectRef => ({
      addNewOption,
      reload,
    })
  );

  useEffect(() => {
    if (data) {
      setOptions(data.results);
      if (getData) getData(data);
    }
  }, [isLoading]);

  const _onSearch = (value) => {
    setIsSearching(true);
    if (!value) {
      setIsSearching(false);
    }
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    setOffset(0);
    typingTimeoutRef.current = setTimeout(() => {
      setFilters(
        `&offset=0&limit=${limit}${(value && `&search=${value}`) || ""}`
      );
    }, 400);
  };

  const _handleChangeSelect = (val, opt) => {
    if (isSearching) {
      setIsSearching(false);
      setFilters(`&offset=0&limit=${limit}`);
    }
    if (handleChange) handleChange(val ? val.value : null, opt);
  };

  const _handleFetchMore = (moreResults) => {
    setOptions([...options, ...moreResults]);
  };

  const _handleScroll = (e) => {
    if (loading || isSearching) return;

    const isEndOfList =
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;

    const hasMore = options.length < data.count;
    if (hasMore && isEndOfList) {
      setLoading(true);

      setOffset(offset + limit);
      api
        .get(`${urlObject}&offset=${offset + limit}&limit=${limit}`, false)
        .then((response) => {
          _handleFetchMore(response?.results);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      {titleText && (
        <div className="ant-col ant-form-item-label">
          <label className="sn-form-label">{titleText || "Select"}</label>
        </div>
      )}

      <Select
        autoClearSearchValue={true}
        className="sn-select"
        optionLabelProp={optionLabelProp || "label"}
        allowClear
        labelInValue
        onSearch={_onSearch}
        filterOption={false}
        showSearch={true}
        loading={isLoading}
        showArrow={true}
        onPopupScroll={_handleScroll}
        listItemHeight={42}
        listHeight={limit < 50 ? 200 : 300}
        onSelect={_handleChangeSelect}
        placeholder={placeholdertext}
        onDropdownVisibleChange={setOpen}
        open={open}
        dropdownRender={(menu) => (
          <div style={{ overflow: isLoading ? "hidden" : "auto" }}>
            {onAddNew && (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    padding: 6,
                  }}
                >
                  <SNButton
                    type="link"
                    title="Add new"
                    icon="plus"
                    onClick={() => {
                      setOpen(false);
                      if (onAddNew) onAddNew();
                    }}
                  />
                </div>
                <Divider style={{ margin: "2px 0" }} />
              </>
            )}
            {menu}
            {(isLoading || loading) && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </div>
        )}
        {...newProps}
      >
        {options?.map((item) => {
          return optionFormat(item);
        }) || []}
      </Select>
    </div>
  );
});

export default SNApiSelect;
