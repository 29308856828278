import { Tag } from "antd";
import { authLoginType, AuthLogLoginType } from "./data.t";

export const getLoginTypeTag = (type: AuthLogLoginType) => {
  switch (type) {
    case 1:
      return <Tag color={"blue"}>{authLoginType[1]}</Tag>;
    case 2:
      return <Tag color={"success"}>{authLoginType[2]}</Tag>;
    case 3:
      return <Tag color={"cyan"}>{authLoginType[3]}</Tag>;
    case 4:
      return <Tag color={"gold"}>{authLoginType[4]}</Tag>;
    case 5:
      return <Tag color={"orange"}>{authLoginType[4]}</Tag>;
    default:
      return null;
  }
};
