import { Col, Row } from "antd";
import { FormInstance } from "antd/lib/form";

import SNFormItem from "../../../../../components/SN/SNForm/SNFormItem";
import SNTextarea from "../../../../../components/SN/SNForm/SNTextarea";
import SNTitle from "../../../../../components/SN/SNTitle";
import NodeGenerator from "../../../components/NodeGenerator";

interface NodeProductionTemplateFormProps {
  form: FormInstance;
  editData?: any;
  isLock?: boolean;
}

function NodeProductionTemplateForm(props: NodeProductionTemplateFormProps) {
  const { form } = props;

  const renderNodeInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "qrcode"]} title="Node information" />

        <NodeGenerator form={form} />
      </section>
    );
  };

  const renderGeneralInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info"]} title="Description" />

        <SNFormItem name="description">
          <SNTextarea placeholder="Add a short description (Optional)" />
        </SNFormItem>
      </section>
    );
  };

  return (
    <Row gutter={[40, 16]}>
      <Col xl={8} lg={12} md={12} xs={24}>
        {renderNodeInfo()}
      </Col>

      <Col xl={8} lg={12} md={12} xs={24}>
        {renderGeneralInfo()}
      </Col>
    </Row>
  );
}

export default NodeProductionTemplateForm;
