import { blue } from "@ant-design/colors";
import { SearchOutlined } from "@ant-design/icons";
import ProTable, {
  ActionType,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { Button, Input, Typography } from "antd";
import { ColumnType } from "antd/lib/table/interface";
import qs from "query-string";
import { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useLocation } from "react-router-dom";
import api, { url } from "../../../api";
import SNButton from "../../../components/SN/SNButton";
import SNButtonGroup from "../../../components/SN/SNButtonGroup";
import {
  showDeleteModal,
  showPatchModal,
} from "../../../components/SN/SNConfirmModal";
import { SNMenuItemProps } from "../../../components/SN/SNMenuItem";
import SNPanel from "../../../components/SN/SNPanel";
import { UserStatus } from "../../../components/Status";
import { useAsyncState } from "../../../hooks/useAsyncState";
import { formatName } from "../../../lib";
import { paramsToObject } from "../../../lib/locationSearch";
import path from "../../Root/routePaths";
import AccountDetailModal from "../components/AccountDetailModal";
import AccountFilter from "../components/AccountFilter";

const { Text } = Typography;

interface AllAccountsListItem {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  date_joined: string;
  is_active: boolean;
}

const AllAccounts = () => {
  const location = useLocation();
  const searchInputRef = useRef<any>();
  const listRef = useRef<ActionType>();
  const [search, setSearch] = useState<string>("");
  const [searchColumn, setSearchColumn] = useState<string>("");
  const [isVisibleInfoModal, setIsVisibleInfoModal] = useState<boolean>(false);
  const [selectedIdForInfo, setSelectedIdForInfo] = useAsyncState<any>(null);

  const _handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearch(selectedKeys[0]);
    setSearchColumn(dataIndex);
    listRef.current?.reload();
  };

  const _handleReset = (clearFilters) => {
    clearFilters();
    setSearch("");
    setSearchColumn("");
    listRef.current?.reload();
  };

  const _refreshList = () => {
    listRef.current?.reload();
  };

  /**
   * Render view region
   */

  const _getColumnSearchProps = (
    dataIndex: string,
    title: string
  ): Pick<
    ColumnType<AllAccountsListItem>,
    | "filterDropdown"
    | "filterIcon"
    | "onFilter"
    | "onFilterDropdownVisibleChange"
    | "render"
  > => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => _handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => _handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => _handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(typeof value === "string" && value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select());
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[search]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const _renderList = () => {
    const columns: ProColumns<AllAccountsListItem>[] = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "username",
        key: "email",
        sorter: true,
        ..._getColumnSearchProps("username", "Username"),
      },
      {
        title: "First name",
        dataIndex: "first_name",
        sorter: true,
        key: "first_name",
        ..._getColumnSearchProps("first_name", "First name"),
      },
      {
        title: "Last name",
        dataIndex: "last_name",
        key: "last_name",
        sorter: true,
        ..._getColumnSearchProps("last_name", "Last name"),
      },
      {
        title: "Has company ?",
        dataIndex: "company_total",
        key: "company_total",
        render: (value: any) => <Text strong>{value > 0 ? "YES" : "NO"}</Text>,
      },
      {
        title: "Last login",
        dataIndex: "last_login",
        key: "last_login",
        sorter: true,
        valueType: "dateTime",
      },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        render: (status) => <UserStatus status={status} />,
      },
      {
        title: "",
        key: "options",
        render: (value: any) => {
          const actions: SNMenuItemProps[] = !value.is_active
            ? [
                {
                  key: "approve",
                  icon: ["fal", "user-plus"],
                  title: "Approve",
                  disabled: value.is_active,
                  onClick: () => {
                    const displayName = formatName(
                      value.first_name,
                      value.last_name
                    );
                    showPatchModal({
                      url: `${url.USER}${value.id}/activate/`,
                      title: "Approve user",
                      content: "Are you sure you want to approve",
                      message404: "Approve user failed",
                      name: displayName.length ? displayName : value.username,
                      postAction: _refreshList,
                      successText: "User has been approved",
                    });
                  },
                },
                {
                  key: "divider",
                },
                {
                  key: "reject",
                  icon: ["fal", "user-check"],
                  iconcolor: "red",
                  title: "Delete",
                  onClick: () => {
                    const displayName = formatName(
                      value.first_name,
                      value.last_name
                    );
                    showDeleteModal({
                      url: `${url.USER}${value.id}/`,
                      title: "Delete user",
                      content: "Are you sure you want to delete",
                      message404: "Delete user failed",
                      name: displayName.length ? displayName : value.username,
                      postAction: _refreshList,
                      successText: "Deleted user successfully",
                    });
                  },
                },
              ]
            : [
                {
                  key: "info",
                  icon: ["fal", "info"],
                  title: "Details",
                  disabled: !value.is_active,
                  onClick: () =>
                    setSelectedIdForInfo(value.id, () => {
                      setIsVisibleInfoModal(true);
                    }),
                },
                {
                  key: "divider",
                },
                {
                  key: "reject",
                  icon: ["fal", "user-times"],
                  title: "Reject",
                  disabled: !value.is_active,
                  onClick: () => {
                    const displayName = formatName(
                      value.first_name,
                      value.last_name
                    );
                    showPatchModal({
                      url: `${url.USER}${value.id}/inactivate/`,
                      title: "Reject user",
                      content: "Are you sure you want to reject",
                      message404: "Reject user failed",
                      name: displayName.length ? displayName : value.username,
                      postAction: _refreshList,
                      successText: "Rejected user successfully",
                    });
                  },
                },
              ];
          return <SNButtonGroup dropdown={actions} />;
        },
      },
    ];

    return (
      <div>
        <ProTable<AllAccountsListItem, { search: any }>
          actionRef={listRef}
          request={async (
            { pageSize, current },
            sort
          ): Promise<RequestData<AllAccountsListItem>> => {
            const sortKey = Object.keys(sort).length
              ? Object.keys(sort)[0]
              : "";
            const sortParams = sortKey.length
              ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
              : "";
            const locationParams = qs.stringify(
              paramsToObject(location.search)
            );
            const urlRequest = `${url.USER}?limit=${pageSize}&offset=${
              (current - 1) * pageSize
            }${sortParams}${search ? `&${searchColumn}=${search}` : ""}${
              locationParams ? `&${locationParams}` : ""
            }`;

            const res = await api.get(urlRequest);

            return {
              data: res.data.results,
              success: true,
              total: res.data.count,
            };
          }}
          params={location.search}
          headerTitle="All accounts"
          search={false}
          options={{
            setting: false,
            fullScreen: false,
            density: false,
          }}
          toolBarRender={() => [
            <SNButton
              key="plus-button"
              title="Create account"
              iconcolor={blue.primary}
              icon="plus"
              link={path.register_accounts}
            />,
            <AccountFilter key="filter" />,
          ]}
          size={"small"}
          pagination={{ pageSize: 100, showQuickJumper: true }}
          rowKey={(key) => key.id}
          columns={columns}
          dateFormatter="string"
        />
        <AccountDetailModal
          id={selectedIdForInfo}
          isVisible={isVisibleInfoModal}
          onClose={() => {
            setSelectedIdForInfo(null);
            setIsVisibleInfoModal(false);
          }}
        />
      </div>
    );
  };

  return <SNPanel view="list">{_renderList()}</SNPanel>;
};

export default AllAccounts;
