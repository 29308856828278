/**
 * Dashboard route
 */
const dashboard = "/dashboard";
const analytics = `${dashboard}/analytics`;
const auth_log = `${dashboard}/auth-log`;
const new_feed = `${dashboard}/new-feed`;
const new_feed_details = `${dashboard}/news-feed/:id`;
const create_new_feed = `${dashboard}/create-news-feed`;
const edit_new_feed = `${dashboard}/edit-news-feed/:id`;

/**
 * Account route
 */
const accounts = "/accounts";
const all_accounts = `${accounts}/all-accounts`;
const register_accounts = `${accounts}/register-account`;

/**
 * Companies route
 */
const tenants = "/tenants";
const all_tenants = `${tenants}/all-tenants`;
const register_tenant = `${tenants}/register-tenant`;
const edit_tenant = `${tenants}/edit-tenant/:id`;
const tenant_details = `${tenants}/:id`;

/**
 * Nodes route
 */
const nodes = `/nodes`;
const all_nodes = `${nodes}/all-nodes`;
const allocate_node = `${nodes}/allocate-node-form`;
const create_allocate_node = `${nodes}/create-allocate`;
const allocate_node_list = `${nodes}/all-allocations`;
const all_production_nodes = `${nodes}/all-productions`;
const create_production_nodes = `${nodes}/create-productions`;
const edit_production_nodes = `${nodes}/edit-productions/:prod_id`;

/**
 * Subscriptions route
 */
const subscriptions = `/subscriptions`;
const all_subscriptions = `${subscriptions}/all-subscriptions`;
const create_subscription = `${subscriptions}/create-subscription`;
const subscription_details = `${subscriptions}/subscription/:sub_id`;
const edit_subscription = `${subscriptions}/edit-subscription/:sub_id`;
const all_sub_plans = `${subscriptions}/all-plans`;
const sub_plan_details = `${subscriptions}/plan/:plan_id`;
const create_sub_plan = `${subscriptions}/plan/create-plan`;
const edit_sub_plan = `${subscriptions}/plan/edit-plan/:plan_id`;
const all_invoices = `${subscriptions}/all-invoices`;
const create_invoice = `${subscriptions}/create-invoice`;
const edit_invoice = `${subscriptions}/edit-invoice/:invoice_id`;
const invoice_details = `${subscriptions}/invoice/:invoice_id`;
const all_payments = `${subscriptions}/all-payments`;
const payment_details = `${subscriptions}/payment/:pay_id`;
const edit_payment = `${subscriptions}/edit-payment/:pay_id`;
const register_payment = `${subscriptions}/register-payment`;

const path = {
  dashboard,
  accounts,
  register_accounts,
  tenants,
  tenant_details,
  all_tenants,
  all_accounts,
  register_tenant,
  edit_tenant,
  nodes,
  all_nodes,
  allocate_node,
  create_allocate_node,
  allocate_node_list,
  all_production_nodes,
  create_production_nodes,
  edit_production_nodes,
  subscriptions,
  all_subscriptions,
  subscription_details,
  edit_subscription,
  all_sub_plans,
  sub_plan_details,
  create_sub_plan,
  edit_sub_plan,
  all_invoices,
  create_invoice,
  edit_invoice,
  invoice_details,
  all_payments,
  register_payment,
  edit_payment,
  payment_details,
  analytics,
  create_subscription,
  auth_log,
  new_feed,
  new_feed_details,
  create_new_feed,
  edit_new_feed,
};

export default path;
