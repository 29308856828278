import { API_URL, API_VERSION } from "settings/env";

export const BASE = `${API_URL}/${API_VERSION}`;
export const TOKEN = `${API_URL}/o/token/`;
export const USER = `${BASE}/administration/user/`;
export const PROFILE = `${BASE}/user/profile/`;
export const COMPANY = `${BASE}/administration/company/`;
export const COMPANY_STATS = `${BASE}/stats/company/`;
export const COMPANY_USER = `${BASE}/administration/user/`;
export const NODE_ALLOCATION = `${BASE}/administration/snode/allocation/`;
export const ALL_NODES = `${BASE}/administration/snode/order/`;
export const NODE_PRODUCTION = `${BASE}/administration/snode/manufacture/`;
export const SUBSCRIPTION = `${BASE}/subscription/`;
export const SUBSCRIPTION_PLAN = `${BASE}/subscription/plan/`;
export const SUBSCRIPTION_INVOICE = `${BASE}/subscription/invoice/`;
export const SUBSCRIPTION_INVOICE_PAYMENT = `${BASE}/subscription/invoice/payment/`;
export const AUTH_LOG = `${BASE}/administration/log/auth/`;
export const NEWS_FEED = `${BASE}/administration/newsfeed/`;
export default {
  BASE,
  TOKEN,
  PROFILE,
  COMPANY,
  COMPANY_STATS,
  COMPANY_USER,
  USER,
  NODE_ALLOCATION,
  ALL_NODES,
  NODE_PRODUCTION,
  SUBSCRIPTION,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_INVOICE,
  SUBSCRIPTION_INVOICE_PAYMENT,
  AUTH_LOG,
  NEWS_FEED,
};
