import { Col, Image, message, Row, Space, Upload } from "antd";
import { FormInstance } from "antd/lib/form";
import { useState } from "react";
import images from "../../../../assets/img";
import SNButton from "../../SNButton";
import SNFormItem from "../SNFormItem";
import SNImageCropModal from "../SNImageCropModal";
import SNInput from "../SNInput";
import "./index.scss";
interface LogoFormItemProps {
  form: FormInstance;
  existingImg?: any;
  name: any;
  hideActions?: boolean;
}
const dummyRequest = ({ file, onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function LogoFormItem(props: LogoFormItemProps) {
  const { form, existingImg, name, hideActions } = props;

  const [selectedImg, setSelectedImg] = useState<any>(
    existingImg || images.PLACEHOLDER
  );
  const [isCropModalVisible, setIsCropModalVisible] = useState<boolean>(false);

  const [croppedImage, setCroppedImage] = useState<any>(
    existingImg || images.PLACEHOLDER
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return undefined;
    }
    getBase64(file, (imageUrl) => {
      setSelectedImg(imageUrl);
      setIsCropModalVisible(true);
    });
    return isJpgOrPng && isLt2M;
  }
  const uploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: beforeUpload,
    accept: "image/gif,image/jpeg,image/jpg,image/png",
  };

  return (
    <div className="sn-logo-form-item-container">
      <SNFormItem name={name} noStyle>
        <SNInput hidden />
      </SNFormItem>
      <Row>
        <Col span={14}>
          <Image
            src={croppedImage ?? images.PLACEHOLDER}
            alt="Logo"
            className="sn-border"
            preview={true}
          />
        </Col>
        {hideActions ? null : (
          <Col span={10}>
            <Space direction="vertical">
              <Upload
                {...uploadProps}
                customRequest={dummyRequest}
                showUploadList={false}
              >
                <SNButton title="Add" className="sn-btn-big" />
              </Upload>

              <SNButton
                title="Remove"
                onClick={() => {
                  setCroppedImage(null);
                  form.setFieldsValue({ [name]: null });
                }}
                danger
                className="sn-btn-big"
              />
            </Space>
          </Col>
        )}
      </Row>
      <SNImageCropModal
        image={selectedImg}
        isVisible={isCropModalVisible}
        onClose={() => setIsCropModalVisible(false)}
        postActions={(img) => {
          setCroppedImage(img);
          form.setFieldsValue({ [name]: { name: "logo", content: img } });
          setIsCropModalVisible(false);
        }}
      />
    </div>
  );
}

export default LogoFormItem;
