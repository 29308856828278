import ProTable, {
  ActionType,
  enUSIntl,
  IntlProvider,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { Col } from "antd";
import { useContext, useRef } from "react";
import api, { url } from "../../../../api";
import { formatDate, generateEndNodeId } from "../../../../lib";
import { CompanyNodesListItem } from "../../data.t";
import CompanyDetailsContext from "../context";

const QRTab = () => {
  const { companyId } = useContext(CompanyDetailsContext);
  const listRef = useRef<ActionType>();

  const _renderNodesList = () => {
    const listColumns: ProColumns<CompanyNodesListItem>[] = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Allocated on",
        dataIndex: "created_at",
        key: "created_at",
        render: (value) => formatDate(value, "YYYY-MM-DD, HH:mm"),
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
      {
        title: "Start node",
        key: "start_node",
        render: ({ initial, number, series }: CompanyNodesListItem) => (
          <span>{`${initial}${number}${series}`}</span>
        ),
      },
      {
        title: "End node",
        key: "end_node",
        render: ({ initial, number, total, series }: CompanyNodesListItem) => (
          <span>{generateEndNodeId(initial, number, total, series)}</span>
        ),
      },
    ];

    return (
      <Col xl={24} lg={24} md={24} xs={24} className="sn-card-content-body top">
        <IntlProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
          <ProTable<CompanyNodesListItem>
            actionRef={listRef}
            request={async (
              { pageSize, current, keyword },
              sort,
              filter
            ): Promise<RequestData<CompanyNodesListItem>> => {
              const sortKey = Object.keys(sort).length
                ? Object.keys(sort)[0]
                : "";
              const sortParams = sortKey.length
                ? `&ordering=${
                    sort[sortKey] === "descend" ? "-" : ""
                  }${sortKey}`
                : "";

              const urlRequest = `${
                url.NODE_ALLOCATION
              }?limit=${pageSize}&offset=${
                (current - 1) * pageSize
              }${sortParams}&company=${companyId}`;

              const res = await api.get(urlRequest);

              return {
                data: res.data.results,
                success: true,
                total: res.data.count,
              };
            }}
            search={false}
            options={false}
            size={"small"}
            pagination={{ pageSize: 100, showQuickJumper: true }}
            rowKey={(key) => key.id}
            columns={listColumns}
            dateFormatter="string"
          />
        </IntlProvider>
      </Col>
    );
  };

  return <>{_renderNodesList()}</>;
};

export default QRTab;
