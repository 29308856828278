import api from "../../../api";
import { successAlert } from "../../../lib/alertHelper";
import showAntConfirm from "../../AntConfirm/AntConfirm";

export function showDeleteModal(props) {
  const {
    title,
    content,
    name,
    postAction,
    translate,
    url,
    successText,
    cancelText,
    okText,
  } = props;
  const deleteAction = () => {
    api.del(`${url}`).then((response) => {
      if (response && (response.status === 204 || response.status === 200)) {
        successAlert(successText);
        if (postAction) postAction();
      }
    });
  };

  showAntConfirm({
    title: title,
    content: content,
    name: name || "",
    okText: okText,
    okType: "danger",
    cancelText: cancelText,
    onOk: deleteAction,
    translate,
  });
}

export function showPatchModal(props) {
  const {
    title,
    content,
    name,
    postAction,
    translate,
    url,
    successText,
    cancelText,
    okText,
    params,
  } = props;
  const patchAction = () => {
    api.patch(`${url}`, params).then((response) => {
      if (response && (response.status === 204 || response.status === 200)) {
        successAlert(successText);
        if (postAction) postAction();
      }
    });
  };

  showAntConfirm({
    title: title,
    content: content,
    name: name || "",
    okText: okText,
    okType: "",
    cancelText: cancelText,
    onOk: patchAction,
    translate,
  });
}
