import { Route, Switch } from "react-router-dom";
import SNContentWrap from "../../components/SN/SNLayout/SNContentWrap";
import path from "../../screens/Root/routePaths";
import Analytics from "./Analytics";
import AuthLog from "./AuthLog";
import NewsFeed from "./NewsFeed";
import NewsFeedDetails from "./NewsFeed/NewsFeedDetails";
import NewsFeedForm from "./NewsFeed/NewsFeedForm";
const {
  analytics,
  auth_log,
  new_feed,
  create_new_feed,
  edit_new_feed,
  new_feed_details,
} = path;

function Dashboard() {
  return (
    <SNContentWrap title="Dashboard">
      <Switch>
        <Route exact path={analytics} component={Analytics} />
        <Route exact path={auth_log} component={AuthLog} />
        <Route exact path={new_feed} component={NewsFeed} />
        <Route
          exact
          path={[create_new_feed, edit_new_feed]}
          component={NewsFeedForm}
        />
        <Route exact path={new_feed_details} component={NewsFeedDetails} />
      </Switch>
    </SNContentWrap>
  );
}

export default Dashboard;
