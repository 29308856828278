import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { FC } from "react";
import SNBadge from "../../SNBadge";
import "./index.scss";
const { Option } = Select;

interface SNSelectProps extends Omit<SelectProps<any>, "snSelect"> {
  placeholder?: string;
}
const SNSelect: FC<SNSelectProps> = (props) => {
  const { options, style, placeholder, className, ...newProps } = props;

  return (
    <Select
      className={`${className} sn-select`}
      filterOption={(input: any, option: any) =>
        option.label
          ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          : false
      }
      style={style}
      showSearch
      placeholder={placeholder}
      {...newProps}
    >
      {options?.map((item: any, key) => {
        return (
          <Option
            value={item.value}
            data={item?.data}
            key={key}
            label={item.label || ""}
          >
            <div className="sn-option-label-item">
              {item.render ? item.render : item.label}

              {(item.number || item.number === 0) && (
                <SNBadge
                  count={item.number}
                  showZero
                  className="sn-badge-select-item"
                />
              )}
            </div>
          </Option>
        );
      })}
    </Select>
  );
};

export default SNSelect;
