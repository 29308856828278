import { Route, Switch } from "react-router-dom";
import SNContentWrap from "../../components/SN/SNLayout/SNContentWrap";
import AllAccounts from "../../screens/Accounts/AllAccounts";
import path from "../../screens/Root/routePaths";
import AccountForm from "./AccountForm";

const { all_accounts, register_accounts } = path;

const Accounts = () => {
  return (
    <SNContentWrap title="Accounts">
      <Switch>
        <Route path={all_accounts} exact component={AllAccounts} />
        <Route path={register_accounts} exact component={AccountForm} />
      </Switch>
    </SNContentWrap>
  );
};

export default Accounts;
