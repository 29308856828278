import { Col, Form, Row, Space } from "antd";
import SNDetailsLoading from "components/SN/SNDetailsLoading";
import snForm from "lib/form";

import path from "screens/Root/routePaths";
import SNButton from "../../../components/SN/SNButton";
import SNPanel from "../../../components/SN/SNPanel";
import { successAlert } from "../../../lib/alertHelper";
import CompanyForm from "../components/CompanyForm";
import companyService from "../service";

const CompanyFormAction = (props) => {
  const { history, match } = props;
  const companyId = match.params.id;

  const [form] = Form.useForm();
  const [compData] = companyService.useCompanyDetails(companyId);

  if (companyId && !compData) return <SNDetailsLoading />;

  const handleCreateCompany = (values) => {
    const formData = {
      ...values,
      address: values.companyAddress.address ? values.companyAddress : null,
      user: values.user?.value,
    };

    companyService.addCompany(formData, (res) => {
      history.push(path.all_tenants);
      successAlert(`${res.company_name} has been created`);
    });
  };

  const handleEditCompany = (values: any) => {
    const formData = {
      ...values,
      address: snForm.addressData(values.companyAddress),
      invoice_address: snForm.addressData(values.invoice_address),
    };

    companyService.editCompany(companyId, formData, (res) => {
      history.push(path.all_tenants);
      successAlert(`${res.company_name} has been updated`);
    });
  };

  const onFinish = (values) => {
    if (compData) {
      handleEditCompany(values);
    } else {
      handleCreateCompany(values);
    }
  };

  return (
    <SNPanel panelTitle="Register new company">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={
          compData
            ? {
                ...compData,
                companyAddress: compData.address,
              }
            : {
                allow_partnership_request: false,
              }
        }
        className="sn-form"
      >
        <CompanyForm form={form} editData={compData} />

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={() => {
                  history.push("/tenants/all-tenants");
                }}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default CompanyFormAction;
