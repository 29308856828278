import { Col, DatePicker, FormInstance, Row } from "antd";
import { FC } from "react";
import SNFormItem from "../../../../../../components/SN/SNForm/SNFormItem";
import SNSelect from "../../../../../../components/SN/SNForm/SNSelect";
import SNTextarea from "../../../../../../components/SN/SNForm/SNTextarea";
import SNPriceInput from "../../../../../../components/SN/SNPriceInput";
import SNTitle from "../../../../../../components/SN/SNTitle";
import SNInvoicesSelect from "../../../../../components/EntitiesSelect/SNInvoicesSelect";
import SNUsersSelect from "../../../../../components/EntitiesSelect/SNUsersSelect";
import { PaymentApi } from "../../../data.t";

interface PaymentTemplateFormProps {
  form: FormInstance;
  editData?: PaymentApi;
}

const PaymentTemplateForm: FC<PaymentTemplateFormProps> = (props) => {
  const { form } = props;

  const renderGeneralInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info-circle"]} title="General information" />

        <SNInvoicesSelect
          name="invoice"
          label="Invoice"
          placeholder="Select invoice from the system"
          required
        />

        <SNPriceInput
          form={form}
          name="pay_amount"
          label="Pay amount"
          required
        />

        <SNFormItem label="Pay date" name="pay_date" required>
          <DatePicker />
        </SNFormItem>

        <SNFormItem label="Pay method" name="pay_method">
          <SNSelect
            options={[
              { label: "Cash", value: 1 },
              { label: "Card", value: 2 },
              { label: "Bank", value: 3 },
            ]}
            placeholder="Select payment method"
          />
        </SNFormItem>

        <SNUsersSelect
          name="received_by"
          label="Received by"
          placeholdertext="Select employees from the list"
          required
        />

        <SNFormItem label="Note (Optional)" name="note">
          <SNTextarea
            placeholder="Add a short note for this payment"
            autoSize={{ minRows: 5, maxRows: 10 }}
          />
        </SNFormItem>
      </section>
    );
  };

  return (
    <>
      <Row gutter={[40, 16]}>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderGeneralInfo()}
        </Col>
      </Row>
    </>
  );
};

export default PaymentTemplateForm;
