import { Route, Switch } from "react-router-dom";
import SNContentWrap from "../../components/SN/SNLayout/SNContentWrap";
import path from "../Root/routePaths";
import AllCompanies from "./AllCompanies";
import CompanyDetails from "./CompanyDetails";
import CompanyFormAction from "./CompanyFormAction";

const { all_tenants, register_tenant, tenant_details, edit_tenant } = path;

const Companies = (props) => {
  return (
    <SNContentWrap title={"Companies"}>
      <Switch>
        <Route exact path={all_tenants} component={AllCompanies} />
        <Route exact path={register_tenant} component={CompanyFormAction} />
        <Route exact path={tenant_details} component={CompanyDetails} />
        <Route exact path={edit_tenant} component={CompanyFormAction} />
      </Switch>
    </SNContentWrap>
  );
};

export default Companies;
