import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { rootReducer, RootState } from "./Reducers";

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

const persistReducers = persistReducer<RootState>(persistConfig, rootReducer);

const store = configureStore({
  reducer: { persistReducers },
  middleware: getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export default store;
