import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";

interface SNInputNumberProps extends InputNumberProps {}

function SNInputNumber(props: SNInputNumberProps) {
  const { min, ...restProps } = props;

  return <InputNumber {...restProps} min={min || 0} />;
}

export default SNInputNumber;
