import { blue } from "@ant-design/colors";
import ProTable, {
  ActionType,
  enUSIntl,
  IntlProvider,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { useRef } from "react";
import { Link } from "react-router-dom";
import api, { url } from "../../../../api";
import SNButton from "../../../../components/SN/SNButton";
import SNButtonGroup from "../../../../components/SN/SNButtonGroup";
import { SNMenuItemProps } from "../../../../components/SN/SNMenuItem";
import SNPanel from "../../../../components/SN/SNPanel";
import path from "../../../Root/routePaths";
import { PaymentApi } from "../data.t";

const AllPayments = ({ history }) => {
  const listRef = useRef<ActionType>();

  const _renderList = () => {
    const columns: ProColumns<PaymentApi>[] = [
      {
        dataIndex: "id",
        key: "id",
        title: "ID",
        width: 70,
        sorter: true,
      },
      {
        dataIndex: "pay_date",
        key: "pay_date",
        title: "Pay date",
        valueType: "date",
      },
      {
        dataIndex: "invoice",
        key: "invoice",
        title: "Invoice",
        render: (value: PaymentApi["invoice"]) => (
          <Link
            to={{
              pathname: path.invoice_details.replace(
                ":invoice_id",
                `${value.id}`
              ),
            }}
          >
            {value.number}
          </Link>
        ),
      },
      {
        dataIndex: "invoice_total",
        key: "invoice_total",
        title: "Invoice total",
        render: (value: any, data: PaymentApi) => `${data.invoice.total} (SEK)`,
      },
      {
        dataIndex: "pay_amount",
        key: "pay_amount",
        title: "Pay amount",
        render: (value: any) => (value ? `${value} (SEK)` : ""),
      },
      {
        dataIndex: "pay_method",
        key: "pay_method",
        title: "Pay method",
        render: (value: any) => "",
      },
      {
        key: "actions",
        width: 50,
        fixed: "right",
        search: false,
        render: (value: any, data: any) => {
          const actions: SNMenuItemProps[] = [
            {
              key: "info",
              icon: ["fal", "info"],
              title: "Details",
              link: path.payment_details.replace(":pay_id", data.id),
            },
            {
              key: "edit",
              icon: ["fal", "pencil-alt"],
              title: "Edit",
              link: path.edit_payment.replace(":pay_id", data.id),
            },
          ];
          return <SNButtonGroup dropdown={actions} />;
        },
      },
    ];

    return (
      <IntlProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
        <ProTable<PaymentApi, { search: string }>
          actionRef={listRef}
          request={async (
            { pageSize, current, keyword },
            sort,
            filter
          ): Promise<RequestData<PaymentApi>> => {
            const sortKey = Object.keys(sort).length
              ? Object.keys(sort)[0]
              : "";
            const sortParams = sortKey.length
              ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
              : "";

            const urlRequest = `${
              url.SUBSCRIPTION_INVOICE_PAYMENT
            }?limit=${pageSize}&offset=${
              (current - 1) * pageSize
            }${sortParams}`;
            const res = await api.get(urlRequest);

            return {
              data: res.data.results,
              success: true,
              total: res.data.count,
            };
          }}
          headerTitle="All payments"
          search={false}
          toolBarRender={() => [
            <SNButton
              key="plus-button"
              title="Create payment"
              iconcolor={blue.primary}
              icon="plus"
              link={path.register_payment}
            />,
            <SNButton
              key="filter-button"
              title="Filters"
              icon={["far", "filter"]}
              iconcolor={blue.primary}
            />,
          ]}
          options={{
            setting: false,
            fullScreen: false,
            density: false,
          }}
          size={"small"}
          pagination={{ pageSize: 100, showQuickJumper: true }}
          rowKey={(key) => key.id}
          columns={columns}
          dateFormatter="string"
        />
      </IntlProvider>
    );
  };

  return <SNPanel view="list">{_renderList()}</SNPanel>;
};

export default AllPayments;
