import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button, Dropdown, Menu } from "antd";

import { useRouteMatch } from "react-router";
import SNButton from "../SNButton";
import SNMenuItem from "../SNMenuItem";
import SNTooltip from "../SNTooltip";
import "./index.scss";
const ButtonGroup = Button.Group;

export interface ButtonGroupItemProps {
  tips?: string;
  link?: any;
  onClick?: any;
  icon?: IconProp | IconName;
  iconcolor?: string;
  id: string | number;
  disabled?: boolean;
  href?: any;
  target?: any;
  rel?: any;
}

interface SNButtonGroupProps {
  items?: ButtonGroupItemProps[];
  disabled?: boolean;
  className?: string;
  dropdown?: any;
  isNavBar?: any;
  iconDropDown?: any;
}
function SNButtonGroup(props: SNButtonGroupProps) {
  const {
    items = [],
    disabled,
    className = "",
    dropdown,
    isNavBar,
    iconDropDown = "ellipsis-v",
  } = props;

  const match = useRouteMatch();
  const { path } = match;

  const dropdownMenu =
    dropdown && Array.isArray(dropdown) ? (
      <Menu>
        {dropdown.map((item, key) =>
          !item.key || item.key.toString().startsWith("divider") ? (
            <Menu.Divider key={key} />
          ) : (
            <SNMenuItem {...item} />
          )
        )}
      </Menu>
    ) : (
      dropdown
    );

  return (
    <ButtonGroup
      className={`sn-group-actions-list sn-button-group ${className}`}
    >
      {items.map((item: ButtonGroupItemProps, key) => (
        <SNTooltip placement="top" text={item.tips || ""} key={key}>
          <SNButton
            disabled={disabled || item.disabled}
            icon={item.icon}
            iconcolor={item.iconcolor}
            link={item.link}
            onClick={item.onClick}
            key={item.id}
            href={item.href}
            target={item.target}
            rel={item.rel}
            className={
              (isNavBar && item.link === path && "sn-btn-nav-matched") || ""
            }
          />
        </SNTooltip>
      ))}
      {dropdown && (
        <Dropdown overlay={dropdownMenu} key="actions-dropdown">
          <SNButton icon={iconDropDown} className="sn-group-btn-icon" />
        </Dropdown>
      )}
    </ButtonGroup>
  );
}

export default SNButtonGroup;
