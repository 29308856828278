import { createContext } from "react";
import { CompanyDataApi, CompanyStatsApi } from "./../data.t";

const CompanyDetailsContext = createContext<{
  companyData: CompanyDataApi | null;
  companyStats: CompanyStatsApi | null;
  companyId: number;
}>({ companyData: null, companyStats: null, companyId: null });

export default CompanyDetailsContext;
