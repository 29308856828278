import SNIcon from "../SNIcon";

interface SNCheckLabelProps {
  label: any;
  check: boolean;
}

function SNCheckLabel(props: SNCheckLabelProps) {
  const { label, check } = props;
  return (
    <span style={{ alignItems: "center", display: "flex" }}>
      {" "}
      {check ? (
        <SNIcon
          icon={["fas", "check-square"]}
          color="#42a5f5"
          style={{ marginRight: 12, fontSize: 16 }}
        />
      ) : (
        <SNIcon
          icon={["fas", "times-square"]}
          color="#ffc069"
          style={{ marginRight: 12, fontSize: 16 }}
        />
      )}
      {label}
    </span>
  );
}

export default SNCheckLabel;
