import { Tooltip } from "antd";
import { TooltipProps } from "antd/lib/tooltip";
import { FC, ReactNode } from "react";
interface SNTooltipProps extends Omit<TooltipProps, "snTooltip"> {
  text: string | ReactNode;
}

const SNTooltip: FC<SNTooltipProps> = (props) => {
  const { text, ...rest } = props;
  return (
    <Tooltip {...rest} title={text} overlayClassName="antd-tooltip-card" />
  );
};

export default SNTooltip;
