import { useHistory } from "react-router-dom";
let globalHistory = null;

const History = (props) => {
  globalHistory = useHistory();

  return <div />;
};

export default History;

export function getHistory() {
  return globalHistory;
}
