import ProTable, {
  enUSIntl,
  IntlProvider,
  ProColumns,
} from "@ant-design/pro-table";
import { Alert, Checkbox, Row, Space } from "antd";
import Modal, { ModalProps } from "antd/lib/modal/Modal";
import _ from "lodash";
import {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { ORIGIN_URL } from "settings/env";
import SNButton from "../../../../components/SN/SNButton";
import { pickKeysFromArrayObj } from "../../../../lib";
import { NodeOrderApi } from "../../data.t";
import "./index.scss";
import { exportNodeService } from "./service";

export interface ExportNodeModalRef {
  show: () => void;
  close: () => void;
}

export interface ExportNodeModalProps extends ModalProps {
  data?: NodeOrderApi[];
  ref: any;
}

type DataSourceType = {
  id: React.Key;
  company?: string;
  address?: string;
  tagID?: string;
  qrCode?: string;
};

const exportFields = {
  company: "Company",
  address: "Address",
  tagID: "TagID",
  qrCode: "QR-Code",
};

const ExportNodeModal: FC<ExportNodeModalProps> = forwardRef((props, ref) => {
  const { data, onCancel, ...restProps } = props;
  const [isVisible, setIsVisible] = useState(false);

  useImperativeHandle(
    ref,
    (): ExportNodeModalRef => ({
      show: () => setIsVisible(true),
      close: () => setIsVisible(false),
    })
  );

  const defaultData = useMemo(() => {
    return (
      data?.map((item, index) => {
        return {
          id: (Date.now() + index).toString(),
          company: item.allocate?.company.company_name || "",
          address: "",
          tagID: item.node_id,
          qrCode: `${ORIGIN_URL.replace("https://", "")}${item.node_id}`,
        };
      }) || []
    );
  }, [data]);

  // const [editableKeys, setEditableRowKeys] = useState<React.Key[]>(() =>
  //   defaultData.map((item) => item.id)
  // );
  const [dataSource, setDataSource] = useState<DataSourceType[]>(
    () => defaultData
  );
  const [selectedFields, setSelectedFields] = useState<any[]>(
    Object.keys(exportFields)
  );

  useEffect(() => {
    // setEditableRowKeys(defaultData.map((item) => item.id));
    setDataSource(defaultData);
  }, [defaultData]);

  const columns: ProColumns<DataSourceType>[] = useMemo(() => {
    const allColumns: ProColumns<DataSourceType>[] = [
      {
        title: exportFields["company"],
        key: "company",
        dataIndex: "company",
        editable: false,
      },
      {
        title: exportFields["address"],
        key: "address",
        dataIndex: "address",
        width: 200,
      },
      {
        title: exportFields["tagID"],
        key: "tagID",
        dataIndex: "tagID",
        editable: false,
      },
      {
        title: exportFields["qrCode"],
        key: "qrCode",
        dataIndex: "qrCode",
        editable: false,
      },
    ];
    return _.pullAllBy(
      allColumns,
      _.pullAll(Object.keys(exportFields), selectedFields).map((x) => {
        return { key: x };
      }),
      "key"
    );
  }, [selectedFields]);

  const _renderFieldOptions = () => {
    return (
      <section>
        <Alert message="Select fields you want to export with" type="info" />

        <div className="checkbox-group">
          <Checkbox.Group
            defaultValue={selectedFields}
            value={selectedFields}
            onChange={(checkedValues) => setSelectedFields(checkedValues)}
          >
            <Row gutter={[6, 0]}>
              {Object.keys(exportFields).map((item, key) => (
                <Checkbox
                  key={key}
                  value={item}
                  disabled={item === "tagID" || item === "qrCode"}
                >
                  {exportFields[item]}
                </Checkbox>
              ))}
            </Row>
          </Checkbox.Group>
        </div>
      </section>
    );
  };

  const _renderMappingData = () => {
    return (
      <IntlProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
        <ProTable<DataSourceType>
          headerTitle="Review data"
          columns={columns}
          rowKey={(key) => key.id}
          dataSource={dataSource}
          scroll={{ y: "calc(100vh - 360px)" }}
          search={false}
          size={"small"}
          options={false}
          pagination={false}
        />
      </IntlProvider>
    );
  };

  return (
    <Modal
      visible={isVisible}
      className="export-node-modal"
      title="Export nodes"
      width={"calc(100vw - 400px)"}
      destroyOnClose
      maskClosable={false}
      onCancel={() => setIsVisible(false)}
      onOk={() => setIsVisible(false)}
      footer={
        <Space size={10}>
          <SNButton
            title="Cancel"
            className="sn-btn-big"
            onClick={() => setIsVisible(false)}
          />
          <SNButton
            title="Export to CSV"
            type="primary"
            className="sn-btn-big"
            onClick={() => {
              exportNodeService.toCSV(
                pickKeysFromArrayObj(dataSource, selectedFields)
              );
            }}
          />
          <SNButton
            title="Export to XLS"
            type="primary"
            className="sn-btn-big"
            onClick={() => {
              exportNodeService.toXLS(
                pickKeysFromArrayObj(dataSource, selectedFields)
              );
            }}
          />
        </Space>
      }
      style={{ top: 20 }}
      {...restProps}
    >
      <div>
        {_renderFieldOptions()}
        {_renderMappingData()}
      </div>
    </Modal>
  );
});

export default ExportNodeModal;
