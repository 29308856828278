export type PlanStatusType = 1 | 2;
export type PlanEntityType =
  | "workorder"
  | "asset"
  | "snode"
  | "user"
  | "place"
  | "invoice"
  | "form";
export type PlanBillingType = 0 | 1 | 2 | 3;

export type PlanSetting = {
  id: number;
  plan: number;
  entity: PlanEntityType;
  type: number;
  entity_limit: number;
  entity_price: string; // float number
  price: string; // float number
};

export interface PlanApi {
  id: number;
  name: string;
  code: string;
  billing_period: PlanBillingType;
  description: string;
  is_public: boolean;
  status: PlanStatusType;
  plan_settings: PlanSetting[];
  price: string; // float number,
  created_by: any;
  created_at: string;
}

export const planBillingPeriod: { [key in PlanBillingType]: string } = {
  0: "No billing",
  1: "Monthly",
  2: "Quarterly",
  3: "Annually",
};

export const planStatus: { [key in PlanStatusType]: string } = {
  1: "Active",
  2: "Inactive",
};

export const planEntity: { [key in PlanEntityType]: string } = {
  asset: "Asset",
  user: "User",
  workorder: "Work order",
  snode: "Node",
  place: "Place",
  invoice: "Invoice",
  form: "Form",
};
