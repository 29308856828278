import ProDescriptions from "@ant-design/pro-descriptions";

import SNTitle from "../SNTitle";
import { MapRowSize, SNDescriptionProps } from "./data.t";

const SNDescription = ({
  icon,
  title,
  children,
  rowSize,
  itemData,
  ...props
}: SNDescriptionProps) => {
  const _renderItem = () => {
    return itemData.map((item, index) => {
      return (
        <ProDescriptions.Item
          key={index}
          label={item.label}
          labelStyle={{
            width: rowSize
              ? rowSize[0]
              : item.labelWidth || MapRowSize.oneColumn[0],
            ...item.labelStyle,
          }}
          contentStyle={{
            width: rowSize
              ? rowSize[1]
              : item.contentWidth || MapRowSize.oneColumn[1],
            ...item.contentStyle,
          }}
          {...item}
        >
          {item.value}
        </ProDescriptions.Item>
      );
    });
  };

  const _renderTitle = !title ? null : typeof title === "string" ? (
    <SNTitle icon={icon} title={title} />
  ) : (
    title
  );

  return (
    <ProDescriptions title={_renderTitle} {...props}>
      {_renderItem()}
    </ProDescriptions>
  );
};

export default SNDescription;
