import { FormInstance } from "antd/lib/form";

import SNFormItem, { SNFormItemProps } from "../SNForm/SNFormItem";
import SNInput, { SNInputProps } from "../SNForm/SNInput";

interface SNPriceInputProps {
  currency?: string;
  className?: string;
  name?: any;
  label?: any;
  hideCurrency?: boolean;
  form: FormInstance;
  allowClear?: boolean;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  formProps?: SNFormItemProps;
  inputProps?: SNInputProps;
}

function SNPriceInput(props: SNPriceInputProps) {
  const {
    currency,
    hideCurrency,
    label,
    name,
    className,
    form,
    allowClear,
    disabled,
    required,
    autoFocus,
    inputProps,
    formProps,
  } = props;

  return (
    <SNFormItem
      {...formProps}
      name={name}
      required={required}
      rules={[
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (!value || (value && !isNaN(value))) {
              return Promise.resolve();
            }

            return Promise.reject(
              "Invalid value, must be a number: 2, 55.99, 100,..."
            );
          },
        }),
      ]}
      label={label}
      className={className}
    >
      <SNInput
        {...inputProps}
        addonAfter={!hideCurrency && (currency || "SEK")}
        onBlur={(e: any) => {
          if (e.target.value === "0") return form.setFieldsValue({ [name]: 0 });
          if (e.target.value === "" || e.target.value === 0)
            return form.setFieldsValue({ [name]: "" });
          if (isNaN(e.target.value)) {
            form.setFieldsValue({ [name]: "" });
          } else {
            form.setFieldsValue({
              [name]: parseFloat(e.target.value).toFixed(2) || 0,
            });
          }
        }}
        type="number"
        placeholder="0.00"
        allowClear={allowClear}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </SNFormItem>
  );
}

export default SNPriceInput;
