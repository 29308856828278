import { Col, Form, Row, Space } from "antd";
import { useState } from "react";
import { Prompt } from "react-router-dom";
import SNButton from "../../../../components/SN/SNButton";
import SNPanel from "../../../../components/SN/SNPanel";
import { warningAlert } from "../../../../lib/alertHelper";
import InvoiceTemplateForm from "./InvoiceTemplateForm";
import { initInvoiceItems } from "./InvoiceTemplateForm/InvoiceItemSectionForm";

const InvoiceForm = (props) => {
  const { history, match } = props;
  const [form] = Form.useForm();

  const { invoice_id } = match.params;

  const [isFormChanged, setIsFormChanged] = useState(false);

  let initialValues: any = {
    invoice_terms: 15,
    invoice_items: [initInvoiceItems],
    total_vat: 0,
    total_price: 0,
  };

  // const [editData] = planService.usePlanDetails(invoice_id);
  // if (invoice_id && !editData) return <SNFormLoading />;

  // if (editData) {
  //   initialValues = {
  //     ...editData,
  //     billing_period: planBillingPeriod.hasOwnProperty(editData.billing_period)
  //       ? editData.billing_period
  //       : 0,
  //     setting_price: editData.invoice_items.length
  //       ? editData.invoice_items
  //           .map((x) => x.price ?? "0")
  //           .reduce((a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2))
  //       : "0",
  //   };
  // }

  const onFinish = (values) => {
    // const formData = {
    //   ...values,
    // };
    // if (invoice_id) {
    //   planService.editPlan(invoice_id, formData, () => {
    //     setIsFormChanged(false);
    //     history.goBack();
    //     successAlert("Subcription plan has been edited");
    //   });
    // } else {
    //   planService.addPlan(formData, () => {
    //     setIsFormChanged(false);
    //     history.push(path.all_sub_plans);
    //     successAlert("Subcription plan has been created");
    //   });
    // }
    warningAlert("Api did not ready yet");
  };

  const handleOnChange = (changedField, allFields) => {
    if (!isFormChanged) setIsFormChanged(true);
    const { invoice_items } = allFields;

    const newItems = invoice_items
      ? invoice_items.map((item) => {
          const { discount, price, quantity } = item;

          if (price && quantity) {
            const entityPrice =
              parseInt(quantity) * parseFloat(price) -
              (discount && parseFloat(discount) >= 0
                ? parseFloat(discount)
                : 0);

            return {
              ...item,
              total_entity: entityPrice,
            };
          }

          return {
            ...item,
            total_entity: 0,
          };
        })
      : [];

    form.setFieldsValue({ invoice_items: newItems });
    form.setFieldsValue({
      total_vat: newItems
        .map((x) => (parseInt(x.vat) / 100) * parseFloat(x.total_entity))
        .reduce((a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2), 0),
    });
    form.setFieldsValue({
      total_price: newItems
        .map((x) => x.total_entity ?? 0)
        .reduce((a, b) => (parseFloat(a) + parseFloat(b)).toFixed(2), 0),
    });
  };

  return (
    <SNPanel panelTitle={invoice_id ? "Edit invoice" : "Create new invoice"}>
      <Prompt
        when={isFormChanged}
        message={
          "You may have unsaved data on this form. Leaving this form will discard all the changes!"
        }
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        className="sn-form"
        onValuesChange={handleOnChange}
      >
        <InvoiceTemplateForm form={form} />

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={() => {
                  history.goBack();
                }}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default InvoiceForm;
