import { SyncOutlined } from "@ant-design/icons";
import { Checkbox, Col, message, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import SNFormItem from "../../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../../components/SN/SNForm/SNInput";
import SNTitle from "../../../../../components/SN/SNTitle";

interface AccountTemplateFormProps {
  form: FormInstance;
  editData?: any;
}

function AccountTemplateForm(props: AccountTemplateFormProps) {
  const [fetchingPassword, setFetchingPassword] = useState(false);

  useEffect(() => {
    _getPassword(false);
  }, []);

  const _getPassword = (showSuccess: boolean = true) => {
    setFetchingPassword(true);
    setTimeout(() => {
      setFetchingPassword(false);
      showSuccess &&
        message.open({
          type: "success",
          content: "Newly generated password",
          duration: 1,
        });
      // form.setFieldsValue({ password: generatePassword() });
    }, 1000);
  };

  const renderAccountInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info"]} title="Account information" />

        <SNFormItem
          label="Email"
          name="email"
          required
          rules={[{ type: "email", message: "Not a valid email" }]}
        >
          <SNInput type="email" placeholder="Enter email for this user" />
        </SNFormItem>
        <SNFormItem label="First name" name="first_name" required>
          <SNInput maxLength={50} placeholder="Enter first name" />
        </SNFormItem>
        <SNFormItem label="Last name" name="Last_name">
          <SNInput maxLength={50} placeholder="Enter last name" />
        </SNFormItem>

        <SNFormItem label="Password" name="password" required>
          <SNInput
            maxLength={20}
            minLength={8}
            addonAfter={
              <SyncOutlined
                spin={fetchingPassword}
                className="sn-btn-hoverable"
                onClick={() => _getPassword()}
              />
            }
            disabled
            type="password"
          />
        </SNFormItem>

        <SNFormItem name="is_active" valuePropName="checked">
          <Checkbox>Active this account after created ?</Checkbox>
        </SNFormItem>
        <SNFormItem name="send_login" valuePropName="checked">
          <Checkbox>Send login infomation to user ?</Checkbox>
        </SNFormItem>
      </section>
    );
  };

  return (
    <Row gutter={[40, 16]}>
      <Col xl={8} lg={12} md={12} xs={24}>
        {renderAccountInfo()}
      </Col>
    </Row>
  );
}

export default AccountTemplateForm;
