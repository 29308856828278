import { blue } from "@ant-design/colors";
import { Col, Divider, Drawer, Radio, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import Form from "antd/lib/form/Form";
import _ from "lodash";
import qs from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SNButton from "../../../../components/SN/SNButton";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../components/SN/SNForm/SNInput";
import SNSelect from "../../../../components/SN/SNForm/SNSelect";
import SNText from "../../../../components/SN/SNText";
import {
  cleanObject,
  formatBooleanParam,
  formatFormCustomSelectParam,
  formatNumberParam,
} from "../../../../lib/locationSearch";
import SNAllocatedNodesSelect from "../../../components/EntitiesSelect/SNAllocatedNodesSelect";
import SNCompaniesSelect from "../../../components/EntitiesSelect/SNCompaniesSelect";
import { nodeOrderStatus } from "../../data.t";

const NodeOrderFilter = () => {
  const location = useLocation();
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [form] = useForm();
  const searchParams: any = qs.parse(location.search);

  useEffect(() => {
    if (!location.search) {
      form.resetFields();
    } else {
      form.setFieldsValue({ ..._getFormInitValue });
    }
  }, [location.search]);

  const _getFormInitValue: any = useMemo(() => {
    return cleanObject({
      company: formatFormCustomSelectParam(searchParams?.company),
      is_allocated: formatBooleanParam(searchParams?.is_allocated),
      allocate: formatFormCustomSelectParam(searchParams?.allocate),
      status: formatNumberParam(searchParams?.status),
      node_id_greater: searchParams?.node_id_greater,
      node_id_less: searchParams?.node_id_less,
    });
  }, [location.search]);

  const _submitForm = useCallback((allFields) => {
    const {
      company,
      allocate,
      is_allocated,
      node_id_greater,
      node_id_less,
      status,
    } = allFields;

    const isAllocated = _.isBoolean(is_allocated) ? { is_allocated } : {};

    const searchData = Object.assign(
      cleanObject({
        company: company?.value || null,
        allocate: allocate?.value || null,
        status,
        is_allocated,
        node_id_greater,
        node_id_less,
      }),
      isAllocated
    );

    setIsVisible(false);
    history.push(`?${qs.stringify(searchData)}`);
  }, []);

  const _resetFilter = useCallback(() => {
    history.push(location.pathname);
    form.resetFields();
    setIsVisible(false);
  }, []);

  const _renderFormSection = () => {
    return (
      <section>
        <Form
          layout="vertical"
          onFinish={_submitForm}
          form={form}
          initialValues={_getFormInitValue}
          onValuesChange={() => {}}
        >
          <SNFormItem
            className="sn-filter-form-item-wrapper"
            label="Status"
            name="status"
          >
            <SNSelect
              options={Object.keys(nodeOrderStatus).map((i) => {
                return {
                  label: nodeOrderStatus[i],
                  value: parseInt(i),
                };
              })}
              allowClear
              placeholder="Select status of node order"
            />
          </SNFormItem>

          <SNCompaniesSelect
            formClassName="sn-filter-form-item-wrapper"
            label="Company"
            name="company"
            placeholdertext="Select company from the list"
          />

          <Row gutter={16}>
            <Col span={12}>
              <SNFormItem
                className="sn-filter-form-item-wrapper"
                name="node_id_greater"
                label="Node start"
              >
                <SNInput maxLength={8} />
              </SNFormItem>
            </Col>
            <Col span={12}>
              <SNFormItem
                className="sn-filter-form-item-wrapper"
                name="node_id_less"
                label="Node end"
              >
                <SNInput maxLength={8} />
              </SNFormItem>
            </Col>
          </Row>

          <SNFormItem
            className="sn-filter-form-item-wrapper"
            label="Is Allocated ?"
            name="is_allocated"
          >
            <Radio.Group
              onChange={() => form.setFieldsValue({ allocate: null })}
            >
              <Radio value={undefined}>
                <SNText text="All" />
              </Radio>
              <Radio value={true}>
                <SNText text="Yes" />
              </Radio>
              <Radio value={false}>
                <SNText text="No" />
              </Radio>
            </Radio.Group>
          </SNFormItem>

          <SNFormItem
            noStyle
            className="sn-filter-form-item-wrapper"
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.is_allocated !== currentValues.is_allocated
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("is_allocated") === true ? (
                <SNAllocatedNodesSelect
                  formClassName="sn-filter-form-item-wrapper"
                  name="allocate"
                />
              ) : null
            }
          </SNFormItem>
        </Form>
      </section>
    );
  };

  const _renderButtonSection = () => {
    return (
      <section>
        <Divider />
        <Row justify="space-between">
          <SNButton
            title="Reset"
            className="sn-btn-big"
            onClick={_resetFilter}
          />
          <SNButton
            title="Apply"
            type="primary"
            className="sn-btn-big"
            onClick={form.submit}
          />
        </Row>
      </section>
    );
  };

  return (
    <div>
      <Row>
        <SNButton
          title="Filters"
          icon={[location.search ? "fas" : "far", "filter"]}
          iconcolor={blue.primary}
          onClick={() => setIsVisible(true)}
        />
        {location.search && (
          <SNButton
            icon={["far", "eraser"]}
            iconcolor={blue.primary}
            onClick={_resetFilter}
          />
        )}
      </Row>
      <Drawer
        title={"Node order filter"}
        width={400}
        onClose={() => setIsVisible(false)}
        visible={isVisible}
      >
        {_renderFormSection()}
        {_renderButtonSection()}
      </Drawer>
    </div>
  );
};

export default NodeOrderFilter;
