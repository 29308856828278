import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { CSSProperties, FC, ReactNode } from "react";
import SNIcon from "../SNIcon";
import "./index.scss";
interface SNEmptyProps {
  title: string | ReactNode;
  icon: IconProp | IconName;
  style?: CSSProperties;
}
const SNEmpty: FC<SNEmptyProps> = (props) => {
  const { icon, title, style } = props;
  return (
    <div className="no-data-found" style={style}>
      <SNIcon icon={icon} className="no-data-found-icon" size="3x" />
      <p className="no-data-found-message">{title}</p>
    </div>
  );
};

export default SNEmpty;
