import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { Menu, Space } from "antd";
import { CSSProperties, isValidElement } from "react";
import { Link } from "react-router-dom";
import SNIcon from "../SNIcon";
import SNText from "../SNText";

export interface SNMenuItemProps {
  link?: any;
  title?: any;
  icon?: IconProp | IconName;
  iconcolor?: string;
  key?: number | string;
  id?: number | string;
  onClick?: () => any;
  disabled?: boolean;
  href?: any;
  target?: any;
  style?: CSSProperties;
  rel?: any;
}

function SNMenuItem(props: SNMenuItemProps) {
  const { link, title, icon, iconcolor, disabled, key, href, id, ...newProps } =
    props;

  const getIcon = (icon: IconProp | IconName, iconColor: string) => {
    if (isValidElement(icon)) {
      return icon;
    }

    return (
      <SNIcon
        icon={icon}
        style={{
          color: iconColor,
          width: 14,
          textAlign: "center",
        }}
      />
    );
  };

  if (href)
    return (
      <Menu.Item key={key || id} disabled={disabled} {...newProps}>
        <a href={href} target="_blank" rel="noopener noreferrer">
          <Space>
            {icon && getIcon(icon, iconcolor)}
            {title && <SNText text={title} disabled={disabled} />}
          </Space>
        </a>
      </Menu.Item>
    );

  if (link)
    return (
      <Menu.Item key={key || id} disabled={disabled} {...newProps}>
        <Link to={link}>
          {typeof title === "string" ? (
            <Space>
              {icon && getIcon(icon, iconcolor)}
              {title && <SNText text={title} disabled={disabled} />}
            </Space>
          ) : (
            title
          )}
        </Link>
      </Menu.Item>
    );

  return (
    <Menu.Item key={key || id} disabled={disabled} {...newProps}>
      {typeof title === "string" ? (
        <Space>
          {icon && getIcon(icon, iconcolor)}
          {title && <SNText text={title} disabled={disabled} />}
        </Space>
      ) : (
        title
      )}
    </Menu.Item>
  );
}

export default SNMenuItem;
