import { Input } from "antd";
import { InputProps } from "antd/lib/input";

export interface SNInputProps extends InputProps {}

function SNInput(props: SNInputProps) {
  return <Input {...props} />;
}

export default SNInput;
