import { Col, Radio, RadioChangeEvent, Row, Space, Tabs } from "antd";
import SNButtonGroup from "components/SN/SNButtonGroup";
import SNDescription from "components/SN/SNDescription";
import SNFormLoading from "components/SN/SNFormLoading";
import { SNMenuItemProps } from "components/SN/SNMenuItem";
import SNPanel from "components/SN/SNPanel";
import SNText from "components/SN/SNText";
import SNTitle from "components/SN/SNTitle";
import { DATE_TIME_FORMAT } from "constants/date";
import moment from "moment";

import { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useRouteMatch } from "react-router-dom";
import path from "screens/Root/routePaths";
import { getNewsFeedStatusTag, newsFeedTarget } from "../const";
import newsfeedService from "../service";

const NewsFeedDetails = () => {
  const match = useRouteMatch<any>(path.new_feed_details);
  const news_feed_id = match?.params?.id;
  const [language, setLanguage] = useState<string>("sv");

  const [newsFeedData, reload] =
    newsfeedService.useNewFeedDetails(news_feed_id);
  if (news_feed_id && !newsFeedData) return <SNFormLoading />;

  const {
    title,
    status,
    story,
    publish_at,
    target,
    news_by,
    created_at,
    sticky_days,
    story_sv,
  } = newsFeedData;
  const handleModeChange = (e: RadioChangeEvent) => {
    setLanguage(e.target.value);
  };
  const _renderRightOption = () => {
    const actions: SNMenuItemProps[] = [
      {
        key: "refresh",
        icon: ["fal", "redo"],
        title: "Refresh",
        onClick: () => reload(),
      },
      {
        key: "edit",
        icon: ["fal", "pencil"],
        title: "Edit",
        link: `${path.edit_new_feed.replace(":id", news_feed_id)}`,
      },
    ];
    return <SNButtonGroup dropdown={actions} />;
  };

  const _renderBasicInfo = () => {
    const basicColumns = [
      {
        label: "Target",
        value: newsFeedTarget[target]?.label,
      },
      {
        label: "Created by",
        value: news_by?.username,
      },
      {
        label: "Created at",
        value: moment(created_at).format(DATE_TIME_FORMAT),
      },
      {
        label: "Sticky notification",
        value: sticky_days,
      },
      {
        label: "Publish at",
        value: (
          <span>
            {moment(publish_at).format(DATE_TIME_FORMAT)} ({" "}
            {moment(publish_at).fromNow()} )
          </span>
        ),
      },
    ];

    return (
      <SNDescription
        icon={"info-circle"}
        title={"General Infomation"}
        bordered
        column={2}
        rowSize={["15%", "35%"]}
        size={"small"}
        itemData={basicColumns}
      />
    );
  };

  const _renderStory = () => {
    return (
      <section>
        <SNTitle icon="paperclip" title="Story content" />
        <Radio.Group
          onChange={handleModeChange}
          value={language}
          style={{ marginBottom: 8 }}
        >
          <Radio.Button value="sv">Swedish</Radio.Button>
          <Radio.Button value="en">English</Radio.Button>
        </Radio.Group>
        <Tabs
          className="story_tabs"
          defaultActiveKey="sv"
          activeKey={language}
          items={[
            {
              key: "sv",
              children: (
                <div style={{ fontFamily: "Roboto" }}>
                  {ReactHtmlParser(story_sv)}
                </div>
              ),
              label: "",
              forceRender: true,
            },
            {
              key: "en",
              children: (
                <div style={{ fontFamily: "Roboto" }}>
                  {ReactHtmlParser(story)}
                </div>
              ),
              label: "",
              forceRender: true,
            },
          ]}
        />
      </section>
    );
  };

  return (
    <SNPanel
      view={"details"}
      panelTitle={
        <Space style={{ display: "flex" }}>
          <SNText
            text={title}
            style={{ fontWeight: 500, fontSize: 17, color: "#42a5f5" }}
          />
          {getNewsFeedStatusTag(status)}
        </Space>
      }
      extra={_renderRightOption()}
      bodyStyle={{ paddingTop: 5 }}
    >
      <Row style={{ paddingTop: 10 }}>
        <Col span={24}>{_renderBasicInfo()}</Col>
        <Col span={24} style={{ paddingTop: 20 }}>
          {_renderStory()}
        </Col>
      </Row>
    </SNPanel>
  );
};

export default NewsFeedDetails;
