import { QuestionCircleOutlined } from "@ant-design/icons";
import { CSSProperties } from "react";
import SNTooltip from "../SNTooltip";

interface SNWikiIconProps {
  tips: any;
  icon?: any;
  color?: string;
  style?: CSSProperties;
  noTranslate?: boolean;
}
function SNWikiIcon(props: SNWikiIconProps) {
  const {
    tips,
    icon = (
      <QuestionCircleOutlined
        style={{
          color: props.color || "#42a5f5",
          ...props.style,
        }}
      />
    ),
  } = props;
  return <SNTooltip text={tips}>{icon}</SNTooltip>;
}

export default SNWikiIcon;
