import ProLayout from "@ant-design/pro-layout";
import { Route as RouteProps } from "@ant-design/pro-layout/lib/typings";
import { Button, Result } from "antd";
import { isValidElement, useState } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { ENV_NAME } from "settings/env";
import logo from "../../../assets/img/logo_color.png";
import RightContent from "../../../components/GlobalHeader/RightContent";
import SNIcon from "../../../components/SN/SNIcon";
import Accounts from "../../Accounts";
import Companies from "../../Companies";
import Dashboard from "../../Dashboard";
import Nodes from "../../Nodes";
import Subscription from "../../Subscriptions";
import path from "../routePaths";

const getIcon = (icon: any) => {
  if (isValidElement(icon)) {
    return icon;
  }

  return (
    <SNIcon
      icon={icon}
      style={{
        width: 14,
        textAlign: "center",
        marginRight: 18,
      }}
    />
  );
};

const noMatch = (
  <Result
    status={404}
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Button type="primary">
        <Link to={path.auth_log}>Back to home</Link>
      </Button>
    }
  />
);

const RootRoute = (props) => {
  const { history } = props;
  const {
    dashboard,
    accounts,
    all_accounts,
    tenants,
    all_tenants,
    nodes,
    subscriptions,
    all_subscriptions,
    all_sub_plans,
    all_invoices,
    allocate_node_list,
    all_production_nodes,
    auth_log,
    all_nodes,
    all_payments,
    new_feed,
  } = path;
  const [collapsed, setCollapsed] = useState(false);

  const route: RouteProps = {
    path: auth_log,
    routes: [
      {
        name: "Dashboard",
        path: dashboard,
        icon: getIcon("chart-line"),
        routes: [
          {
            name: "News Feed",
            path: new_feed,
          },
          {
            name: "Auth log",
            path: auth_log,
          },
        ],
      },
      {
        name: "Accounts",
        path: accounts,
        icon: getIcon("user"),
        routes: [
          {
            name: "All Accounts",
            path: all_accounts,
          },
        ],
      },
      {
        name: "Companies",
        path: tenants,
        icon: getIcon("building"),
        routes: [
          {
            name: "All Companies",
            path: all_tenants,
          },
        ],
      },
      {
        name: "Nodes",
        path: nodes,
        icon: getIcon("qrcode"),
        routes: [
          {
            name: "All Nodes",
            path: all_nodes,
          },
          {
            name: "Allocate",
            path: allocate_node_list,
          },
          {
            name: "Production",
            path: all_production_nodes,
          },
        ],
      },
      {
        name: "Subscription (Test)",
        path: subscriptions,
        icon: getIcon("dollar-sign"),
        routes: [
          {
            name: "All Subscriptions",
            path: all_subscriptions,
          },
          {
            name: "Plans",
            path: all_sub_plans,
          },
          {
            name: "Invoices",
            path: all_invoices,
          },
          {
            name: "Payments",
            path: all_payments,
          },
        ],
      },
    ],
  };

  return (
    <ProLayout
      logo={logo}
      title={"Administration"}
      onMenuHeaderClick={() => history.push(new_feed)}
      menuItemRender={(menuItemProps, defaultDom) => {
        if (menuItemProps.isUrl || !menuItemProps.path) {
          return defaultDom;
        }

        return <Link to={menuItemProps.path}>{defaultDom}</Link>;
      }}
      breadcrumbRender={(routers = []) => [...routers]}
      itemRender={(route, params, routes, paths) => {
        const first = routes.indexOf(route) === 0;
        return first ? (
          <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
        ) : (
          <span>{route.breadcrumbName}</span>
        );
      }}
      collapsed={collapsed}
      onCollapse={(collapsed) => setCollapsed(collapsed)}
      fixSiderbar
      fixedHeader
      rightContentRender={() => <RightContent {...props} />}
      route={route}
      contentStyle={{ margin: 12 }}
      pageTitleRender={(props, defaultTitle, info) => {
        return `${info.pageName} ${
          ENV_NAME !== "Production" ? `- ${ENV_NAME} ` : ""
        }| Admin`;
      }}
    >
      <Switch>
        <Route strict path={dashboard} component={Dashboard} />
        <Route strict path={accounts} component={Accounts} />
        <Route strict path={tenants} component={Companies} />
        <Route strict path={subscriptions} component={Subscription} />
        <Route strict path={nodes} component={Nodes} />
        <Route render={() => noMatch} />
      </Switch>
    </ProLayout>
  );
};

export default RootRoute;
