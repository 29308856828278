import { Col, Form, Row, Space } from "antd";

import SNButton from "../../../components/SN/SNButton";
import SNPanel from "../../../components/SN/SNPanel";
import { warningAlert } from "../../../lib/alertHelper";
import AccountTemplateForm from "./components/AccountTemplateForm";

const AccountForm = ({ history }) => {
  const [form] = Form.useForm();

  const initValues = {
    is_active: true,
    send_login: true,
  };

  const onFinish = (values) => {
    warningAlert("Api did not ready yet");
  };

  return (
    <SNPanel panelTitle="Register account" style={{ marginBottom: 15 }}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        className="sn-form"
      >
        <AccountTemplateForm form={form} />

        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={history.goBack}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default AccountForm;
