import { Badge } from "antd";
import { BadgeProps } from "antd/lib/badge";
import { FC } from "react";
import "./index.scss";

interface SNBadgeProps extends Omit<BadgeProps, "snBadgeProps"> {
  type?: "trans" | "tag";
}

const SNBadge: FC<SNBadgeProps> = (props) => {
  const { overflowCount } = props;
  return (
    <Badge {...props} overflowCount={overflowCount ? overflowCount : 999} />
  );
};

export default SNBadge;
