import { message } from "antd";
import { removeAuth, removeUser } from "../../redux/Reducers/authReducer";
import store from "../../redux/store";
import { getHistory } from "../../settings/history";

const removeAuthentication = () => {
  message.warning("Your authentication session has expired", 1);
  getHistory().push("/login");
  store.dispatch(removeAuth());
  store.dispatch(removeUser());
};

export default {
  removeAuthentication,
};
