import {
  Col,
  DatePicker,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Tabs,
} from "antd";
import { FormInstance } from "antd/es/form/Form";
import "braft-editor/dist/index.css";
import SNFormItem from "components/SN/SNForm/SNFormItem";
import SNInput from "components/SN/SNForm/SNInput";
import SNSelect from "components/SN/SNForm/SNSelect";
import SNTextEditor from "components/SN/SNTextEditor";
import SNTitle from "components/SN/SNTitle";
import { DATE_TIME_FORMAT, TIME_FORMAT } from "constants/date";
import { FC, useState } from "react";
import { newsFeedTarget } from "../../const";
import "./index.scss";
interface NewsFeedFormSectionProps {
  form: FormInstance;
}

const NewsFeedFormSection: FC<NewsFeedFormSectionProps> = (props) => {
  const { form } = props;
  const [editorState, setEditorState] = useState(form.getFieldValue("story"));
  const [language, setLanguage] = useState<string>("sv");

  const _renderBasicInfo = () => {
    return (
      <section>
        <SNTitle icon="info-circle" title="General Infomation" />

        <Row gutter={[20, 16]}>
          <Col span={8}>
            <SNFormItem name="title" label="Title" required>
              <SNInput placeholder="Enter title" />
            </SNFormItem>
          </Col>

          <Col span={8}>
            <SNFormItem name="publish_at" label="Publish at" required>
              <DatePicker
                showTime={{ format: TIME_FORMAT }}
                format={DATE_TIME_FORMAT}
              />
            </SNFormItem>
          </Col>

          <Col span={4}>
            <SNFormItem name="target" label="Target">
              <SNSelect
                options={newsFeedTarget}
                placeholder="Select target"
                allowClear={false}
              />
            </SNFormItem>
          </Col>
          <Col span={4}>
            <SNFormItem name="sticky_days" label="Sticky notification">
              <InputNumber addonAfter="days" />
            </SNFormItem>
          </Col>
        </Row>
      </section>
    );
  };
  const handleModeChange = (e: RadioChangeEvent) => {
    setLanguage(e.target.value);
  };
  const _renderStory = () => {
    return (
      <section>
        <SNTitle icon="paperclip" title="Story content" />
        <Radio.Group
          onChange={handleModeChange}
          value={language}
          style={{ marginBottom: 8 }}
        >
          <Radio.Button value="sv">Swedish</Radio.Button>
          <Radio.Button value="en">English</Radio.Button>
        </Radio.Group>
        <Tabs
          className="story_tabs"
          defaultActiveKey="sv"
          activeKey={language}
          items={[
            {
              key: "sv",
              children: (
                <div>
                  <SNFormItem name="story_sv">
                    <SNTextEditor
                      value={editorState}
                      placeholder="Type something"
                      onChange={setEditorState}
                    />
                  </SNFormItem>
                </div>
              ),
              label: "",
              forceRender: true,
            },
            {
              key: "en",
              children: (
                <div>
                  <SNFormItem name="story">
                    <SNTextEditor
                      value={editorState}
                      placeholder="Type something"
                      onChange={setEditorState}
                    />
                  </SNFormItem>
                </div>
              ),
              label: "",
              forceRender: true,
            },
          ]}
        />
      </section>
    );
  };

  return (
    <Row>
      <Col span={24}>{_renderBasicInfo()}</Col>
      <Col span={24}>{_renderStory()}</Col>
    </Row>
  );
};

export default NewsFeedFormSection;
