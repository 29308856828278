import { blue } from "@ant-design/colors";
import ProTable, {
  ActionType,
  enUSIntl,
  IntlProvider,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import qs from "query-string";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import api, { url } from "../../../api";
import SNButton from "../../../components/SN/SNButton";
import SNPanel from "../../../components/SN/SNPanel";
import { paramsToObject } from "../../../lib/locationSearch";
import NodeProductionFilter from "../components/NodeProductionFilter";
import { NodeProductionApi } from "../data.t";
import NodeProductionForm from "../NodeProductionForm";

const AllProductionNodes = () => {
  const location = useLocation();
  const listRef = useRef<ActionType>();
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);

  const _renderList = () => {
    const columns: ProColumns<NodeProductionApi>[] = [
      { title: "ID", dataIndex: "id", key: "id", sorter: true },
      {
        title: "Production date",
        dataIndex: "production_date",
        key: "production_date",
        sorter: true,
      },
      { title: "Initial ", dataIndex: "initial", key: "initial", sorter: true },
      { title: "Number ", dataIndex: "number", key: "number" },
      { title: "Series ", dataIndex: "series", key: "series", sorter: true },
      { title: "Total ", dataIndex: "total", key: "total" },
      // {
      //   key: "actions",
      //   width: 50,
      //   fixed: "right",
      //   search: false,
      //   render: (value: any) => {
      //     const actions: SNMenuItemProps[] = [
      //       {
      //         key: "edit",
      //         icon: ["fal", "pencil-alt"],
      //         title: "Edit",
      //         link: path.edit_production_nodes.replace("prod_id", value.id)
      //       },
      //     ];
      //     return <SNButtonGroup dropdown={actions} />;
      //   },
      // },
    ];

    return (
      <IntlProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
        <ProTable<NodeProductionApi, {}>
          actionRef={listRef}
          request={async (
            { pageSize, current, keyword },
            sort,
            filter
          ): Promise<RequestData<NodeProductionApi>> => {
            const sortKey = Object.keys(sort).length
              ? Object.keys(sort)[0]
              : "";
            const sortParams = sortKey.length
              ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
              : "";
            const locationParams = qs.stringify(
              paramsToObject(location.search)
            );

            const urlRequest = `${
              url.NODE_PRODUCTION
            }?limit=${pageSize}&offset=${
              (current - 1) * pageSize
            }${sortParams}${locationParams ? `&${locationParams}` : ""}`;

            const res = await api.get(urlRequest);

            return {
              data: res.data.results,
              success: true,
              total: res.data.count,
            };
          }}
          headerTitle={"Production Nodes"}
          search={false}
          params={location.search}
          options={{
            setting: false,
            fullScreen: false,
            density: false,
          }}
          size={"small"}
          toolBarRender={() => [
            <SNButton
              key="plus-button"
              title="Create production node"
              iconcolor={blue.primary}
              icon="plus"
              onClick={() => setShowCreateForm(true)}
            />,
            <NodeProductionFilter key="filter" />,
          ]}
          pagination={{ pageSize: 100, showQuickJumper: true }}
          rowKey={(key) => key.id}
          columns={columns}
          dateFormatter="string"
        />
      </IntlProvider>
    );
  };

  return (
    <div>
      {showCreateForm && (
        <NodeProductionForm
          onCancelForm={() => {
            setShowCreateForm(false);
          }}
          onFinishForm={() => {
            setShowCreateForm(false);
            listRef.current?.reload();
          }}
        />
      )}
      <SNPanel view="list">{_renderList()}</SNPanel>
    </div>
  );
};

export default AllProductionNodes;
