import { Button, Radio, Slider } from "antd";
import "cropperjs/dist/cropper.css";
import { useState } from "react";
import Cropper from "react-cropper";
import SNModal from "../../SNModal";
import "./index.scss";

interface SNImageCropModalProps {
  image: any;
  isVisible: boolean;
  onClose: () => void;
  postActions?: (value: any) => void;
}

function SNImageCropModal(props: SNImageCropModalProps) {
  const { isVisible, onClose, image, postActions } = props;
  const [zoom, setZoom] = useState<number>(0);
  const [aspect, setAspect] = useState<any>(0);
  const [cropper, setCropper] = useState<any>();

  const _onModalClose = () => {
    setZoom(0);
    setAspect(0);
    onClose();
  };
  const onOk = () => {
    if (aspect === "full") {
      postActions(image);
    }
    let cropped_data = cropper
      .getCroppedCanvas({
        width: 400,
        fillColor: "#fff",
      })
      .toDataURL("image/jpeg", 0.85);

    postActions(cropped_data);
    _onModalClose();
  };
  return (
    <SNModal
      isVisible={isVisible}
      onCancel={_onModalClose}
      onOk={onOk}
      destroyOnClose
    >
      <br />
      <Cropper
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        preview=".sn-cropper-pre-view"
        aspectRatio={aspect}
        dragMode="move"
        viewMode={1}
        minContainerHeight={300}
        minCropBoxWidth={100}
        checkCrossOrigin={false}
        checkOrientation={false}
        autoCropArea={1}
        src={image}
        zoomTo={zoom}
        scalable
      />
      <Radio.Group
        defaultValue={0}
        value={aspect}
        onChange={(e) => setAspect(e.target.value)}
        optionType="button"
        style={{ marginTop: 4 }}
      >
        <Radio.Button value={0}>100%</Radio.Button>
        <Radio.Button value={1}>1:1</Radio.Button>
        <Radio.Button value={16 / 9}>16:9</Radio.Button>
      </Radio.Group>
      <div className="sn-img-crop-control">
        <Button
          onClick={() => {
            setZoom(zoom - 1);
          }}
          disabled={zoom <= 0}
        >
          －
        </Button>
        <Slider
          min={0}
          max={10}
          onChange={(val) => {
            setZoom(val);
          }}
          value={zoom}
        />
        <Button
          onClick={() => {
            setZoom(zoom + 1);
          }}
          disabled={zoom >= 10}
        >
          ＋
        </Button>
      </div>
    </SNModal>
  );
}

export default SNImageCropModal;
