import { blue } from "@ant-design/colors";
import { Divider, Drawer, Radio, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import Form from "antd/lib/form/Form";
import _ from "lodash";
import qs from "query-string";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SNButton from "../../../../components/SN/SNButton";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import SNText from "../../../../components/SN/SNText";
import {
  cleanObject,
  formatBooleanParam,
} from "../../../../lib/locationSearch";

const AccountFilter = () => {
  const formRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [form] = useForm();
  const searchParams: any = qs.parse(location.search);

  useEffect(() => {
    if (!location.search) {
      form.resetFields();
    } else {
      form.setFieldsValue({ ..._getFormInitValue });
    }
  }, [location.search]);

  const _getFormInitValue: any = useMemo(() => {
    return cleanObject({
      is_active: formatBooleanParam(searchParams?.is_active),
      has_company: formatBooleanParam(searchParams?.has_company),
    });
  }, [location.search]);

  const _submitForm = useCallback((allFields) => {
    const { is_active, has_company } = allFields;
    const isActived = _.isBoolean(is_active) ? { is_active } : {};
    const hasCompany = _.isBoolean(has_company) ? { has_company } : {};

    const searchData = Object.assign({}, hasCompany, isActived);

    setIsVisible(false);
    history.push(`?${qs.stringify(searchData)}`);
  }, []);

  const _resetFilter = useCallback(() => {
    history.push(location.pathname);
    form.resetFields();
    setIsVisible(false);
  }, []);

  const _renderFormSection = () => {
    return (
      <section>
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={_submitForm}
          form={form}
          initialValues={_getFormInitValue}
        >
          <SNFormItem
            className="sn-filter-form-item-wrapper"
            label="Is actived ?"
            name="is_active"
          >
            <Radio.Group>
              <Radio value={undefined}>
                <SNText text="All" />
              </Radio>
              <Radio value={true}>
                <SNText text="Yes" />
              </Radio>
              <Radio value={false}>
                <SNText text="No" />
              </Radio>
            </Radio.Group>
          </SNFormItem>
          <SNFormItem
            className="sn-filter-form-item-wrapper"
            label="Has company ?"
            name="has_company"
          >
            <Radio.Group>
              <Radio value={undefined}>
                <SNText text="All" />
              </Radio>
              <Radio value={true}>
                <SNText text="Yes" />
              </Radio>
              <Radio value={false}>
                <SNText text="No" />
              </Radio>
            </Radio.Group>
          </SNFormItem>
        </Form>
      </section>
    );
  };

  const _renderButtonSection = () => {
    return (
      <section>
        <Divider />
        <Row justify="space-between">
          <SNButton
            title="Reset"
            className="sn-btn-big"
            onClick={_resetFilter}
          />
          <SNButton
            title="Apply"
            type="primary"
            className="sn-btn-big"
            onClick={form.submit}
          />
        </Row>
      </section>
    );
  };

  return (
    <div>
      <Row>
        <SNButton
          title="Filters"
          icon={[location.search ? "fas" : "far", "filter"]}
          iconcolor={blue.primary}
          onClick={() => setIsVisible(true)}
        />
        {location.search && (
          <SNButton
            icon={["far", "eraser"]}
            iconcolor={blue.primary}
            onClick={_resetFilter}
          />
        )}
      </Row>
      <Drawer
        title={"Account filter"}
        width={400}
        onClose={() => setIsVisible(false)}
        visible={isVisible}
      >
        {_renderFormSection()}
        {_renderButtonSection()}
      </Drawer>
    </div>
  );
};

export default AccountFilter;
