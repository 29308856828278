import Axios from "axios";
import { useEffect, useState } from "react";
import api, { url } from "../../api";
import { CompanyDataApi, CompanyStatsApi } from "./data.t";

const useCompanyDetails = (
  id: any
): [CompanyDataApi, CompanyStatsApi, Function] => {
  const [companyData, setCompanyData] = useState<CompanyDataApi>(null);
  const [companyStats, setCompanyStats] = useState<CompanyStatsApi>(null);
  const [willReload, setWillReload] = useState<boolean>(false);

  let source = Axios.CancelToken.source();
  const reload = () => {
    setCompanyData(null);
    setCompanyStats(null);
    setWillReload(!willReload);
  };

  const fetchDetails = () => {
    Promise.all([
      api.get(`${url.COMPANY}${id}/`),
      api.get(`${url.COMPANY}${id}/usage/`),
    ]).then((res) => {
      setCompanyData(res[0].data);
      setCompanyStats(res[1].data);
    });
  };

  useEffect(() => {
    if (id) {
      try {
        fetchDetails();
      } catch {
        setCompanyData(null);
      }
    } else {
      setCompanyData(null);
    }
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [id, willReload]);

  return [companyData, companyStats, reload];
};

function addCompany(params: any = {}, postActions?: (data: any) => void) {
  api.post(url.COMPANY, params).then((response) => {
    if (response.status === 201) {
      if (postActions) postActions(response.data);
    }
  });
}

function editCompany(id: any, params: any = {}, postActions?: (data: any) => void) {
  api.put(`${url.COMPANY}${id}/`, params).then((response) => {
    if (response.status === 201) {
      if (postActions) postActions(response.data);
    }
  });
}

const companyService = {
  useCompanyDetails,
  addCompany,
  editCompany
};

export default companyService;
