import { Modal, Skeleton } from "antd";
import { ModalProps } from "antd/lib/modal";

import SNButton from "../SNButton";
import "./index.scss";

interface SNModalProps extends Omit<ModalProps, "snModal"> {
  isHideFooter?: boolean;
  isLoading?: boolean;
  isVisible?: boolean;
  children?: React.ReactNode;
  leftFooter?: React.ReactNode;
}

function SNModal(props: SNModalProps) {
  const {
    okText,
    onOk,
    cancelText,
    onCancel,
    maskClosable = false,
    isVisible,
    title,
    isLoading,
    isHideFooter,
    footer,
    className,
    leftFooter,
    ...newProps
  } = props;

  return (
    <Modal
      {...newProps}
      className={`${className || ""} sn-modal ${
        (!title && "sn-modal-no-title") || ""
      }`}
      onOk={onOk || null}
      onCancel={onCancel || null}
      okText={okText || "Save"}
      cancelText={cancelText || "Cancel"}
      title={title}
      visible={isVisible}
      maskClosable={maskClosable}
      okButtonProps={{ className: "sn-btn-big" }}
      cancelButtonProps={{ className: "sn-btn-big" }}
      footer={
        isHideFooter
          ? null
          : footer
          ? footer
          : [
              leftFooter ? (
                <div className="sn-modal-left-footer" key="leftFooter">
                  {leftFooter}
                </div>
              ) : (
                ""
              ),
              <SNButton
                className="sn-btn-big"
                onClick={onCancel}
                title={cancelText || "Cancel"}
                key="okBtn"
              />,
              <SNButton
                className="sn-btn-big"
                type="primary"
                onClick={onOk}
                title={okText || "Save"}
                key="cancelBtn"
              />,
            ]
      }
      centered
    >
      <Skeleton active paragraph={{ rows: 4 }} loading={isLoading}>
        {props.children}
      </Skeleton>
    </Modal>
  );
}

export default SNModal;
