import { List, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { useRef } from "react";
import { url } from "../../../../api";
import SNApiSelect, {
  SNApiSelectRef,
} from "../../../../components/SN/SNForm/SNApiSelect";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import { InvoiceApi } from "../../../Subscriptions/Invoices/data.t";

const { Option } = Select;
interface SNInvoicesSelectProps extends SelectProps<any> {
  placeholdertext?: string;
  required?: boolean;
  name: string;
  label?: any;
  formClassName?: string;
  fields?: string;
}

function SNInvoicesSelect(props: SNInvoicesSelectProps) {
  const {
    placeholdertext = "Select invoice from the list",
    fields,
    required,
    name,
    label,
    formClassName,
    onSelect,
    ...newProps
  } = props;
  const apiSelectRef = useRef<SNApiSelectRef>();

  const optionFormat = (item: InvoiceApi) => {
    const { id, total, number } = item;
    const title = `${number}`;
    const description = `${total} (SEK)`;

    return (
      <Option value={id} key={id} label={title} data={item}>
        <List.Item.Meta
          className="option-item-has-image"
          title={title}
          description={description}
        />
      </Option>
    );
  };

  return (
    <>
      <SNFormItem
        name={name}
        label={label}
        required={required}
        className={formClassName}
      >
        <SNApiSelect
          {...newProps}
          placeholdertext={placeholdertext}
          urlObject={`${url.SUBSCRIPTION_INVOICE}`}
          optionFormat={optionFormat}
          limit={100}
          handleChange={onSelect}
          ref={apiSelectRef}
        />
      </SNFormItem>
    </>
  );
}

export default SNInvoicesSelect;
