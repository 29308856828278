import axios from "axios";
import { AUTH_KEY } from "settings/env";
import { serverErrorAlert } from "../lib/alertHelper";
import { endProcessing, startProcessing } from "../redux/Reducers/apiReducer";
import store from "../redux/store";
import rootService from "../screens/Root/service";
import endpoints, { TOKEN } from "./endpoints";

//To perform GET API operations
function get(url, showServerMessage = true, cancelToken = undefined) {
  return axios
    .get(url, { headers: apiRequestHeader(), cancelToken: cancelToken })
    .then((data) => {
      return data;
    })
    .catch((data: { response }) => {
      const tokenHasExpired = data.response && data.response.status === 401;

      if (tokenHasExpired) {
        rootService.removeAuthentication();
      } else {
        return handleServerErrors(data, showServerMessage, undefined);
      }
    });
}

function del(url) {
  store.dispatch(startProcessing());
  return axios
    .request({
      method: "delete",
      headers: apiRequestHeader(),
      url,
    })
    .then((response) => {
      store.dispatch(endProcessing());
      return response;
    })
    .catch((data: { response }) => {
      store.dispatch(endProcessing());
      const tokenHasExpired = data.response && data.response.status === 401;
      if (tokenHasExpired) {
        rootService.removeAuthentication();
      } else {
        return handleServerErrors(data, true, undefined);
      }
    });
}

//To perform PATCH API operations
function patch(url, data) {
  store.dispatch(startProcessing());
  return axios
    .request({
      method: "patch",
      headers: apiRequestHeader(),
      url,
      data,
    })
    .then((data) => {
      store.dispatch(endProcessing());
      return data;
    })
    .catch((data: { response }) => {
      store.dispatch(endProcessing());
      const tokenHasExpired = data.response && data.response.status === 401;

      if (tokenHasExpired) {
        rootService.removeAuthentication();
      } else {
        return handleServerErrors(data, true, undefined);
      }
    });
}

//To perform PATCH API operations
function put(url, data) {
  store.dispatch(startProcessing());
  return axios
    .request({
      method: "put",
      headers: apiRequestHeader(),
      url,
      data,
    })
    .then((data) => {
      store.dispatch(endProcessing());
      return data;
    })
    .catch((data: { response }) => {
      store.dispatch(endProcessing());
      const tokenHasExpired = data.response && data.response.status === 401;

      if (tokenHasExpired) {
        rootService.removeAuthentication();
      } else {
        return handleServerErrors(data, true, undefined);
      }
    });
}

//To perform POST API operations`
function post(url, data) {
  store.dispatch(startProcessing());
  return axios
    .request({
      method: "post",
      headers: apiRequestHeader(),
      url,
      data,
    })
    .then((data) => {
      store.dispatch(endProcessing());
      return data;
    })
    .catch((data: { response }) => {
      store.dispatch(endProcessing());
      const tokenHasExpired = data.response && data.response.status === 401;

      if (tokenHasExpired) {
        rootService.removeAuthentication();
      } else {
        return handleServerErrors(data, true, undefined);
      }
    });
}

function apiRequestHeader(
  { access_token, token_type } = store.getState().persistReducers.auth.authInfo
) {
  return {
    Authorization: `${token_type} ${access_token}`,
    "Content-Type": "application/json",
  };
}

function requestForRefreshToken() {
  const TOKEN_CONFIG = {
    headers: {
      Authorization: AUTH_KEY,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  return axios.post(TOKEN, getRefreshTokenParams(), TOKEN_CONFIG);
}

function getRefreshTokenParams() {
  const auth = store.getState().persistReducers.auth.authInfo;
  const { refresh_token } = auth;
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refresh_token);
  return params;
}

function handleServerErrors(error, showServerMessage = true, fixedMessage) {
  if (error.response)
    switch (error.response.status) {
      case 400:
        if (showServerMessage) {
          let errorItem = error.response.data;
          serverErrorAlert(generateBadRequest(errorItem), fixedMessage);
          return error;
        }

        return error;

      case 500:
        if (showServerMessage)
          serverErrorAlert(
            "Something went wrong! please try again in 30 seconds!"
          );
        return error.response;

      default:
        if (showServerMessage)
          serverErrorAlert(
            "Your request could not be processed. Please try again."
          );
        return error;
    }
  return null;
}

/*400 or Bad Request Message Generator from API Error Response*/
function generateBadRequest(errorItems) {
  let totalErrors = [];
  if (typeof errorItems === "string") {
    totalErrors.push(errorItems);
  } else {
    const error_object_keys = Object.keys(errorItems);

    error_object_keys.forEach(function (key) {
      const objError = errorItems[key];
      // IF mess is array
      if (Array.isArray(objError)) {
        objError.map((error) =>
          totalErrors.push({ title: key, message: error.message })
        );
      } else {
        // Mess is object
        Object.keys(objError).forEach((obj) => {
          if (typeof objError[obj] === "object")
            totalErrors.push({ message: objError[obj].message });

          if (Array.isArray(objError[obj]))
            objError[obj].map((objE) =>
              totalErrors.push({ title: key, message: objE.message })
            );
        });

        if (objError.message && typeof objError.message === "string")
          totalErrors.push({ message: objError.message });
      }
    });
  }

  return totalErrors;
}

export const url = endpoints;
export default {
  get,
  post,
  patch,
  del,
  put,
  apiRequestHeader,
  requestForRefreshToken,
  getRefreshTokenParams,
};
