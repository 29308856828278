import { Tag } from "antd";
import { invoiceStatus, InvoiceStatusType } from "./data.t";

export const getInvoiceStatusTag = (status: InvoiceStatusType) => {
  switch (status) {
    case 1:
      return <Tag color={"geekblue"}>{invoiceStatus[1]}</Tag>;
    case 2:
      return <Tag color={"orange"}>{invoiceStatus[2]}</Tag>;
    case 3:
      return <Tag color={"success"}>{invoiceStatus[3]}</Tag>;
    case 4:
      return <Tag color={"error"}>{invoiceStatus[4]}</Tag>;
    default:
      return null;
  }
};
