import { Col, DatePicker, FormInstance, Row } from "antd";
import moment from "moment";
import { FC } from "react";
import SNAddress from "../../../../../components/SN/SNForm/SNAddress";
import SNFormItem from "../../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../../components/SN/SNForm/SNInput";
import SNSelect from "../../../../../components/SN/SNForm/SNSelect";
import SNTextarea from "../../../../../components/SN/SNForm/SNTextarea";
import SNText from "../../../../../components/SN/SNText";
import SNTitle from "../../../../../components/SN/SNTitle";
import SNSubscriptionsSelect from "../../../../components/EntitiesSelect/SNSubscriptionsSelect";
import { InvoiceApi } from "../../data.t";
import InvoiceItemSectionForm from "./InvoiceItemSectionForm";

interface PlanTemplateFormProps {
  form: FormInstance;
  editData?: InvoiceApi;
  isLock?: boolean;
}

const InvoiceTemplateForm: FC<PlanTemplateFormProps> = (props) => {
  const { form } = props;

  const renderGeneralInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info-square"]} title="General information" />

        <SNSubscriptionsSelect
          label="Subscription"
          name="subscription"
          placeholdertext="Select subscription"
          required
        />

        <SNFormItem
          label="Invoice number"
          name="number"
          required
          tooltip="Specific ID for the Invoice"
        >
          <SNInput
            maxLength={20}
            placeholder={"example: INVOICE-137, INV999,..."}
          />
        </SNFormItem>

        <SNFormItem label="Invoice date" name="invoice_date" required>
          <DatePicker
            onChange={(value) => {
              const invoiceTerms = form.getFieldValue("invoice_terms");

              if (invoiceTerms) {
                form.setFieldsValue({
                  due_date: moment(value).add(invoiceTerms, "days"),
                });
              }
            }}
          />
        </SNFormItem>

        <SNFormItem label="Invoice terms" name="invoice_terms">
          <SNSelect
            options={[
              { label: "NET 15", value: 15 },
              { label: "NET 30", value: 30 },
            ]}
            onChange={(value) => {
              const invoiceDate = form.getFieldValue("invoice_date");

              if (invoiceDate) {
                form.setFieldsValue({
                  due_date: moment(invoiceDate).add(value, "days"),
                });
              }
            }}
          />
        </SNFormItem>

        <SNFormItem label="Due date" name="due_date" required>
          <DatePicker />
        </SNFormItem>
      </section>
    );
  };

  const renderInvoiceInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "file-invoice"]} title="Invoice information" />

        <SNFormItem label="Invoice Ref" name="invoice_ref">
          <SNInput maxLength={50} />
        </SNFormItem>

        <SNFormItem label="Invoice email" name="invoice_email">
          <SNInput maxLength={50} />
        </SNFormItem>

        <SNTitle icon={["fal", "map-marked-alt"]} title="Invoice address" />
        <SNAddress name="contact_address" form={form} />
      </section>
    );
  };

  const renderOtherInfo = () => {
    return (
      <section>
        <SNTitle icon={["fal", "info"]} title="Other information" />

        <SNFormItem label="OCR number" name="ocr_number">
          <SNInput maxLength={50} />
        </SNFormItem>

        <SNFormItem name="note" label="Note (Display on invoice)">
          <SNTextarea
            placeholder="Note to the customer for this subscription"
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        </SNFormItem>
      </section>
    );
  };

  const renderlistItem = () => {
    return (
      <section>
        <SNTitle icon={["fal", "shopping-basket"]} title="Invoice Item" />

        <InvoiceItemSectionForm form={form} name="invoice_items" />

        <Row gutter={[12, 16]} justify="end" style={{ marginTop: 30 }}>
          <Col xl={6} lg={12} md={12} xs={12}>
            <SNFormItem
              label={<SNText text="TOTAL VAT AMOUNT" strong />}
              name="total_vat"
            >
              <SNInput style={{ width: "100%" }} disabled addonAfter={"SEK"} />
            </SNFormItem>

            <SNFormItem
              label={<SNText text="TOTAL PRICE" strong />}
              name="total_price"
            >
              <SNInput style={{ width: "100%" }} disabled addonAfter={"SEK"} />
            </SNFormItem>

            <SNText
              type="secondary"
              text={"* Total price inc. VAT"}
              style={{ fontStyle: "italic" }}
            ></SNText>
          </Col>
        </Row>
      </section>
    );
  };

  return (
    <div>
      <Row gutter={[40, 16]}>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderGeneralInfo()}
        </Col>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderInvoiceInfo()}
        </Col>
        <Col xl={8} lg={16} md={16} xs={24}>
          {renderOtherInfo()}
        </Col>
      </Row>
      <Row gutter={[40, 16]} style={{ marginBottom: 30 }}>
        <Col xl={24} lg={24} md={24} xs={24}>
          {renderlistItem()}
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceTemplateForm;
