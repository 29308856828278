import Axios from "axios";
import { useEffect, useState } from "react";
import api, { url } from "../../api";
import { SubscriptionApi } from "./data.t";

const useSubscriptionDetails = (id: any): [SubscriptionApi, Function] => {
  const [subData, setSubData] = useState<SubscriptionApi>(null);
  const [willReload, setWillReload] = useState<boolean>(false);

  let source = Axios.CancelToken.source();
  const reload = () => {
    setSubData(null);
    setWillReload(!willReload);
  };

  const fetchDetails = () => {
    api.get(`${url.SUBSCRIPTION}${id}/`).then((res) => {
      setSubData(res.data);
    });
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    } else {
      setSubData(null);
    }
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [id, willReload]);

  return [subData, reload];
};

function addSubscription(params: any = {}, postActions?: (data: any) => void) {
  api.post(url.SUBSCRIPTION, params).then((response) => {
    if (response.status === 201) {
      if (postActions) postActions(response.data);
    }
  });
}

function editSubscription(
  id: any,
  params: any = {},
  postActions?: (data: any) => void
) {
  api.put(`${url.SUBSCRIPTION}${id}/`, params).then((response) => {
    if (response.status === 200) {
      if (postActions) postActions(response.data);
    }
  });
}

const subService = {
  useSubscriptionDetails,
  addSubscription,
  editSubscription,
};

export default subService;
