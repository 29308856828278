import { Col, Input, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { FC, useState } from "react";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../components/SN/SNForm/SNInput";
import SNInputNumber from "../../../../components/SN/SNForm/SNInputNumber";
import SNSelect from "../../../../components/SN/SNForm/SNSelect";
import { NODE_INITIALS, NODE_SERIES } from "../../../../constants";
import { generateEndNodeId } from "../../../../lib";

interface NodeGeneratorProps {
  form: FormInstance;
}

const NodeGenerator: FC<NodeGeneratorProps> = (props) => {
  const { form } = props;

  const [startId, setStartId] = useState<string>("");
  const [endId, setEndId] = useState<string>("");

  const _generateNodeId = (inputValue, field) => {
    const { getFieldValue } = form;

    let initial = getFieldValue("initial");
    let series = getFieldValue("series");
    let total = getFieldValue("total");
    let number = getFieldValue("number");

    switch (field) {
      case "initial":
        initial = inputValue;
        break;
      case "series":
        series = inputValue;
        break;
      case "total":
        total = inputValue;
        break;
      case "number":
        number = inputValue;
        break;
      default:
        break;
    }

    if (number && number.length > 3 && total > 0 && initial && series) {
      const start_node_id = `${initial}${number}${series}`;
      const end_node_id = generateEndNodeId(initial, number, total, series);

      setStartId(start_node_id);
      setEndId(end_node_id);
    } else {
      setStartId("");
      setEndId("");
    }
  };

  return (
    <>
      <Row gutter={20}>
        <Col span={8}>
          <SNFormItem name="initial" label="Initial" required>
            <SNSelect
              options={NODE_INITIALS}
              onChange={(e) => _generateNodeId(e, "initial")}
            />
          </SNFormItem>
        </Col>
        <Col span={8}>
          <SNFormItem
            label="Number"
            name="number"
            rules={[
              {
                required: true,
                message: "Number is exactly 4 digits",
                pattern: /^\d{4}$/g,
              },
            ]}
            required
          >
            <SNInput
              maxLength={4}
              onChange={(e) => _generateNodeId(e.target.value, "number")}
            />
          </SNFormItem>
        </Col>
        <Col span={8}>
          <SNFormItem name="series" label="Series" required>
            <SNSelect
              options={NODE_SERIES}
              onChange={(e) => _generateNodeId(e, "series")}
            />
          </SNFormItem>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={8}>
          <SNFormItem
            name="total"
            label="Total node"
            required
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    RegExp(/^(0|([1-9]\d*))$/).test(getFieldValue("total"))
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("This field must be a natural number")
                  );
                },
              }),
            ]}
          >
            <SNInputNumber
              max={1000}
              min={1}
              onChange={(e) => _generateNodeId(e, "total")}
              style={{ width: "100%" }}
            />
          </SNFormItem>
        </Col>
        <Col span={8}>
          <SNFormItem label={"Start Node ID"}>
            <Input value={startId} disabled />
          </SNFormItem>
        </Col>
        <Col span={8}>
          <SNFormItem label={"End Node ID"}>
            <Input value={endId} disabled />
          </SNFormItem>
        </Col>
      </Row>
    </>
  );
};

export default NodeGenerator;
