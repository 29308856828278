import { Col, Input, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { FC } from "react";
import SNFormItem from "../SNFormItem";
import SNAddressAutoComplete from "./SNAddressAutoComplete";

interface SNAddressProps {
  name?: string;
  form?: FormInstance;
  panTo?: any;
  compact?: boolean;
}

export function formatMapSearchResult(
  address: any,
  pos: { lat: any; lng: any } = { lat: 1, lng: 1 }
) {
  let formatted_address = {
    address: "",
    city: "",
    country: "",
    post: "",
    lat: pos.lat,
    lng: pos.lng,
  };

  if (address.hasOwnProperty("address_components")) {
    let places = address["address_components"];

    places.map((place: any) => {
      switch (place.types[0]) {
        case "street_number":
          formatted_address.address = place.long_name;
          break;

        case "route":
          formatted_address.address =
            place.long_name + " " + formatted_address.address;
          break;

        case "locality":
          formatted_address.city = place.long_name;
          break;

        case "postal_town":
          formatted_address.city = place.long_name;
          break;

        case "country":
          formatted_address.country = place.long_name;
          break;

        case "postal_code":
          formatted_address.post = place.long_name;
          break;

        default:
          break;
      }

      return null;
    });
  } else {
    return formatted_address;
  }

  return formatted_address;
}
const SNAddress: FC<SNAddressProps> = (props) => {
  const { name = "address", form, panTo } = props;
  const onAddressSelect = (val: any, pos: { lng: any; lat: any }) => {
    const { address, city, country, post, lat, lng } = formatMapSearchResult(
      val,
      pos
    );
    if (panTo) panTo({ lat: lat, lng: lng });
    form.setFieldsValue({
      [name]: { address, city, country, post, lat, lng },
    });
  };
  const onReset = () => {
    form.setFieldsValue({
      [name]: null,
    });
  };
  return (
    <>
      <SNAddressAutoComplete
        name={[name, "address"]}
        label="Street"
        onSelect={onAddressSelect}
        onReset={onReset}
      />
      <SNFormItem name={[name, "address2"]} label="Address 2">
        <Input placeholder={"Ex. C/O or attention"} />
      </SNFormItem>
      <SNFormItem name={[name, "post"]} label="Post">
        <Input />
      </SNFormItem>
      <Row gutter={12}>
        <Col span={12}>
          <SNFormItem name={[name, "city"]} label="City">
            <Input />
          </SNFormItem>
        </Col>
        <Col span={12}>
          <SNFormItem name={[name, "country"]} label="Country">
            <Input />
          </SNFormItem>

          <SNFormItem name={[name, "lat"]} noStyle>
            <Input type="hidden" />
          </SNFormItem>
          <SNFormItem name={[name, "lng"]} noStyle>
            <Input type="hidden" />
          </SNFormItem>
        </Col>
      </Row>
    </>
  );
};

export default SNAddress;
