import SNFormItem from "../SNFormItem";
import SNInput from "../SNInput";

interface SNWebsiteInputProps {
  label?: any;
  name?: string | string[];
  placeholder?: string;
}

function SNWebsiteInput(props: SNWebsiteInputProps) {
  const { label, name = "website", placeholder } = props;

  var expression =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  var urlRegex = new RegExp(expression);
  return (
    <SNFormItem
      label={label}
      name={name}
      rules={[
        {
          pattern: urlRegex,
          message: "Inavlid website",
        },
      ]}
    >
      <SNInput placeholder={placeholder} />
    </SNFormItem>
  );
}

export default SNWebsiteInput;
