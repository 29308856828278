import { Tag } from "antd";

import { NewsFeedStatusType } from "./data.t";

export const newsFeedStatus = [
  { label: "Published", value: 1 },
  { label: "Obsolete", value: 2 },
  { label: "Archived", value: 3 },
];

export const newsFeedTarget = [
  { label: "Everyone", value: 0 },
  { label: "System Owner", value: 1 },
  { label: "Manager", value: 2 },
  { label: "Service Person", value: 3 },
];
export const stickyDays = [
  { label: "1 day", value: 1 },
  { label: "7 days", value: 7 },
  { label: "30 days", value: 30 },
];
export const getNewsFeedStatusTag = (status: NewsFeedStatusType) => {
  switch (status) {
    case 1:
      return <Tag color={"success"}>{newsFeedStatus[0].label}</Tag>;
    case 2:
      return <Tag color={"blue"}>{newsFeedStatus[1].label}</Tag>;
    case 3:
      return <Tag color={"error"}>{newsFeedStatus[2].label}</Tag>;
    default:
      return null;
  }
};
