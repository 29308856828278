import { Form } from "antd";
import { FormItemProps, RuleObject } from "antd/lib/form";
import { FC } from "react";
import SNButton, { SNButtonProps } from "../../SNButton";
import "./index.scss";

export interface SNFormItemProps extends FormItemProps {
  type?: "checkbox" | "input" | "select" | "textarea";
  label?: any;
  required?: boolean;
  rules?: RuleObject[] | any;
  labelRight?: any;
  rightButton?: SNButtonProps;
}

const SNFormItem: FC<SNFormItemProps> = (props) => {
  const {
    label,
    required,
    rules,
    type,
    className = "sn-form-item",
    labelRight,
    rightButton,
    ...newProps
  } = props;

  let rulesItem = rules || [];
  if (!!rules?.find((item) => item.type === "number")) {
    rulesItem = [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || (value && !isNaN(value))) {
            return Promise.resolve();
          }

          return Promise.reject("Only numbers are allowed");
        },
      }),
    ];
  }
  const itemLabel = () => {
    if (label && rightButton)
      return (
        <div className="sn-form-item-label-has-right">
          {label}{" "}
          <div className="pull-right">
            <SNButton
              type="link"
              icon="plus-circle"
              title="Add new"
              {...rightButton}
            />
          </div>
        </div>
      );
    if (labelRight)
      return (
        <div className="sn-form-item-label-has-right">
          {label} <div className="pull-right">{labelRight}</div>
        </div>
      );

    return label;
  };
  return (
    <Form.Item
      label={itemLabel()}
      rules={[
        { required: required, message: "This field is required" },
        ...rulesItem,
      ]}
      className={`${
        type === "checkbox" && "sn-checkbox-input-item"
      } ${className}`}
      {...newProps}
    >
      {props.children}
    </Form.Item>
  );
};

export default SNFormItem;
