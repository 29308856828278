import {
  blue,
  gold,
  green,
  magenta,
  orange,
  purple,
  volcano,
} from "@ant-design/colors";
import { Tag } from "antd";
import {
  planBillingPeriod,
  PlanBillingType,
  planEntity,
  planStatus,
  PlanStatusType,
} from "./data.t";

export const getPlanStatusTag = (status: PlanStatusType) => {
  switch (status) {
    case 1:
      return <Tag color={"success"}>{planStatus[1]}</Tag>;
    case 2:
      return <Tag color={"error"}>{planStatus[2]}</Tag>;
    default:
      return null;
  }
};

export const getPlanBillingTag = (status: PlanBillingType) => {
  switch (status) {
    case 0:
      return <Tag color={"purple"}>{planBillingPeriod[0]}</Tag>;
    case 1:
      return <Tag color={"volcano"}>{planBillingPeriod[1]}</Tag>;
    case 2:
      return <Tag color={"warning"}>{planBillingPeriod[2]}</Tag>;
    case 3:
      return <Tag color={"gold"}>{planBillingPeriod[3]}</Tag>;
    default:
      return null;
  }
};

export const getPlanEntityObj = (entity: any) => {
  switch (entity) {
    case "asset":
      return {
        label: planEntity["asset"],
        color: blue.primary,
      };
    case "invoice":
      return {
        label: planEntity["invoice"],
        color: orange.primary,
      };
    case "workorder":
      return {
        label: planEntity["workorder"],
        color: green.primary,
      };
    case "snode":
      return {
        label: planEntity["snode"],
        color: purple.primary,
      };
    case "form":
      return {
        label: planEntity["form"],
        color: volcano.primary,
      };
    case "user":
      return {
        label: planEntity["user"],
        color: gold.primary,
      };
    case "place":
      return {
        label: planEntity["place"],
        color: magenta.primary,
      };
    default:
      return {
        label: "",
        color: "",
      };
  }
};
