import { Route, Switch } from "react-router-dom";
import SNContentWrap from "../../components/SN/SNLayout/SNContentWrap";
import path from "../Root/routePaths";
import AllSubscriptions from "./AllSubscriptions";
import AllInvoices from "./Invoices/AllInvoices";
import InvoiceForm from "./Invoices/InvoiceForm";
import AllPayments from "./Payments/AllPayments";
import PaymentForm from "./Payments/PaymentForm";
import AllPlans from "./Plans/AllPlans";
import PlanDetails from "./Plans/PlanDetails";
import PlanForm from "./Plans/PlanForm";
import SubcriptionDetails from "./SubcriptionDetails";
import SubcriptionForm from "./SubcriptionForm";

const {
  all_subscriptions,
  subscription_details,
  create_subscription,
  edit_subscription,
  all_sub_plans,
  create_sub_plan,
  edit_sub_plan,
  sub_plan_details,
  all_invoices,
  create_invoice,
  edit_invoice,
  all_payments,
  edit_payment,
  register_payment,
} = path;

const Subscription = () => {
  return (
    <SNContentWrap title="Subscription">
      <Switch>
        <Route exact path={all_sub_plans} component={AllPlans} />
        <Route
          exact
          path={[create_sub_plan, edit_sub_plan]}
          component={PlanForm}
        />
        <Route exact path={sub_plan_details} component={PlanDetails} />
        <Route exact path={all_subscriptions} component={AllSubscriptions} />
        <Route
          exact
          path={subscription_details}
          component={SubcriptionDetails}
        />
        <Route
          exact
          path={[create_subscription, edit_subscription]}
          component={SubcriptionForm}
        />
        <Route exact path={all_invoices} component={AllInvoices} />
        <Route
          exact
          path={[create_invoice, edit_invoice]}
          component={InvoiceForm}
        />
        <Route exact path={all_payments} component={AllPayments} />
        <Route
          exact
          path={[edit_payment, register_payment]}
          component={PaymentForm}
        />
        {/* <Route
          exact
          path={[register_payment, edit_payment]}
          component={InvoiceForm}
        /> */}
        {/* <Route exact path={invoice_details} component={SubcriptionDetails} /> */}
        {/* <Route exact path={payment_details} component={SubcriptionDetails} /> */}
      </Switch>
    </SNContentWrap>
  );
};

export default Subscription;
