import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";

interface SNTextareaProps extends Omit<TextAreaProps, "snTextArea"> {}
function SNTextarea(props: SNTextareaProps) {
  const {
    placeholder = "Add a short description (Optional)",
    autoSize = { minRows: 3, maxRows: 3 },
    rows = 3,
    ...rest
  } = props;

  return (
    <Input.TextArea
      {...rest}
      placeholder={placeholder}
      autoSize={autoSize}
      rows={rows}
    />
  );
}

export default SNTextarea;
