import { Moment } from "moment";
import { AddressData } from "../components/SN/SNForm/SNAddress/data.t";
export const removeFalsy = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] || obj[prop] === "0" || obj[prop] === 0) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};
const addressData = (address: AddressData) => {
  if (!address) return null;
  if (!removeFalsy(address) || Object.keys(removeFalsy(address)).length === 0) {
    return null;
  }
  return address;
};
const dateData = (date: Moment, format: string) => {
  if (!date) return null;
  return date.format(format);
};
const optionData = (selectedOption: any) => {
  return selectedOption.value || selectedOption || null;
};
const optionDataFormat = (
  optionData: any,
  valueField: string,
  labelField?: string
) => {
  if (!optionData || !optionData[valueField]) return undefined;
  return {
    value: optionData[valueField],
    label: labelField ? optionData[labelField] : optionData[valueField],
  };
};

const snForm = { addressData, dateData, optionData, optionDataFormat };
export default snForm;
