import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import SNProcessLoader from "../../components/SN/SNProcessLoader";
import { is_authenticated } from "../../settings/auth_actions";
import LoginForm from "../Auth/Login";
import RootRoute from "./RootRoute";

const Root = () => {
  const user = useSelector((state: any) => state.persistReducers.auth.user);
  const auth = useSelector((state: any) => state.persistReducers.auth.authInfo);
  const { isProcessing } = useSelector(
    (state: any) => state.persistReducers.api
  );

  return (
    <>
      <Switch>
        <Route exact path="/" component={LoginForm} />
        <Route exact path="/login" component={LoginForm} />
        <Route
          render={(props) =>
            is_authenticated(user, auth) ? (
              <RootRoute {...props} />
            ) : (
              <Redirect
                exact
                to={{
                  pathname: "/login",
                }}
              />
            )
          }
        />
        <Route render={() => "Not found"} />
      </Switch>
      <SNProcessLoader loading={isProcessing} />
    </>
  );
};

export default Root;
