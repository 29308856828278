import { List, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { useRef } from "react";
import { url } from "../../../../api";
import images from "../../../../assets/img";
import SNAvatar from "../../../../components/SN/SNAvatar";
import SNApiSelect, {
  SNApiSelectRef,
} from "../../../../components/SN/SNForm/SNApiSelect";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import "./index.scss";

const { Option } = Select;
interface SNCompaniesSelectProps extends SelectProps<any> {
  placeholdertext?: string;
  required?: boolean;
  name: string;
  label?: any;
  formClassName?: string;
  fields?: string;
}
function SNCompaniesSelect(props: SNCompaniesSelectProps) {
  const {
    placeholdertext,
    fields,
    required,
    name,
    label,
    formClassName,
    onSelect,
    placeholder = "Select company from the list",
    ...newProps
  } = props;
  const apiSelectRef = useRef<SNApiSelectRef>();

  const optionFormat = (item: any) => {
    const { id, company_id, company_name, logo } = item;

    return (
      <Option value={id} key={id} label={company_name} data={item}>
        <List.Item.Meta
          className="option-item-has-image"
          avatar={
            <SNAvatar
              src={logo ?? images.PLACEHOLDER}
              name={company_name}
              alt=""
              shape="square"
            />
          }
          title={company_name}
          description={company_id}
        />
      </Option>
    );
  };

  return (
    <>
      <SNFormItem
        name={name}
        label={label}
        required={required}
        className={formClassName}
      >
        <SNApiSelect
          {...newProps}
          placeholdertext={placeholdertext}
          urlObject={`${url.COMPANY}?is_active=true`}
          optionFormat={optionFormat}
          limit={100}
          handleChange={onSelect}
          ref={apiSelectRef}
        />
      </SNFormItem>
    </>
  );
}

export default SNCompaniesSelect;
