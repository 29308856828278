import BraftEditor, { BraftEditorProps } from "braft-editor";
import "braft-editor/dist/index.css";
import { FC, memo } from "react";
import "./index.scss";

interface SNTextEditorProps extends BraftEditorProps {}

const SNTextEditor: FC<SNTextEditorProps> = (props) => {
  return (
    <BraftEditor
      language="en"
      className="sn-editor-wrapper"
      contentClassName="editor-content"
      {...props}
    />
  );
};

export default memo(SNTextEditor);
