import { blue } from "@ant-design/colors";
import { Col, DatePicker, Divider, Drawer, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import Form from "antd/lib/form/Form";
import qs from "query-string";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SNButton from "../../../../components/SN/SNButton";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import SNSelect from "../../../../components/SN/SNForm/SNSelect";
import { NODE_INITIALS, NODE_SERIES } from "../../../../constants";
import { cleanObject, formatDateParam } from "../../../../lib/locationSearch";

const NodeProductionFilter = () => {
  const formRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [form] = useForm();
  const searchParams: any = qs.parse(location.search);

  useEffect(() => {
    if (!location.search) {
      form.resetFields();
    } else {
      form.setFieldsValue({ ..._getFormInitValue });
    }
  }, [location.search]);

  const _getFormInitValue: any = useMemo(() => {
    return cleanObject({
      initial: searchParams?.initial,
      series: searchParams?.series,
      production_date: formatDateParam(searchParams?.production_date),
    });
  }, [location.search]);

  const _submitForm = useCallback((allFields) => {
    const { production_date, initial, series } = allFields;
    const searchData = cleanObject({
      production_date: production_date?.format("YYYY-MM-DD") || null,
      initial,
      series,
    });

    setIsVisible(false);
    history.push(`?${qs.stringify(searchData)}`);
  }, []);

  const _resetFilter = useCallback(() => {
    history.push(location.pathname);
    form.resetFields();
    setIsVisible(false);
  }, []);

  const _renderFormSection = () => {
    return (
      <section>
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={_submitForm}
          form={form}
          initialValues={_getFormInitValue}
        >
          <Row gutter={16}>
            <Col span={12}>
              <SNFormItem
                className="sn-filter-form-item-wrapper"
                name="initial"
                label="Initial"
              >
                <SNSelect
                  options={NODE_INITIALS}
                  style={{ width: "100%" }}
                  allowClear
                />
              </SNFormItem>
            </Col>
            <Col span={12}>
              <SNFormItem
                className="sn-filter-form-item-wrapper"
                name="series"
                label="Series"
              >
                <SNSelect
                  options={NODE_SERIES}
                  style={{ width: "100%" }}
                  allowClear
                />
              </SNFormItem>
            </Col>
          </Row>

          <SNFormItem
            className="sn-filter-form-item-wrapper"
            label="Production date"
            name="production_date"
          >
            <DatePicker />
          </SNFormItem>
        </Form>
      </section>
    );
  };

  const _renderButtonSection = () => {
    return (
      <section>
        <Divider />
        <Row justify="space-between">
          <SNButton
            title="Reset"
            className="sn-btn-big"
            onClick={_resetFilter}
          />
          <SNButton
            title="Apply"
            type="primary"
            className="sn-btn-big"
            onClick={form.submit}
          />
        </Row>
      </section>
    );
  };

  return (
    <div>
      <Row>
        <SNButton
          title="Filters"
          icon={[location.search ? "fas" : "far", "filter"]}
          iconcolor={blue.primary}
          onClick={() => setIsVisible(true)}
        />
        {location.search && (
          <SNButton
            icon={["far", "eraser"]}
            iconcolor={blue.primary}
            onClick={_resetFilter}
          />
        )}
      </Row>
      <Drawer
        title={"Node production filter"}
        width={400}
        onClose={() => setIsVisible(false)}
        visible={isVisible}
      >
        {_renderFormSection()}
        {_renderButtonSection()}
      </Drawer>
    </div>
  );
};

export default NodeProductionFilter;
