import SNLoader from "../SNLoader";
import "./index.scss";
interface SNProcessLoaderProps {
  loading: boolean;
}

function SNProcessLoader(props: SNProcessLoaderProps) {
  const { loading } = props;
  return (
    <div
      className="sn-process-loader-container"
      style={{ display: loading ? "flex" : "none" }}
    >
      <SNLoader className="sn-process-spin" loaderStyle="dot" tip={"Loading"} />
    </div>
  );
}

export default SNProcessLoader;
