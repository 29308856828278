import { blue } from "@ant-design/colors";
import ProTable, {
  ActionType,
  enUSIntl,
  IntlProvider,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { useRef } from "react";
import api, { url } from "../../../../api";
import SNButton from "../../../../components/SN/SNButton";
import SNButtonGroup from "../../../../components/SN/SNButtonGroup";
import { SNMenuItemProps } from "../../../../components/SN/SNMenuItem";
import SNPanel from "../../../../components/SN/SNPanel";
import path from "../../../Root/routePaths";
import { SubscriptionApi } from "../../data.t";
import { getInvoiceStatusTag } from "../const";

const AllInvoices = ({ history }) => {
  const listRef = useRef<ActionType>();

  const _renderList = () => {
    const columns: ProColumns<SubscriptionApi>[] = [
      {
        dataIndex: "number",
        key: "number",
        title: "Number",
        sorter: true,
      },
      {
        dataIndex: "company",
        key: "company",
        title: "Company",
        render: (value: any) => value.company_name,
      },
      {
        dataIndex: "date",
        key: "date",
        title: "Invoice date",
        sorter: true,
        valueType: "date",
      },
      {
        dataIndex: "due_date",
        key: "due_date",
        title: "Due date",
        sorter: true,
        valueType: "date",
      },
      {
        dataIndex: "total",
        key: "total",
        title: "Invoice amount",
        render: (value) => `${value ?? 0} (SEK)`,
      },
      {
        dataIndex: "amount_paid",
        key: "amount_paid",
        title: "Amount paid",
        render: (value: any) => `${value ?? 0} (SEK)`,
      },
      {
        dataIndex: "status",
        key: "status",
        title: "Status",
        render: (value: any) => getInvoiceStatusTag(value),
      },
      {
        key: "actions",
        width: 50,
        fixed: "right",
        search: false,
        render: (value: any, data: any) => {
          const actions: SNMenuItemProps[] = [
            {
              key: "info",
              icon: ["fal", "info"],
              title: "Details",
              link: path.invoice_details.replace(":invoice_id", data.id),
            },
            {
              key: "edit",
              icon: ["fal", "pencil-alt"],
              title: "Edit",
              link: path.edit_invoice.replace(":invoice_id", data.id),
            },
          ];
          return <SNButtonGroup dropdown={actions} />;
        },
      },
    ];

    return (
      <IntlProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
        <ProTable<SubscriptionApi, { search: string }>
          actionRef={listRef}
          request={async (
            { pageSize, current, keyword },
            sort,
            filter
          ): Promise<RequestData<SubscriptionApi>> => {
            const sortKey = Object.keys(sort).length
              ? Object.keys(sort)[0]
              : "";
            const sortParams = sortKey.length
              ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
              : "";

            const urlRequest = `${
              url.SUBSCRIPTION_INVOICE
            }?limit=${pageSize}&offset=${
              (current - 1) * pageSize
            }${sortParams}`;
            const res = await api.get(urlRequest);

            return {
              data: res.data.results,
              success: true,
              total: res.data.count,
            };
          }}
          headerTitle="All invoices"
          search={false}
          toolBarRender={() => [
            <SNButton
              key="plus-button"
              title="Create invoice"
              icon="plus"
              iconcolor={blue.primary}
              link={path.create_invoice}
            />,
            <SNButton
              key="filter-button"
              title="Filters"
              icon={["far", "filter"]}
              iconcolor={blue.primary}
            />,
          ]}
          options={{
            setting: false,
            fullScreen: false,
            density: false,
          }}
          size={"small"}
          pagination={{ pageSize: 100, showQuickJumper: true }}
          rowKey={(key) => key.id}
          columns={columns}
          dateFormatter="string"
        />
      </IntlProvider>
    );
  };

  return <SNPanel view="list">{_renderList()}</SNPanel>;
};

export default AllInvoices;
