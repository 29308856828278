import { Avatar } from "antd";

import UserAvatar, { CompanyUserData } from "./UserAvatar";
import "./index.scss";

interface SNListUsersProps {
  users: CompanyUserData[];
  maxCount?: number;
}
function SNListUsers(props: SNListUsersProps) {
  const { users, maxCount = 2 } = props;

  return (
    <Avatar.Group
      maxCount={maxCount}
      maxStyle={{ color: "#fff", backgroundColor: "#42a5f5" }}
      maxPopoverPlacement="top"
      className="sn-user-avatar-list"
      key="group"
    >
      {users?.map((member: CompanyUserData, i: number) => (
        <UserAvatar
          companyUser={member}
          key={`user-a-${i}`}
          alt={`user-a-${i}`}
        />
      ))}
    </Avatar.Group>
  );
}

export default SNListUsers;
