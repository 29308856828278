import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { CSSProperties } from "react";

const { Text } = Typography;
interface SNTextProps extends Omit<TextProps, "snTextProp"> {
  text: any;
  style?: CSSProperties;
}

const SNText = (props: SNTextProps) => {
  const { text, style, ...newProps } = props;
  if (!text) return null;
  return (
    <Text
      style={{
        ...{ fontFamily: "roboto" },
        ...style,
      }}
      {...newProps}
    >
      {!text.length ? "" : text}
    </Text>
  );
};

export default SNText;
