import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { AUTH_KEY } from "settings/env";
import api, { url } from "../../../api";
import SNFormItem from "../../../components/SN/SNForm/SNFormItem";
import { LOGO } from "../../../constants";
import { setAuth, setUser } from "../../../redux/Reducers/authReducer";
import { is_authenticated } from "../../../settings/auth_actions";
import routePaths from "../../Root/routePaths";
import "./loginStyles.css";

const LoginForm = (props) => {
  const {
    location: { state },
  } = props;
  const [form] = useForm();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.persistReducers.auth.user);
  const auth = useSelector((state: any) => state.persistReducers.auth.authInfo);

  let redirect_path = routePaths.new_feed;
  if (state && state.hasOwnProperty("from")) {
    redirect_path = state.from.pathname;
  }
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (is_authenticated(user, auth) && !loading) {
      setRedirect(true);
    }
  }, [[]]);

  const _handleLogin = (allFields) => {
    setLoading(true);

    const data = new URLSearchParams();
    const { username, password } = allFields;

    data.append("username", username);
    data.append("password", password);
    data.append("grant_type", "password");

    axios
      .request({
        method: "post",
        headers: {
          Authorization: AUTH_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: url.TOKEN,
        data,
      })
      .then((response) => {
        dispatch(setAuth(response.data));
        const { access_token, token_type } = response.data;
        axios
          .get(url.PROFILE, {
            headers: api.apiRequestHeader({ access_token, token_type }),
          })
          .then((response) => {
            const { data } = response;
            if (data.hasOwnProperty("is_superuser")) {
              dispatch(setUser(data));
              message.success("Welcome admin!", 1);
              setLoading(false);
              setRedirect(true);
            } else {
              message.error(
                "Sorry! You are not authorized to access the system!",
                2
              );
              localStorage.clear();
              setLoading(false);
            }
          });
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // form.setFieldsValue({
          //   username: {
          //     value: form.getFieldValue("username"),
          //     errors: [new Error("")],
          //   },
          //   password: {
          //     value: form.getFieldValue("password"),
          //     errors: [new Error("Username or Password is not correct")],
          //   },
          // });
        }

        setLoading(false);
      });
  };

  /**
   * Render view region
   */

  if (redirect) return <Redirect to={redirect_path} />;

  const _renderUsernameForm = () => {
    return (
      <SNFormItem name="username" required>
        <Input
          type="text"
          prefix={<UserOutlined style={{ color: "grey" }} />}
          placeholder="Username"
          size="large"
        />
      </SNFormItem>
    );
  };

  const _renderPasswordForm = () => {
    return (
      <SNFormItem name="password" required>
        <Input
          type="password"
          prefix={<LockOutlined style={{ color: "grey" }} />}
          placeholder="Password"
          size="large"
        />
      </SNFormItem>
    );
  };

  return (
    <div className="login-wrapper">
      <div className="login">
        <img src={LOGO} alt="service node logo" />
        <Form onFinish={_handleLogin} form={form}>
          {_renderUsernameForm()}
          {_renderPasswordForm()}
          <Button
            onClick={form.submit}
            type="primary"
            size="large"
            block
            loading={loading}
          >
            Log in
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
