import {
  ProDescriptionsItemProps,
  ProDescriptionsProps,
} from "@ant-design/pro-descriptions";
import { ReactNode } from "react";
import { SNTitleProps } from "./../SNTitle/data.t";

export interface SNDescriptionItemProps extends ProDescriptionsItemProps {
  label: string;
  value: any;
  labelWidth?: any;
  contentWidth?: any;
}

/** TODO: Divided by total columns
 * @example: 1 column => 100%, 2 columns => 50%
 */
export const MapRowSize = {
  oneColumn: ["30%", "70%"],
  twoColumns: ["15%", "35%"],
  threeColumns: ["10%", "23%"],
};
type RowSize = keyof typeof MapRowSize;

export interface SNDescriptionProps
  extends ProDescriptionsProps,
    Pick<SNTitleProps, "icon"> {
  title: string | ReactNode;
  itemData: SNDescriptionItemProps[];
  rowSize?: typeof MapRowSize[RowSize];
}
