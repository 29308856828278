import { Col, Divider, Empty, Row, Space } from "antd";
import SN404Page from "../../../../components/SN/SN404Page";
import SNButtonGroup from "../../../../components/SN/SNButtonGroup";
import SNDescription from "../../../../components/SN/SNDescription";
import { SNDescriptionItemProps } from "../../../../components/SN/SNDescription/data.t";
import SNDetailsLoading from "../../../../components/SN/SNDetailsLoading";
import { SNMenuItemProps } from "../../../../components/SN/SNMenuItem";
import SNPanel from "../../../../components/SN/SNPanel";
import SNText from "../../../../components/SN/SNText";
import SNTitle from "../../../../components/SN/SNTitle";
import path from "../../../Root/routePaths";
import { getPlanStatusTag } from "../const";
import { planBillingPeriod } from "../data.t";
import planService from "../service";

const PlanDetails = (props) => {
  const { match } = props;
  const { plan_id } = match.params;

  /**
   * Render view region
   */

  if (!plan_id) return <SN404Page />;

  const [planData, refreshData] = planService.usePlanDetails(plan_id);

  if (!planData) return <SNDetailsLoading />;

  const {
    name,
    status,
    billing_period,
    code,
    price,
    plan_settings,
    description,
    is_public,
  } = planData;

  const renderBasicInfo = () => {
    const basicColumns: SNDescriptionItemProps[] = [
      {
        label: "Plan code",
        value: code,
      },
      {
        label: "Billing period",
        value: planBillingPeriod[billing_period],
      },
      {
        label: "Price",
        value: price ? `${price} (SEK)` : "",
      },
      // {
      //   label: "Setup fee",
      //   value: setup_fee ? `${setup_fee} (SEK)` : "",
      // },
      // {
      //   label: "Free trial day",
      //   value: free_trial_day
      //     ? `${free_trial_day} ${free_trial_day > 1 ? "days" : "day"}`
      //     : "No trial",
      // },
      {
        label: "Is public ?",
        value: is_public ? "Yes" : "No",
      },
      {
        label: "Plan terms",
        value: description,
      },
    ];

    return (
      <Col xl={24} lg={24} md={24} xs={24} style={{ marginTop: 20 }}>
        <SNDescription
          icon={["fal", "info"]}
          title={"Plan Infomation"}
          bordered
          column={2}
          rowSize={["15%", "25%"]}
          size={"small"}
          itemData={basicColumns}
        />
      </Col>
    );
  };

  const renderSetting = () => {
    return (
      <section style={{ marginTop: 20 }}>
        <SNTitle title="Setting" icon="cogs" className="sn-section-title top" />

        {!plan_settings.length && (
          <Empty description={"No setting for this plan"} />
        )}
        {/* {plan_settings.length ? (
          <List
            itemLayout="vertical"
            style={{ marginTop: 30 }}
            grid={{ gutter: 20, column: 2 }}
            dataSource={plan_settings}
            renderItem={(item) => {
              const { entity, entity_limit, entity_price, type } = item;
              const entityObj = getPlanEntityObj(entity);

              return (
                <List.Item>
                  <Badge.Ribbon text={entityObj.label} color={entityObj.color}>
                    <Card
                      hoverable
                      bordered
                      style={{ borderColor: entityObj.color, borderWidth: 2 }}
                    >
                      <SNDescription
                        title={null}
                        bordered
                        column={2}
                        rowSize={["15%", "25%"]}
                        size={"small"}
                        itemData={[
                          {
                            label: "Type",
                            value: type,
                          },
                          {
                            label: "Limit",
                            value: entity_limit,
                          },
                          {
                            label: "Price per entity",
                            value: entity_price ? `${entity_price} (SEK)` : "",
                          },
                          {
                            label: "Price",
                            value: item.price ? `${item.price} (SEK)` : "",
                          },
                        ]}
                      />
                    </Card>
                  </Badge.Ribbon>
                </List.Item>
              );
            }}
          />
        ) : null} */}
        {plan_settings.length ? (
          <section>
            <Divider />
            <Row justify="end">
              <SNText
                style={{ fontWeight: "bold", fontSize: 24 }}
                text={`Total: ${plan_settings
                  .map((x) => x.price ?? "0")
                  .reduce((a, b) =>
                    (parseFloat(a) + parseFloat(b)).toFixed(2)
                  )} (SEK)`}
              />
            </Row>
          </section>
        ) : null}
      </section>
    );
  };

  const renderRightOption = () => {
    const actions: SNMenuItemProps[] = [
      {
        key: "refresh",
        icon: ["fal", "redo"],
        title: "Refresh",
        onClick: () => refreshData(),
      },
      {
        key: "edit",
        icon: ["fal", "pencil-alt"],
        title: "Edit",
        link: path.edit_sub_plan.replace(":plan_id", plan_id),
      },
    ];
    return <SNButtonGroup dropdown={actions} />;
  };

  return (
    <SNPanel
      view={"details"}
      panelTitle={
        <Space style={{ display: "flex" }}>
          <SNText
            text={name}
            style={{ fontWeight: 500, fontSize: 17, color: "#42a5f5" }}
          />
          {getPlanStatusTag(status)}
        </Space>
      }
      extra={renderRightOption()}
      bodyStyle={{ paddingTop: 5 }}
    >
      {renderBasicInfo()}
      {renderSetting()}
    </SNPanel>
  );
};

export default PlanDetails;
