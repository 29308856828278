import { Tag } from "antd";
import { subStatus, SubStatusType } from "./data.t";

export const getSubStatusTag = (status: SubStatusType) => {
  switch (status) {
    case 1:
      return <Tag color={"blue"}>{subStatus[1]}</Tag>;
    case 2:
      return <Tag color={"success"}>{subStatus[2]}</Tag>;
    case 3:
      return <Tag color={"error"}>{subStatus[3]}</Tag>;
    case 4:
      return <Tag color={"orange"}>{subStatus[4]}</Tag>;
    default:
      return null;
  }
};
