import { List, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { useRef } from "react";
import { url } from "../../../../api";
import SNAvatar from "../../../../components/SN/SNAvatar";
import SNApiSelect, {
  SNApiSelectRef,
} from "../../../../components/SN/SNForm/SNApiSelect";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import "./index.scss";

const { Option } = Select;
interface SNUsersSelectProps extends SelectProps<any> {
  placeholdertext?: string;
  required?: boolean;
  name: string;
  label?: any;
  formClassName?: string;
  fields?: string;
  showUsername?: boolean;
}
function SNUsersSelect(props: SNUsersSelectProps) {
  const {
    placeholdertext = "Select employees from the list",
    fields,
    required,
    name,
    label,
    formClassName,
    showUsername = false,
    onSelect,
    ...newProps
  } = props;
  const apiSelectRef = useRef<SNApiSelectRef>();

  const optionFormat = (item: any) => {
    const { id, designation, first_name, last_name, username, image } = item;
    const name = `${first_name} ${last_name} `;

    return (
      <Option
        value={id}
        key={id}
        searchtext={`${name}${designation}${username}`}
        label={!showUsername ? name : username}
        data={item}
      >
        <List.Item.Meta
          className="option-item-has-image"
          avatar={<SNAvatar src={image} name={name} alt="" shape="square" />}
          title={name}
          description={designation ? `${designation}, ${username}` : username}
        />
      </Option>
    );
  };

  return (
    <>
      <SNFormItem
        name={name}
        label={label}
        required={required}
        className={formClassName}
      >
        <SNApiSelect
          {...newProps}
          placeholdertext={placeholdertext}
          urlObject={`${url.USER}?is_active=true`}
          optionFormat={optionFormat}
          limit={100}
          handleChange={onSelect}
          ref={apiSelectRef}
        />
      </SNFormItem>
    </>
  );
}

export default SNUsersSelect;
