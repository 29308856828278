import SNText from "../../SNText";
import "./index.scss";

function SNContentWrap(props) {
  const { title } = props;
  return (
    <div className="sn-content">
      <div className="sn-content-header">
        <SNText
          text={title.toUpperCase()}
          style={{ fontSize: 18, fontWeight: 700 }}
        />
      </div>

      <div className="sn-content-body">{props.children}</div>
    </div>
  );
}

export default SNContentWrap;
