import { Col, Form, Row, Space } from "antd";
import moment from "moment";

import SNButton from "../../../components/SN/SNButton";
import SNPanel from "../../../components/SN/SNPanel";
import { successAlert } from "../../../lib/alertHelper";
import path from "../../Root/routePaths";
import nodeService from "../service";
import NodeAllocateTemplateForm from "./components/NodeAllocateTemplateForm";

const NodeAllocateForm = (props) => {
  const { history } = props;

  const [form] = Form.useForm();

  const initValues = {
    status: 1,
    ordered_at: moment(),
    skip_existing: false,
  };

  const onFinish = (values) => {
    const {
      company,
      description,
      first_name,
      last_name,
      phone1,
      phone2,
      email,
      initial,
      number,
      series,
      total,
      ordered_at,
    } = values;
    const ordered_by =
      !first_name && !last_name && !phone1 && !phone2 && !email
        ? null
        : {
            first_name,
            last_name,
            contact: {
              phone1,
              phone2,
              email,
            },
          };

    const formData = {
      initial,
      number,
      series,
      total,
      company: company.value,
      shipped_at: moment().format("YYYY-MM-DDTHH:mm"),
      ordered_at: ordered_at?.format("YYYY-MM-DD") || null,
      description: description || "",
      ordered_by,
      skip_existing: false,
    };

    nodeService.addAllocation(formData, () => {
      history.push(path.allocate_node_list);
      successAlert(`Nodes has been allocated to ${company.label}`);
    });
  };

  return (
    <SNPanel panelTitle="Add allocation nodes">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        className="sn-form"
      >
        <NodeAllocateTemplateForm form={form} />

        <Row style={{ marginTop: 30 }}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Space size={8}>
              <SNButton
                title="Cancel"
                className="sn-btn-big"
                onClick={() => {
                  history.push(path.allocate_node_list);
                }}
              />
              <SNButton
                title="Save"
                type="primary"
                className="sn-btn-big"
                onClick={form.submit}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </SNPanel>
  );
};

export default NodeAllocateForm;
