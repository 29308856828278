import api, { url } from "api";
import Axios from "axios";
import { useEffect, useState } from "react";
import { NewsFeedApi } from "./data.t";

const useNewFeedDetails = (id: any): [NewsFeedApi, Function] => {
  const [data, setData] = useState<NewsFeedApi>(null);
  const [willReload, setWillReload] = useState<boolean>(false);

  let source = Axios.CancelToken.source();
  const reload = () => {
    setData(null);
    setWillReload(!willReload);
  };

  const fetchDetails = () => {
    api.get(`${url.NEWS_FEED}${id}/`).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    } else {
      setData(null);
    }
    return () => {
      source.cancel("Cancelling in cleanup");
    };
  }, [id, willReload]);

  return [data, reload];
};

function createNew(params: any = {}, postActions?: (data: any) => void) {
  api.post(url.NEWS_FEED, params).then((response) => {
    if (response.status === 201) {
      if (postActions) postActions(response.data);
    }
  });
}

function editNew(id: any, params: any = {}, postActions?: (data: any) => void) {
  api.put(`${url.NEWS_FEED}${id}/`, params).then((response) => {
    if (response.status === 200) {
      if (postActions) postActions(response.data);
    }
  });
}

const newsfeedService = {
  useNewFeedDetails,
  createNew,
  editNew,
};

export default newsfeedService;
