import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { ConfigProvider } from "antd";
import "antd/dist/antd.css";
import enUSIntl from "antd/lib/locale/en_US";
import axios from "axios";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { API_URL } from "settings/env";
import "./App.scss";
import "./base.scss";
import store, { persistor } from "./redux/store";
import Root from "./screens/Root";
import History from "./settings/history";

const FAS: any = fas;
const FAR: any = far;
const FAL: any = fal;

library.add(FAS, FAR, FAL);

axios.defaults.baseURL = API_URL;

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ConfigProvider locale={enUSIntl}>
            <History />
            <Root />
          </ConfigProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
