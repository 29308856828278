import { Col } from "antd";

import moment from "moment";
import SNButton from "../../../../../components/SN/SNButton";
import SNDescription from "../../../../../components/SN/SNDescription";
import { SNDescriptionItemProps } from "../../../../../components/SN/SNDescription/data.t";
import SNEmpty from "../../../../../components/SN/SNEmpty";
import SNModal from "../../../../../components/SN/SNModal";
import { formatName } from "../../../../../lib";

const LOGIN_TYPE = {
  1: "APP",
  2: "WEB",
  3: "PUBLIC URL",
  4: "ANDROID",
  5: "IOS",
};

const AuthLogDetailModal = (props) => {
  const { isVisible, onClose, info } = props;

  const _renderDetails = () => {
    if (!info || !info.id)
      return <SNEmpty title="No data found" icon="comment-alt-exclamation" />;

    const userColumns: SNDescriptionItemProps[] = [
      {
        label: "Name",
        value: formatName(info.user.first_name, info.user.last_name),
      },
      {
        label: "Email",
        value: info.user.contact?.email,
      },
    ];
    const logColumns: SNDescriptionItemProps[] = [
      {
        label: "Login time",
        value: moment(info.login_time).format("YYYY-MM-DD HH:mm"),
      },
      {
        label: "Logout time",
        value: info.logout_time
          ? moment(info.login_time).format("YYYY-MM-DD HH:mm")
          : "",
      },
      {
        label: "IP",
        value: info.ip,
      },
      {
        label: "Device",
        value: LOGIN_TYPE[info.login_from],
      },
      {
        label: "Address",
        value: info.address,
      },
    ];
    return (
      <Col xl={24} lg={24} md={24} xs={24}>
        <SNDescription
          icon={"building"}
          title={"User Infomation"}
          bordered
          column={1}
          size={"small"}
          itemData={userColumns}
        />
        <SNDescription
          icon={"info-circle"}
          title={"Log Infomation"}
          bordered
          column={1}
          size={"small"}
          itemData={logColumns}
          style={{ marginTop: 20 }}
        />
      </Col>
    );
  };

  const footer = [
    <SNButton
      key="cancel"
      title={"Close"}
      onClick={onClose}
      className="sn-btn-big"
    />,
  ];

  return (
    <SNModal
      title="Log Details"
      isVisible={isVisible}
      footer={footer}
      onCancel={onClose}
      width={800}
      bodyStyle={{
        maxHeight: 800,
        overflowY: "auto",
        overflowX: "hidden",
        padding: 16,
        // margin: 20,
      }}
      destroyOnClose
    >
      {_renderDetails()}
    </SNModal>
  );
};

export default AuthLogDetailModal;
