import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Typography, notification } from "antd";

const { Paragraph } = Typography;

export function successAlert(message) {
  notification["success"]({
    message: "Success",
    top: 64,
    description: message || "",
    zIndex: 99999,
    icon: <CheckCircleFilled style={{ color: "green" }} />,
  });
}

export function warningAlert(message = "") {
  notification["warning"]({
    message: "Warning",
    top: 64,
    description: message,
    zIndex: 99999,
    icon: <ExclamationCircleFilled style={{ color: "#ff9800" }} />,
  });
}

export function serverErrorAlert(message, fixedMessage) {
  let transMessages = "";
  if (Array.isArray(message)) {
    transMessages = (
      <Paragraph>
        <b>Error</b>
        <ul>
          {message.map((msg, key) => {
            if (msg.title)
              return (
                <li key={key}>
                  <span>
                    {msg.title && msg.title !== "Error" && (
                      <b>{msg.title || ""} - </b>
                    )}
                    {msg.message || ""}
                  </span>
                </li>
              );
            return <li key={key}>{msg.message || ""}</li>;
          })}
        </ul>
      </Paragraph>
    );
  } else {
    transMessages = message || "";
  }

  notification["error"]({
    message: fixedMessage || "",
    top: 64,
    description: transMessages,
    zIndex: 99999,
    icon: <CloseCircleFilled style={{ color: "#f50" }} />,
  });
}
