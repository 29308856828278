import { blue } from "@ant-design/colors";
import ProTable, {
  ActionType,
  enUSIntl,
  IntlProvider,
  ProColumns,
  RequestData,
} from "@ant-design/pro-table";
import { useRef } from "react";
import { Link } from "react-router-dom";
import api, { url } from "../../../../api";
import SNButton from "../../../../components/SN/SNButton";
import SNButtonGroup from "../../../../components/SN/SNButtonGroup";
import { SNMenuItemProps } from "../../../../components/SN/SNMenuItem";
import SNPanel from "../../../../components/SN/SNPanel";
import path from "../../../Root/routePaths";
import { getPlanBillingTag, getPlanStatusTag } from "../const";
import { PlanApi } from "../data.t";

const AllPlans = ({ history }) => {
  const listRef = useRef<ActionType>();

  const _renderList = () => {
    const columns: ProColumns<PlanApi>[] = [
      {
        dataIndex: "id",
        key: "id",
        title: "ID",
        width: 70,
        sorter: true,
      },
      {
        dataIndex: "name",
        key: "name",
        title: "Plan name",
        sorter: true,
        render: (value, data: any) => {
          return (
            <Link
              to={{
                pathname: path.sub_plan_details.replace(":plan_id", data.id),
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        dataIndex: "billing_period",
        key: "billing_period",
        title: "Billing period",
        render: (value: any) => getPlanBillingTag(value),
      },
      {
        dataIndex: "price",
        key: "price",
        title: "Price",
        render: (value: any) => (value ? `${value} (SEK)` : ""),
      },
      {
        dataIndex: "is_public",
        key: "is_public",
        title: "Public",
        render: (value: any) => (value ? "Yes" : "No"),
      },
      {
        dataIndex: "created_at",
        key: "created_at",
        title: "Created at",
        valueType: "date",
      },
      // {
      //   dataIndex: "free_trial_day",
      //   key: "free_trial_day",
      //   title: "Free trial",
      //   render: (value) =>
      //     value ? `${value} ${value > 1 ? "days" : "day"}` : "No trial",
      // },
      // {
      //   dataIndex: "setup_fee",
      //   key: "setup_fee",
      //   title: "Setup fee",
      //   render: (value: any) => (value ? `${value} (SEK)` : ""),
      // },
      {
        dataIndex: "status",
        key: "status",
        title: "Status",
        render: (value: any) => getPlanStatusTag(value),
      },
      {
        key: "actions",
        width: 50,
        fixed: "right",
        search: false,
        render: (value: any, data: any) => {
          const actions: SNMenuItemProps[] = [
            {
              key: "info",
              icon: ["fal", "info"],
              title: "Details",
              link: path.sub_plan_details.replace(":plan_id", data.id),
            },
            {
              key: "edit",
              icon: ["fal", "pencil-alt"],
              title: "Edit",
              link: path.edit_sub_plan.replace(":plan_id", data.id),
            },
          ];
          return <SNButtonGroup dropdown={actions} />;
        },
      },
    ];

    return (
      <IntlProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
        <ProTable<PlanApi, { search: string }>
          actionRef={listRef}
          request={async (
            { pageSize, current, keyword },
            sort,
            filter
          ): Promise<RequestData<PlanApi>> => {
            const sortKey = Object.keys(sort).length
              ? Object.keys(sort)[0]
              : "";
            const sortParams = sortKey.length
              ? `&ordering=${sort[sortKey] === "descend" ? "-" : ""}${sortKey}`
              : "";

            const urlRequest = `${
              url.SUBSCRIPTION_PLAN
            }?limit=${pageSize}&offset=${
              (current - 1) * pageSize
            }${sortParams}`;
            const res = await api.get(urlRequest);

            return {
              data: res.data.results,
              success: true,
              total: res.data.count,
            };
          }}
          headerTitle="All subscription plans"
          search={false}
          toolBarRender={() => [
            <SNButton
              key="plus-button"
              title="Create Plan"
              iconcolor={blue.primary}
              icon="plus"
              onClick={() => history.push(path.create_sub_plan)}
            />,
            <SNButton
              key="filter-button"
              title="Filters"
              icon={["far", "filter"]}
              iconcolor={blue.primary}
            />,
          ]}
          options={{
            setting: false,
            fullScreen: false,
            density: false,
          }}
          size={"small"}
          pagination={{ pageSize: 100, showQuickJumper: true }}
          rowKey={(key) => key.id}
          columns={columns}
          dateFormatter="string"
        />
      </IntlProvider>
    );
  };

  return <SNPanel view="list">{_renderList()}</SNPanel>;
};

export default AllPlans;
