import { ExclamationCircleFilled } from "@ant-design/icons";
import { Alert, Modal } from "antd";
import { ModalFuncProps } from "antd/lib/modal";

import "./index.css";
const { confirm } = Modal;

export default function showAntConfirm(props: ModalFuncProps & any) {
  const {
    title,
    content,
    name,
    onOk,
    onCancel,
    okText,
    okType,
    cancelText,
    okButtonProps,
    cancelButtonProps,
    warning,
  } = props;
  return (
    <div>
      {confirm({
        title: title,
        content: (
          <>
            {content +
              (name
                ? ` ${name}${
                    typeof name === "string" &&
                    name.charAt(name.length - 1) === "?"
                      ? ""
                      : "?"
                  }`
                : "")}

            {warning && (
              <Alert
                message={warning}
                type="warning"
                style={{ marginLeft: -16, marginTop: 6 }}
              />
            )}
          </>
        ),
        okText: okText || "Yes",
        okType: okType,
        icon: <ExclamationCircleFilled style={{ color: "#ff9800" }} />,
        cancelText: cancelText || "Cancel",
        okButtonProps: okButtonProps,
        cancelButtonProps: cancelButtonProps,
        onOk() {
          onOk && onOk();
        },
        onCancel() {
          onCancel && onCancel();
        },
      })}
    </div>
  );
}
