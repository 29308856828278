import { EnvironmentOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import { FC } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import SNFormItem from "../SNFormItem";

interface SNAddressAutoCompleteProps {
  onSelect?: any;
  onReset?: any;
  name?: any;
  label: any;
}

const SNAddressAutoComplete: FC<SNAddressAutoCompleteProps> = (props) => {
  const { onSelect, onReset, name, label } = props;
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {
        lat: () => 43.6532,
        lng: () => -79.3832,
        toJSON: () => ({ lat: 43.6532, lng: -79.3832 }),
        toString,
        toUrlValue: () => "aa",
        equals: () => false,
      },
      radius: 100 * 1000,
    },
  });

  const handleInput = (string: string) => {
    setValue(string);
  };
  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const res = await getLatLng(results[0]);
      onSelect(results[0], res);
    } catch (error) {}
  };

  return (
    <SNFormItem name={name} label={label}>
      <AutoComplete
        onSelect={handleSelect}
        onSearch={handleInput}
        disabled={!ready}
        options={
          status === "OK" &&
          data.map((suggestion) => {
            const {
              id,
              structured_formatting: { main_text, secondary_text },
              description,
            } = suggestion;

            return {
              value: description,
              label: (
                <div>
                  <EnvironmentOutlined /> <strong>{main_text}</strong>{" "}
                  <small>{secondary_text}</small>
                </div>
              ),
              key: id,
            };
          })
        }
      >
        <Input
          allowClear
          placeholder={"Street"}
          onChange={(e) => {
            if (!e.target.value) onReset();
          }}
        />
      </AutoComplete>
    </SNFormItem>
  );
};

export default SNAddressAutoComplete;
