export type AuthLogLoginType = 1 | 2 | 3 | 4 | 5;

export interface AuthLogApi {
  id: number;
  user: {
    username: string;
    first_name: string;
    last_name: string;
    image: string;
    contact: {
      email: string;
      phone1: string;
      phone2: string;
    };
  };
  session_key: string;
  ip: string;
  os: string;
  browser: string;
  device: string;
  address: string;
  login_time: string;
  logout_time: string;
  login_from: AuthLogLoginType;
}

export const authLoginType: { [key in AuthLogLoginType]: string } = {
  1: "App",
  2: "Web",
  3: "Public url",
  4: "Android",
  5: "iOS",
};
