import { blue } from "@ant-design/colors";
import { DatePicker, Divider, Drawer, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import Form from "antd/lib/form/Form";
import qs from "query-string";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SNButton from "../../../../../components/SN/SNButton";
import SNFormItem from "../../../../../components/SN/SNForm/SNFormItem";
import SNSelect from "../../../../../components/SN/SNForm/SNSelect";
import {
  cleanObject,
  formatDateParam,
  formatFormCustomSelectParam,
  formatNumberParam,
} from "../../../../../lib/locationSearch";
import SNUsersSelect from "../../../../components/EntitiesSelect/SNUsersSelect";
import { authLoginType } from "../../data.t";

const AuthLogFilter = () => {
  const formRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [form] = useForm();
  const searchParams: any = qs.parse(location.search);

  useEffect(() => {
    if (!location.search) {
      form.resetFields();
    } else {
      form.setFieldsValue({ ..._getFormInitValue });
    }
  }, [location.search]);

  const _getFormInitValue: any = useMemo(() => {
    return cleanObject({
      user: formatFormCustomSelectParam(searchParams?.user),
      login_from: formatNumberParam(searchParams?.login_from),
      login_time_date_exact: formatDateParam(
        searchParams?.login_time_date_exact
      ),
    });
  }, [location.search]);

  const _submitForm = useCallback((allFields) => {
    const { user, login_time_date_exact, login_from } = allFields;
    const searchData = cleanObject({
      user: user?.value || null,
      login_time_date_exact:
        login_time_date_exact?.format("YYYY-MM-DD") || null,
      login_from,
    });

    setIsVisible(false);
    history.push(`?${qs.stringify(searchData)}`);
  }, []);

  const _resetFilter = useCallback(() => {
    history.push(location.pathname);
    form.resetFields();
    setIsVisible(false);
  }, []);

  const _renderFormSection = () => {
    return (
      <section>
        <Form
          ref={formRef}
          layout="vertical"
          onFinish={_submitForm}
          form={form}
          initialValues={_getFormInitValue}
        >
          <SNUsersSelect
            formClassName="sn-filter-form-item-wrapper"
            name="user"
            label="User"
            placeholdertext="Select user from the list"
            showUsername
          />

          <SNFormItem
            className="sn-filter-form-item-wrapper"
            label="Login from"
            name="login_from"
          >
            <SNSelect
              options={Object.keys(authLoginType).map((i) => {
                return {
                  label: authLoginType[i],
                  value: parseInt(i),
                };
              })}
              allowClear={false}
              placeholder="Select login type"
            />
          </SNFormItem>

          <SNFormItem
            className="sn-filter-form-item-wrapper"
            label="Login date"
            name="login_time_date_exact"
          >
            <DatePicker />
          </SNFormItem>
        </Form>
      </section>
    );
  };

  const _renderButtonSection = () => {
    return (
      <section>
        <Divider />
        <Row justify="space-between">
          <SNButton
            title="Reset"
            className="sn-btn-big"
            onClick={_resetFilter}
          />
          <SNButton
            title="Apply"
            type="primary"
            className="sn-btn-big"
            onClick={form.submit}
          />
        </Row>
      </section>
    );
  };

  return (
    <div>
      <Row>
        <SNButton
          title="Filters"
          icon={[location.search ? "fas" : "far", "filter"]}
          iconcolor={blue.primary}
          onClick={() => setIsVisible(true)}
        />
        {location.search && (
          <SNButton
            icon={["far", "eraser"]}
            iconcolor={blue.primary}
            onClick={_resetFilter}
          />
        )}
      </Row>
      <Drawer
        title={"Auth log filter"}
        width={400}
        onClose={() => setIsVisible(false)}
        visible={isVisible}
      >
        {_renderFormSection()}
        {_renderButtonSection()}
      </Drawer>
    </div>
  );
};

export default AuthLogFilter;
