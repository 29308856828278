import { Col, Row } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Form, { FormInstance } from "antd/lib/form";
import { useState } from "react";
import Validator from "validator";
import SNFormItem from "../../../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../../../components/SN/SNForm/SNInput";
import SNSelect from "../../../../../../components/SN/SNForm/SNSelect";
import SNTextarea from "../../../../../../components/SN/SNForm/SNTextarea";
import SNPriceInput from "../../../../../../components/SN/SNPriceInput";
import SNText from "../../../../../../components/SN/SNText";
import SNTitle from "../../../../../../components/SN/SNTitle";
import SNWikiIcon from "../../../../../../components/SN/SNWikiIcon";
import { PlanApi, planBillingPeriod, planEntity } from "../../../data.t";

interface PlanTemplateFormProps {
  form: FormInstance;
  editData?: PlanApi;
  isLock?: boolean;
}

const PlanTemplateForm = (props: PlanTemplateFormProps) => {
  const { form, editData } = props;
  const [billStatus, setBillStatus] = useState(
    editData ? editData.billing_period : form.getFieldValue("billing_period")
  );

  const initPlanSettingValues = [
    {
      entity: "asset",
      available: 0,
      entity_limit: 0,
      entity_price: 0,
      price: 0,
      disabled: true,
    },
    {
      entity: "user",
      available: 0,
      entity_limit: 0,
      entity_price: 0,
      price: 0,
      disabled: true,
    },
    {
      entity: "snode",
      available: 0,
      entity_limit: 0,
      entity_price: 0,
      price: 0,
      disabled: false,
    },
    {
      entity: "place",
      available: 0,
      entity_limit: 0,
      entity_price: 0,
      price: 0,
      disabled: false,
    },
    {
      entity: "workorder",
      available: 0,
      entity_limit: 0,
      entity_price: 0,
      price: 0,
      disabled: false,
    },
    {
      entity: "invoice",
      available: 0,
      entity_limit: 0,
      entity_price: 0,
      price: 0,
      disabled: false,
    },
    {
      entity: "form",
      available: 0,
      entity_limit: 0,
      entity_price: 0,
      price: 0,
      disabled: false,
    },
  ];

  const validateMessages = {
    price: {
      validator: (_, value) =>
        value >= 0
          ? Promise.resolve()
          : Promise.reject(new Error("Not valid number")),
    },
    quantity: {
      validator: (_, value) =>
        Validator.isNumeric(value.toString(), { no_symbols: true })
          ? Promise.resolve()
          : Promise.reject(new Error("Not valid number")),
    },
  };

  const renderGeneralInfo = () => {
    return (
      <Col xl={24} lg={16} md={16} xs={24}>
        <SNTitle icon={["fal", "info"]} title="General information" />

        <Row gutter={[40, 16]}>
          <Col xl={8} lg={16} md={16} xs={24}>
            <SNFormItem
              label="Plan name"
              name="name"
              required
              rules={[{ max: 100 }]}
            >
              <SNInput placeholder={"Name of the plan"} />
            </SNFormItem>

            <SNFormItem
              label="Plan code"
              name="code"
              required
              rules={[{ max: 10 }]}
            >
              <SNInput placeholder={"Example: SN99, SN33, PLAN11,..."} />
            </SNFormItem>

            <SNFormItem name="is_public" valuePropName="checked">
              <Checkbox>Public plan</Checkbox>
            </SNFormItem>
          </Col>
          <Col xl={8} lg={16} md={16} xs={24}>
            <SNFormItem label="Billing period" name="billing_period">
              <SNSelect
                options={[
                  { label: planBillingPeriod[0], value: 0 },
                  { label: planBillingPeriod[1], value: 1 },
                  { label: planBillingPeriod[2], value: 2 },
                  { label: planBillingPeriod[3], value: 3 },
                ]}
                allowClear={false}
                onChange={(value) => {
                  if (value === 0) {
                    form.setFieldsValue({ price: 0 });
                  }
                  setBillStatus(value);
                }}
              />
            </SNFormItem>

            <SNPriceInput
              form={form}
              label="Price / billing period"
              name="price"
              required
              disabled={billStatus == 0}
              inputProps={{
                onChange: (e) => {
                  form.setFieldsValue({
                    plan_price: parseFloat(e.target.value).toFixed(2),
                  });
                },
              }}
            />
          </Col>

          <Col xl={8} lg={16} md={16} xs={24}>
            <SNFormItem label="Plan terms" name="description">
              <SNTextarea
                placeholder="Add a short description of Terms and Condition to use this plan (Optional)"
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </SNFormItem>
          </Col>
        </Row>
      </Col>
    );
  };

  const renderSetting = () => {
    return (
      <Col xl={24} lg={24} md={24} xs={24}>
        <SNTitle icon="cogs" title="Features" />
        <Row gutter={[12, 16]} style={{ marginBottom: 20 }}>
          <Col xl={3} lg={6} md={6} xs={9}>
            <SNText text="Entity" />
          </Col>
          <Col xl={3} lg={5} md={5} xs={8}>
            <span>
              <SNText text="Available" style={{ marginRight: 5 }} />
              <SNWikiIcon tips="Decide if customer can use this function on Web and Mobile or not" />
            </span>
          </Col>
          <Col xl={4} lg={8} md={8} xs={12}>
            <span>
              <SNText text="Limit" style={{ marginRight: 5 }} />
              <SNWikiIcon tips="The quantity that company can interact with (based on price of billing period), 0 mean unlimited" />
            </span>
          </Col>
          <Col xl={7} lg={15} md={15} xs={20}>
            <span>
              <SNText text="Price per entity" style={{ marginRight: 5 }} />
              <SNWikiIcon tips="Define the amount to be paid for each entity after exceeding the limit, 0 mean user can not access this feature anymore" />
            </span>
          </Col>
        </Row>
        <Form.List name="plan_settings" initialValue={initPlanSettingValues}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => {
                return (
                  <Row key={key} gutter={[12, 16]} style={{ marginBottom: 10 }}>
                    <Col xl={3} lg={6} md={6} xs={9}>
                      <SNFormItem
                        {...restField}
                        name={[name, "entity"]}
                        fieldKey={[fieldKey, "entity"]}
                        isListField={true}
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <div style={{ marginTop: 5 }}>
                          <SNText
                            strong
                            text={planEntity[initPlanSettingValues[key].entity]}
                          />
                        </div>
                      </SNFormItem>
                    </Col>
                    <Col xl={3} lg={5} md={5} xs={8}>
                      <SNFormItem
                        {...restField}
                        name={[name, "available"]}
                        fieldKey={[fieldKey, "available"]}
                        isListField={true}
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <SNSelect
                          options={[
                            { label: "Yes", value: 0 },
                            { label: "No", value: 1 },
                          ]}
                          allowClear={false}
                          disabled={initPlanSettingValues[key].disabled}
                          onChange={(value) => {
                            if (value == 1) {
                              const planSetting = [
                                ...form.getFieldValue("plan_settings"),
                              ];
                              form.setFieldsValue({
                                plan_settings: planSetting.map(
                                  (plan, planId) => {
                                    if (planId === key) {
                                      return {
                                        ...plan,
                                        entity_limit: 0,
                                        entity_price: 0,
                                      };
                                    }

                                    return {
                                      ...plan,
                                    };
                                  }
                                ),
                              });
                            }
                          }}
                        />
                      </SNFormItem>
                    </Col>
                    <Col xl={4} lg={8} md={8} xs={12}>
                      <SNFormItem
                        {...restField}
                        name={[name, "entity_limit"]}
                        fieldKey={[fieldKey, "entity_limit"]}
                        isListField={true}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[validateMessages.quantity]}
                        required
                      >
                        <SNInput
                          min={0}
                          style={{ width: "100%" }}
                          maxLength={6}
                          type="number"
                          placeholder="0"
                          disabled={
                            form.getFieldValue("plan_settings")[key][
                              "available"
                            ] === 1
                          }
                        />
                      </SNFormItem>
                    </Col>
                    <Col xl={7} lg={15} md={15} xs={20}>
                      <SNFormItem
                        {...restField}
                        name={[name, "entity_price"]}
                        fieldKey={[fieldKey, "entity_price"]}
                        rules={[validateMessages.price]}
                        isListField={true}
                        validateTrigger={["onChange", "onBlur"]}
                        required
                      >
                        <SNInput
                          min={0}
                          style={{ width: "100%" }}
                          addonAfter={"SEK"}
                          type="number"
                          placeholder="0.00"
                          disabled={
                            form.getFieldValue("plan_settings")[key][
                              "available"
                            ] === 1
                          }
                        />
                      </SNFormItem>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        </Form.List>
        <Row gutter={[12, 16]} justify="end">
          <Col xl={6} lg={12} md={12} xs={24} style={{ marginTop: 15 }}>
            <SNFormItem
              label={<SNText text="PRICE FOR THIS PLAN" strong />}
              name="plan_price"
            >
              <SNInput style={{ width: "100%" }} disabled addonAfter={"SEK"} />
            </SNFormItem>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <>
      <Row gutter={[40, 16]}>{renderGeneralInfo()}</Row>
      <Row gutter={[40, 16]}>{renderSetting()}</Row>
    </>
  );
};

export default PlanTemplateForm;
