import { Checkbox, Col, Row } from "antd";
import { FormInstance } from "antd/lib/form";

import SNCheckLabel from "../../../../components/SN/SNCheckLabel";
import SNAddress from "../../../../components/SN/SNForm/SNAddress";
import SNFormItem from "../../../../components/SN/SNForm/SNFormItem";
import SNInput from "../../../../components/SN/SNForm/SNInput";
import SNLogoFormItem from "../../../../components/SN/SNForm/SNLogoFormItem";
import SNSelect from "../../../../components/SN/SNForm/SNSelect";
import SNTextarea from "../../../../components/SN/SNForm/SNTextarea";
import SNWebsiteInput from "../../../../components/SN/SNForm/SNWebsiteInput";
import SNText from "../../../../components/SN/SNText";
import SNTitle from "../../../../components/SN/SNTitle";
import { addressManager } from "../../../../lib";
import SNUsersSelect from "../../../components/EntitiesSelect/SNUsersSelect";
import { COMPANY_INDUSTRIES } from "../../const";

interface CompanyFormProps {
  form: FormInstance;
  editData?: any;
  isLock?: boolean;
}
function CompanyForm(props: CompanyFormProps) {
  const { form, editData, isLock } = props;

  const renderInvoice = () => {
    if (isLock) {
      return (
        <section>
          <SNTitle icon="file-invoice-dollar" title="Invoice information" />
          <SNFormItem label="Reference">
            <strong>{editData.invoice_ref || "-"}</strong>
          </SNFormItem>
          <SNFormItem label="Invoice e-mail">
            <strong>{editData.invoice_email || "-"}</strong>
          </SNFormItem>
          <SNFormItem label="VAT reg. number">
            <strong>{editData.vat_reg_number || "-"}</strong>
          </SNFormItem>
          <SNFormItem label="Invoice address">
            <strong>
              {(editData.invoice_address?.address &&
                addressManager(editData.invoice_address)) ||
                "-"}
            </strong>
          </SNFormItem>
        </section>
      );
    }
    return (
      <>
        <section>
          <SNTitle icon="file-invoice-dollar" title="Invoice information" />
          <SNFormItem label="Reference" name="invoice_ref">
            <SNInput />
          </SNFormItem>
          <SNFormItem
            label="Invoice e-mail"
            name="invoice_email"
            rules={[{ type: "email" }]}
          >
            <SNInput />
          </SNFormItem>
          <SNFormItem label="VAT reg. number" name="vat_reg_number">
            <SNInput />
          </SNFormItem>
        </section>
        <section>
          <SNTitle icon="map" title="Invoice address" />
          <SNAddress name="invoice_address" form={form} />
        </section>
      </>
    );
  };
  const renderGeneralInfo = () => {
    if (isLock)
      return (
        <section>
          <SNTitle icon="building" title="Company information" />
          <SNFormItem label="Company Name">
            <strong>{editData.company_name}</strong>
          </SNFormItem>
          <SNFormItem label="Organization number">
            <strong>{editData.company_id}</strong>
          </SNFormItem>
          <SNFormItem label="Industry">
            <strong>
              {COMPANY_INDUSTRIES[editData.industry_type - 1]?.label
                ? COMPANY_INDUSTRIES[editData.industry_type - 1]?.label
                : "-"}
            </strong>
          </SNFormItem>
          <SNFormItem>
            <SNCheckLabel
              check={editData.allow_partnership_request}
              label={<span>Allow partner request </span>}
            />
          </SNFormItem>
        </section>
      );
    return (
      <section>
        <SNTitle icon="building" title="Company information" />
        <SNFormItem label="Name" name="company_name" required>
          <SNInput />
        </SNFormItem>

        <SNFormItem
          label="Organization number"
          name="company_id"
          rules={[{ max: 20 }]}
          required
        >
          <SNInput disabled={!!editData} />
        </SNFormItem>

        <SNFormItem label="Industry" name="industry_type">
          <SNSelect options={COMPANY_INDUSTRIES} />
        </SNFormItem>

        {!editData && (
          <SNUsersSelect
            name="user"
            placeholdertext="Select user in the system"
            label="Created for user"
            required
          />
        )}

        <SNFormItem name="allow_partnership_request" valuePropName="checked">
          <Checkbox>
            <SNText text="Allow partner request" />
          </Checkbox>
        </SNFormItem>
      </section>
    );
  };
  const renderPhoneEmail = () => {
    if (isLock)
      return (
        <section>
          <SNTitle icon="id-card" title="Phone & Email" />
          <SNFormItem label="Phone">
            <strong>{editData.company_phone || "-"}</strong>
          </SNFormItem>
          <SNFormItem label="Email">
            <strong>{editData.company_email || "-"}</strong>
          </SNFormItem>
          <SNFormItem label="Website">
            <strong>{editData.website || "-"}</strong>
          </SNFormItem>
        </section>
      );
    return (
      <section>
        <SNTitle icon="id-card" title="Phone & Email" />

        <SNFormItem label="Company phone" name="company_phone">
          <SNInput />
        </SNFormItem>
        <SNFormItem
          label="Company email"
          name="company_email"
          rules={[{ type: "email" }]}
        >
          <SNInput />
        </SNFormItem>
        <SNWebsiteInput label="Website" name="website" />
      </section>
    );
  };
  const renderAddress = () => {
    if (isLock)
      return (
        <section>
          <SNTitle icon="map-marker-alt" title="Visiting address" />
          <SNFormItem>{addressManager(editData.address) || "-"}</SNFormItem>
        </section>
      );
    return (
      <section>
        <SNTitle icon="map-marker-alt" title="Visiting address" />
        <SNAddress form={form} name="companyAddress" />
      </section>
    );
  };

  const renderDescription = () => {
    if (isLock)
      return (
        <section>
          <SNTitle icon="comment-alt-lines" title="Description" />
          <SNFormItem>{editData.description || "-"}</SNFormItem>
        </section>
      );
    return (
      <section>
        <SNTitle icon="comment-alt-lines" title="Description" />
        <SNFormItem name="description">
          <SNTextarea placeholder="Add a short description (Optional)" />
        </SNFormItem>
      </section>
    );
  };
  const renderContacts = () => {
    return (
      <>
        <section>
          <SNTitle icon="file-image" title="Logo" />
          <SNLogoFormItem
            name="logo"
            form={form}
            existingImg={editData?.logo}
            hideActions={isLock}
          />
        </section>
      </>
    );
  };
  if (editData)
    return (
      <Row gutter={[40, 16]}>
        <Col xl={12} lg={12} md={12} xs={24}>
          {renderGeneralInfo()}
          {renderPhoneEmail()}
          {renderAddress()}
          {renderDescription()}
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          {renderInvoice()}
          {renderContacts()}
        </Col>
      </Row>
    );
  return (
    <Row gutter={[40, 16]}>
      <Col xl={8} lg={12} md={12} xs={24}>
        {renderGeneralInfo()}
      </Col>

      <Col xl={8} lg={12} md={12} xs={24}>
        {renderPhoneEmail()}
        {renderAddress()}
        {renderDescription()}
      </Col>

      <Col xl={8} lg={12} md={12} xs={24}>
        {renderInvoice()}
        {renderContacts()}
      </Col>
    </Row>
  );
}

export default CompanyForm;
