import { Button, Result } from "antd";

import { Link } from "react-router-dom";
import path from "../../../screens/Root/routePaths";
import SNPanel from "../SNPanel";

interface SN404PageProps {}

function SN404Page(props: SN404PageProps) {
  return (
    <SNPanel>
      <Result
        status={404}
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary">
            <Link to={path.analytics}>Back to home</Link>
          </Button>
        }
      />
    </SNPanel>
  );
}

export default SN404Page;
