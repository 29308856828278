import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import { Space, Tabs } from "antd";

import { url } from "../../../api";
import SN404Page from "../../../components/SN/SN404Page";
import SNButtonGroup from "../../../components/SN/SNButtonGroup";
import {
  showDeleteModal,
  showPatchModal,
} from "../../../components/SN/SNConfirmModal";
import SNDetailsLoading from "../../../components/SN/SNDetailsLoading";
import { SNMenuItemProps } from "../../../components/SN/SNMenuItem";
import SNPanel from "../../../components/SN/SNPanel";
import SNText from "../../../components/SN/SNText";
import path from "../../Root/routePaths";
import companyService from "../service";
import CompanyDetailsContext from "./context";
import "./index.scss";
import InvoiceTab from "./InvoiceTab";
import OverviewTab from "./OverviewTab";
import QRTab from "./QRTab";

const CompanyDetails = (props) => {
  const { match, history } = props;
  const companyId = match.params.id;

  /**
   * Render view region
   */

  if (!companyId) return <SN404Page />;

  const [companyData, companyStats, refreshData] =
    companyService.useCompanyDetails(companyId);

  if (!companyData) return <SNDetailsLoading />;

  const { company_name } = companyData;

  const _renderRightOption = () => {
    const actions: SNMenuItemProps[] = companyData.is_active
      ? [
          {
            key: "refresh",
            icon: ["fal", "redo"],
            title: "Refresh",
            onClick: () => refreshData(),
          },
          {
            key: "divider",
          },
          {
            key: "reject",
            icon: ["fal", "times-square"],
            title: "Reject",
            onClick: () => {
              showPatchModal({
                url: `${url.COMPANY}${companyData.id}/inactivate/`,
                title: "Reject company",
                content: `Are you sure you want to reject`,
                message404: "Reject company failed",
                name: companyData.company_name,
                postAction: () => {
                  refreshData();
                },
                successText: "Rejected company successfully",
              });
            },
          },
        ]
      : [
          {
            key: "refresh",
            icon: ["fal", "redo"],
            title: "Refresh",
            onClick: () => refreshData(),
          },
          {
            key: "divider",
          },
          {
            key: "Approve",
            icon: ["fal", "check-square"],
            title: "Approve",
            onClick: () => {
              showPatchModal({
                url: `${url.COMPANY}${companyData.id}/activate/`,
                title: "Approve company",
                content: `Are you sure you want to approve`,
                message404: "Approve company failed",
                name: companyData.company_name,
                postAction: () => {
                  refreshData();
                },
                successText: "Approved company successfully",
              });
            },
          },
          {
            key: "Delete",
            icon: ["fal", "trash-alt"],
            title: "Delete",
            onClick: () => {
              showDeleteModal({
                url: `${url.COMPANY}${companyData.id}/`,
                title: "Delete company",
                content: `Are you sure you want to delete`,
                message404: "Delete company failed",
                name: companyData.company_name,
                postAction: () => {
                  history.push(path.all_tenants);
                },
                successText: "Delete company successfully",
              });
            },
          },
        ];
    return <SNButtonGroup dropdown={actions} />;
  };

  const tabs = [
    { name: "Overview", render: () => <OverviewTab /> },
    { name: "Invoices", render: () => <InvoiceTab /> },
    { name: "Node Allocation", render: () => <QRTab /> },
    // { name: "Users", render: () => <UserTab /> },
  ];

  return (
    <CompanyDetailsContext.Provider
      value={{ companyData, companyStats, companyId }}
    >
      <SNPanel
        view={"details"}
        panelTitle={
          <Space style={{ display: "flex" }}>
            <SNText
              text={company_name}
              style={{ fontWeight: 500, fontSize: 17, color: "#42a5f5" }}
            />
          </Space>
        }
        extra={_renderRightOption()}
        bodyStyle={{ paddingTop: 5 }}
      >
        <Tabs defaultActiveKey="1">
          {tabs.map((tab, id) => (
            <TabPane key={`Tab-${id.toString()}`} tab={tab.name}>
              {tab.render()}
            </TabPane>
          ))}
        </Tabs>
      </SNPanel>
    </CompanyDetailsContext.Provider>
  );
};

export default CompanyDetails;
