import { Tag } from "antd";
export const COUNTRIES = () => {
  return [
    { value: 1, label: "Sweden" },
    { value: 2, label: "Norway" },
    { value: 3, label: "Finalnd" },
    { value: 4, label: "Denmark" },
  ];
};
export const INDUSTRY_TYPE = () => {
  return [
    {
      value: 1,
      label: "Facility Management",
    },
    {
      value: 2,
      label: "Property Owner",
    },
    {
      value: 3,
      label: "Service & maintenance",
    },
    {
      value: 4,
      label: "Manufacturer",
    },
    {
      value: 5,
      label: "Equipment rental",
    },
    {
      value: 6,
      label: "Installation",
    },
    { value: 7, label: "Others" },
  ];
};
export const COMPANY_INDUSTRIES = [
  { value: 1, label: "Facility Management" },
  { value: 2, label: "Property Owner" },
  { value: 3, label: "Service & maintenance" },
  { value: 4, label: "Manufacturer" },
  { value: 5, label: "Equipment rental" },
  { value: 6, label: "Installation" },
  { value: 7, label: "Others" },
];

export const getOwnerStatusTag = (status: boolean) => {
  switch (status) {
    case true:
      return <Tag color={"success"}>Active</Tag>;
    case false:
      return <Tag color={"error"}>Inactive</Tag>;
    default:
      return null;
  }
};

export const getCompanyStatusTag = (status: boolean) => {
  switch (status) {
    case true:
      return <Tag color={"success"}>Active</Tag>;
    case false:
      return <Tag color={"orange"}>Pending approval</Tag>;
    default:
      return null;
  }
};
